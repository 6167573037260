.logoIcon {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 2.5rem;
  flex-shrink: 0;
}
.componentName,
.link,
.logoIcon {
  position: relative;
}
.link {
  font-size: var(--m3-headline-small-size);
  text-decoration: underline;
  line-height: 2rem;
  color: var(--m3-sys-light-primary);
}
.definition {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 1.5rem;
  text-align: left;
}
.header,
.title {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.header {
  background-color: var(--m3-sys-dark-on-surface);
  overflow: hidden;
  padding: var(--padding-33xl) var(--padding-33xl) var(--padding-51xl);
  gap: var(--gap-41xl);
}
.label1 {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 1.5rem;
  font-weight: 500;
}
.label {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-xl);
  align-items: flex-start;
  justify-content: center;
}
.rippleIcon {
  position: absolute;
  margin: 0 !important;
  bottom: -2.81rem;
  left: 11.13rem;
  width: 11.13rem;
  height: 7.81rem;
  z-index: 0;
}
.icon {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
}
.stateLayer1 {
  flex-direction: row;
  padding: var(--padding-5xs);
}
.container,
.leadingIcon,
.stateLayer1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container {
  border-radius: var(--br-81xl);
  overflow: hidden;
  flex-direction: row;
}
.leadingIcon {
  width: 3rem;
  height: 3rem;
  flex-direction: column;
  z-index: 1;
}
.supportingText {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
}
.content,
.stTrailingIcon {
  display: flex;
  align-items: center;
}
.content {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  z-index: 2;
}
.stTrailingIcon {
  width: 3rem;
  height: 3rem;
  flex-direction: column;
  justify-content: center;
}
.background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--m3-sys-light-primary);
}
.initial {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
}
.avatar {
  position: relative;
  width: 1.88rem;
  height: 1.88rem;
  overflow: hidden;
  flex-shrink: 0;
}
.avatarTarget,
.trailingElements {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.avatarTarget {
  width: 3rem;
  height: 3rem;
  justify-content: center;
}
.trailingElements {
  justify-content: flex-end;
  z-index: 3;
  text-align: center;
  color: var(--m3-sys-light-on-error);
}
.stateLayer,
.statepressedShowAvatartru {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.stateLayer {
  align-self: stretch;
  flex: 1;
  background-color: var(--m3-state-layers-light-on-surface-opacity-008);
  padding: var(--padding-9xs);
  position: relative;
  gap: var(--gap-9xs);
}
.statepressedShowAvatartru {
  position: absolute;
  top: 6rem;
  left: 44.5rem;
  border-radius: var(--br-9xl);
  background-color: var(--m3-sys-light-surface-container-high);
  width: 20.5rem;
  height: 3.5rem;
  overflow: hidden;
}
.content1 {
  align-self: stretch;
  flex: 1;
  justify-content: flex-start;
}
.content1,
.stateLayer3,
.trailingElements1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.trailingElements1 {
  justify-content: flex-end;
  text-align: center;
  color: var(--m3-sys-light-on-error);
}
.stateLayer3 {
  align-self: stretch;
  flex: 1;
  background-color: var(--m3-state-layers-light-on-surface-opacity-008);
  padding: var(--padding-9xs);
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.statehoveredShowAvatartru {
  position: absolute;
  top: 6rem;
  left: 22.75rem;
  border-radius: var(--br-9xl);
  background-color: var(--m3-sys-light-surface-container-high);
  width: 20.5rem;
  height: 3.5rem;
  overflow: hidden;
}
.stateLayer6,
.stateenabledShowAvatartru,
.statehoveredShowAvatartru {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.stateLayer6 {
  align-self: stretch;
  flex: 1;
  padding: var(--padding-9xs);
  gap: var(--gap-9xs);
}
.stateenabledShowAvatartru {
  position: absolute;
  top: 6rem;
  left: 1rem;
  border-radius: var(--br-9xl);
  background-color: var(--m3-sys-light-surface-container-high);
  width: 20.5rem;
  height: 3.5rem;
  overflow: hidden;
}
.ndTrailingIcon {
  width: 3rem;
  height: 3rem;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.trailingElements3 {
  justify-content: flex-end;
  z-index: 3;
}
.statepressedShowAvatarfal,
.trailingElements3,
.trailingElements4 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.statepressedShowAvatarfal {
  position: absolute;
  top: 1rem;
  left: 44.5rem;
  border-radius: var(--br-9xl);
  background-color: var(--m3-sys-light-surface-container-high);
  width: 20.5rem;
  height: 3.5rem;
  overflow: hidden;
  justify-content: flex-start;
}
.trailingElements4 {
  justify-content: flex-end;
}
.stateenabledShowAvatarfal,
.statehoveredShowAvatarfal {
  position: absolute;
  top: 1rem;
  left: 22.75rem;
  border-radius: var(--br-9xl);
  background-color: var(--m3-sys-light-surface-container-high);
  width: 20.5rem;
  height: 3.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.stateenabledShowAvatarfal {
  left: 1rem;
}
.searchBar {
  position: relative;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-surface);
  width: 22.25rem;
  height: 10.5rem;
  overflow: hidden;
  flex-shrink: 0;
  text-align: left;
  color: var(--m3-sys-light-on-surface-variant);
}
.label2 {
  display: flex;
  flex-direction: row;
  padding: var(--padding-21xl) var(--padding-xl) 0;
  align-items: flex-start;
  justify-content: center;
}
.caretIcon {
  position: relative;
  width: 0.06rem;
  height: 1.06rem;
}
.inputText {
  height: 1.5rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-12xs);
}
.content6 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.divider1,
.header1 {
  box-sizing: border-box;
}
.header1 {
  align-self: stretch;
  border-radius: var(--br-9xl) var(--br-9xl) 0 0;
  background-color: var(--m3-sys-light-surface-container-high);
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.divider1 {
  position: relative;
  border-top: 1px solid var(--m3-sys-light-outline);
  height: 0.06rem;
}
.divider,
.divider1,
.list {
  align-self: stretch;
}
.divider {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.list {
  overflow: hidden;
}
.labelTextinputTextShowLi {
  border-radius: var(--br-9xl);
  width: 20.5rem;
  flex-direction: column;
  align-items: flex-start;
}
.header2,
.labelTextinputTextShowLi,
.labelTextsupportingTextSh {
  background-color: var(--m3-sys-light-surface-container-high);
  display: flex;
  justify-content: flex-start;
}
.header2 {
  align-self: stretch;
  border-radius: var(--br-9xl) var(--br-9xl) 0 0;
  height: 3.5rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-37xl) var(--padding-9xs)
    var(--padding-9xs);
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-9xs);
}
.labelTextsupportingTextSh {
  border-radius: var(--br-9xl);
  width: 20.5rem;
  flex-direction: column;
  align-items: flex-start;
  color: var(--m3-sys-light-on-surface-variant);
}
.stateLayerOverlay {
  position: absolute;
  margin: 0 !important;
  height: 100%;
  width: 109.76%;
  top: 0;
  right: -9.76%;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.buildingBlocksmonogram {
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-dark-on-primary-container);
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  flex-shrink: 0;
}
.leadingElement {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: var(--m3-sys-light-on-primary-container);
}
.headline,
.overline {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.5px;
}
.overline {
  line-height: 1rem;
  font-weight: 500;
  display: none;
}
.headline {
  font-size: var(--m3-title-medium-size);
  line-height: 1.5rem;
  color: var(--m3-sys-light-on-surface);
}
.content9,
.supportingText7 {
  align-self: stretch;
  overflow: hidden;
}
.supportingText7 {
  position: relative;
  font-size: var(--m3-label-large-prominent-size);
  letter-spacing: 0.25px;
  line-height: 1.25rem;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 1.25rem;
  flex-shrink: 0;
}
.content9 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: var(--m3-label-medium-size);
}
.trailingSupportingText {
  position: relative;
  font-size: var(--m3-label-small-size);
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 500;
  text-align: right;
  display: none;
}
.stateLayer26 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-5xl) var(--padding-5xs)
    var(--padding-base);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.divider7 {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--m3-sys-dark-on-surface-variant);
  box-sizing: border-box;
  height: 0.06rem;
}
.divider6,
.listItem1 {
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
}
.divider6 {
  display: none;
  align-items: flex-start;
  z-index: 2;
}
.listItem1 {
  height: 4.5rem;
  display: flex;
  align-items: center;
  position: relative;
}
.stateLayerOverlay3 {
  position: absolute;
  margin: 0 !important;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.listItem4 {
  align-self: stretch;
  height: 4.5rem;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.list2,
.supportingText12 {
  align-self: stretch;
  display: flex;
}
.list2 {
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  color: var(--m3-sys-light-on-surface-variant);
}
.supportingText12 {
  flex: 1;
  position: relative;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
  align-items: center;
}
.list3 {
  align-self: stretch;
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
}
.content20,
.list3,
.searchViewModal {
  display: flex;
  flex-direction: column;
}
.searchViewModal {
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.content20 {
  flex: 1;
  align-items: flex-start;
  justify-content: center;
}
.header5 {
  align-self: stretch;
  border-radius: var(--br-9xl) var(--br-9xl) 0 0;
  background-color: var(--m3-sys-light-surface-container-high);
  height: 4.5rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-8xs) var(--padding-9xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.content21,
.labelTextinputTextListIt {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.labelTextinputTextListIt {
  background-color: var(--m3-sys-light-surface-container-high);
  width: 25.75rem;
  flex-direction: column;
}
.content21 {
  flex: 1;
  flex-direction: row;
}
.header6,
.labelTextsupportingTextLi {
  background-color: var(--m3-sys-light-surface-container-high);
  display: flex;
  justify-content: flex-start;
}
.header6 {
  align-self: stretch;
  border-radius: var(--br-9xl) var(--br-9xl) 0 0;
  height: 4.5rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-37xl) var(--padding-9xs)
    var(--padding-9xs);
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-9xs);
}
.labelTextsupportingTextLi {
  width: 25.75rem;
  flex-direction: column;
  align-items: flex-start;
  color: var(--m3-sys-light-on-surface-variant);
}
.stateLayerOverlay10 {
  position: absolute;
  margin: 0 !important;
  height: 100%;
  width: 87.38%;
  top: 0;
  right: 12.62%;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.supportingText24 {
  flex: 1;
  position: relative;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
}
.lightScheme,
.searchViewFullScreen {
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-surface);
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl);
  align-items: center;
}
.searchViewFullScreen {
  justify-content: flex-start;
  gap: var(--gap-xl);
  text-align: left;
  color: var(--m3-sys-light-on-surface);
}
.lightScheme {
  border: 1px solid var(--m3-sys-dark-on-surface-variant);
  justify-content: center;
}
.background3 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--m3-sys-dark-primary);
}
.stateLayer53,
.trailingElements6 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.trailingElements6 {
  justify-content: flex-end;
  z-index: 3;
  text-align: center;
  color: var(--m3-sys-dark-on-primary);
}
.stateLayer53 {
  align-self: stretch;
  flex: 1;
  background-color: var(--m3-state-layers-dark-on-surface-opacity-008);
  padding: var(--padding-9xs);
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-9xs);
}
.statepressedShowAvatartru1 {
  position: absolute;
  top: 6rem;
  left: 44.5rem;
  border-radius: var(--br-9xl);
  background-color: var(--m3-sys-dark-surface-container-high);
  width: 20.5rem;
  height: 3.5rem;
  overflow: hidden;
  justify-content: flex-start;
}
.stateLayer56,
.statepressedShowAvatartru1,
.trailingElements7 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.trailingElements7 {
  justify-content: flex-end;
  text-align: center;
  color: var(--m3-sys-dark-on-primary);
}
.stateLayer56 {
  align-self: stretch;
  flex: 1;
  background-color: var(--m3-state-layers-dark-on-surface-opacity-008);
  padding: var(--padding-9xs);
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.stateenabledShowAvatarfal1,
.stateenabledShowAvatartru1,
.statehoveredShowAvatarfal1,
.statehoveredShowAvatartru1,
.statepressedShowAvatarfal1 {
  position: absolute;
  top: 6rem;
  left: 22.75rem;
  border-radius: var(--br-9xl);
  background-color: var(--m3-sys-dark-surface-container-high);
  width: 20.5rem;
  height: 3.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.stateenabledShowAvatarfal1,
.stateenabledShowAvatartru1,
.statehoveredShowAvatarfal1,
.statepressedShowAvatarfal1 {
  left: 1rem;
}
.stateenabledShowAvatarfal1,
.statehoveredShowAvatarfal1,
.statepressedShowAvatarfal1 {
  top: 1rem;
  left: 44.5rem;
}
.stateenabledShowAvatarfal1,
.statehoveredShowAvatarfal1 {
  left: 22.75rem;
}
.stateenabledShowAvatarfal1 {
  left: 1rem;
}
.searchBarDark {
  position: relative;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-dark-surface);
  width: 22.19rem;
  height: 10.5rem;
  overflow: hidden;
  flex-shrink: 0;
  text-align: left;
  color: var(--m3-sys-dark-on-surface-variant);
}
.divider52,
.header9 {
  align-self: stretch;
  box-sizing: border-box;
}
.header9 {
  border-radius: var(--br-9xl) var(--br-9xl) 0 0;
  background-color: var(--m3-sys-dark-surface-container-high);
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.divider52 {
  position: relative;
  border-top: 1px solid var(--m3-sys-dark-outline);
  height: 0.06rem;
}
.labelTextinputTextShowLi2 {
  border-radius: var(--br-9xl);
  width: 20.5rem;
  flex-direction: column;
  align-items: flex-start;
}
.header10,
.labelTextinputTextShowLi2,
.labelTextsupportingTextSh2 {
  background-color: var(--m3-sys-dark-surface-container-high);
  display: flex;
  justify-content: flex-start;
}
.header10 {
  align-self: stretch;
  border-radius: var(--br-9xl) var(--br-9xl) 0 0;
  height: 3.5rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-37xl) var(--padding-9xs)
    var(--padding-9xs);
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-9xs);
}
.labelTextsupportingTextSh2 {
  border-radius: var(--br-9xl);
  width: 20.5rem;
  flex-direction: column;
  align-items: flex-start;
  color: var(--m3-sys-dark-on-surface-variant);
}
.buildingBlocksmonogramDark {
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-dark-primary-container);
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  flex-shrink: 0;
}
.leadingElement20 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: var(--m3-sys-dark-on-primary-container);
}
.divider56,
.headline20 {
  align-self: stretch;
  position: relative;
}
.headline20 {
  font-size: var(--m3-title-medium-size);
  letter-spacing: 0.5px;
  line-height: 1.5rem;
  color: var(--m3-sys-dark-on-surface);
}
.divider56 {
  border-top: 1px solid var(--m3-sys-light-on-surface-variant);
  box-sizing: border-box;
  height: 0.06rem;
}
.list10 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  color: var(--m3-sys-dark-on-surface-variant);
}
.header13,
.searchViewModalDark {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.searchViewModalDark {
  border-radius: var(--br-base);
  background-color: var(--m3-sys-dark-surface);
  flex-direction: column;
  padding: var(--padding-xl);
  gap: var(--gap-xl);
  text-align: left;
  color: var(--m3-sys-dark-on-surface);
}
.header13 {
  align-self: stretch;
  border-radius: var(--br-9xl) var(--br-9xl) 0 0;
  background-color: var(--m3-sys-dark-surface-container-high);
  height: 4.5rem;
  flex-direction: row;
  padding: var(--padding-8xs) var(--padding-9xs);
  box-sizing: border-box;
  gap: var(--gap-9xs);
}
.labelTextinputTextListIt2 {
  width: 25.75rem;
  flex-direction: column;
  align-items: flex-start;
}
.header14,
.labelTextinputTextListIt2,
.labelTextsupportingTextLi2 {
  background-color: var(--m3-sys-dark-surface-container-high);
  display: flex;
  justify-content: flex-start;
}
.header14 {
  align-self: stretch;
  border-radius: var(--br-9xl) var(--br-9xl) 0 0;
  height: 4.5rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-37xl) var(--padding-9xs)
    var(--padding-9xs);
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-9xs);
}
.labelTextsupportingTextLi2 {
  width: 25.75rem;
  flex-direction: column;
  align-items: flex-start;
  color: var(--m3-sys-dark-on-surface-variant);
}
.darkScheme {
  border-radius: var(--br-base);
  background-color: var(--m3-sys-dark-surface);
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl);
  align-items: center;
  justify-content: center;
  color: var(--m3-sys-light-on-error);
}
.components,
.search {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.components {
  flex-direction: row;
  padding: var(--padding-51xl);
  gap: var(--gap-21xl);
  font-size: var(--m3-title-medium-size);
}
.search {
  position: relative;
  border-radius: var(--br-41xl) var(--br-41xl) var(--br-base) var(--br-base);
  background-color: var(--m3-sys-light-on-error);
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  text-align: center;
  font-size: var(--font-size-61xl);
  color: var(--m3-black);
  font-family: var(--m3-headline-medium);
}
