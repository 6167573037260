.logoIcon {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 2.5rem;
  flex-shrink: 0;
}
.componentName,
.link,
.logoIcon {
  position: relative;
}
.link {
  font-size: var(--m3-headline-small-size);
  text-decoration: underline;
  line-height: 2rem;
  color: var(--m3-sys-light-primary);
}
.definition {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 1.5rem;
  text-align: left;
}
.header,
.title {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.header {
  background-color: var(--m3-sys-dark-on-surface);
  overflow: hidden;
  padding: var(--padding-33xl) var(--padding-33xl) var(--padding-51xl);
  gap: var(--gap-41xl);
}
.sizesmall {
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  width: 0.38rem;
  height: 0.38rem;
}
.badgeLabel {
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 7px);
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.88rem;
  height: 0.88rem;
}
.sizelargeSingleDigit {
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  width: 1rem;
  height: 1rem;
  overflow: hidden;
  flex-shrink: 0;
}
.badgeLabel1 {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 500;
}
.sizemultipleDigits {
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-9xs);
  align-items: center;
  justify-content: flex-start;
}
.badge,
.lightScheme {
  background-color: var(--m3-sys-light-surface);
  display: flex;
  justify-content: flex-start;
}
.badge {
  border-radius: var(--br-8xs);
  flex-direction: column;
  padding: var(--padding-xl);
  align-items: center;
  gap: var(--gap-xl);
}
.lightScheme {
  border-radius: var(--br-base);
  border: 1px solid var(--m3-sys-dark-on-surface-variant);
  flex-direction: row;
  padding: var(--padding-41xl);
  align-items: flex-start;
}
.sizelargeSingleDigit1,
.sizemultipleDigits1,
.sizesmall1 {
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-dark-error);
}
.sizesmall1 {
  position: relative;
  width: 0.38rem;
  height: 0.38rem;
}
.sizelargeSingleDigit1,
.sizemultipleDigits1 {
  overflow: hidden;
}
.sizelargeSingleDigit1 {
  position: relative;
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
}
.sizemultipleDigits1 {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-9xs);
  align-items: center;
  justify-content: flex-start;
}
.badgeDark,
.darkScheme {
  background-color: var(--m3-sys-dark-surface);
}
.badgeDark {
  border-radius: var(--br-8xs);
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.darkScheme {
  border-radius: var(--br-base);
  flex-direction: row;
  padding: var(--padding-41xl);
  color: var(--m3-sys-dark-on-error);
}
.badges,
.components,
.darkScheme {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.components {
  flex-direction: row;
  padding: var(--padding-51xl);
  gap: var(--gap-21xl);
  font-size: var(--m3-label-small-size);
  color: var(--m3-sys-light-on-error);
}
.badges {
  position: relative;
  border-radius: var(--br-41xl) var(--br-41xl) var(--br-base) var(--br-base);
  background-color: var(--m3-sys-light-on-error);
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  text-align: center;
  font-size: var(--font-size-61xl);
  color: var(--m3-black);
  font-family: var(--m3-headline-medium);
}
