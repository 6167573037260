.logoIcon {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 2.5rem;
  flex-shrink: 0;
}
.componentName,
.link,
.logoIcon {
  position: relative;
}
.link {
  font-size: var(--m3-headline-small-size);
  text-decoration: underline;
  line-height: 2rem;
  color: var(--m3-sys-light-primary);
}
.definition {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 1.5rem;
  text-align: left;
}
.header,
.title {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.header {
  background-color: var(--m3-sys-dark-on-surface);
  overflow: hidden;
  padding: var(--padding-33xl) var(--padding-33xl) var(--padding-51xl);
  gap: var(--gap-41xl);
}
.label,
.label1 {
  left: 0;
  line-height: 1.5rem;
  font-weight: 500;
}
.label {
  position: absolute;
  top: 33.88rem;
}
.label1 {
  top: 17rem;
}
.label1,
.label2,
.labels {
  position: absolute;
}
.label2 {
  top: 0;
  left: 0;
  line-height: 1.5rem;
  font-weight: 500;
}
.labels {
  margin: 0 !important;
  top: 2.88rem;
  left: 3.25rem;
  width: 12.56rem;
  height: 35.38rem;
  z-index: 2;
}
.label3,
.supportingText {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-weight: 500;
}
.supportingText {
  font-size: var(--m3-label-small-size);
  letter-spacing: 0.5px;
  line-height: 1rem;
  display: none;
}
.content {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-9xs);
}
.item1 {
  flex: 1;
  background-image: url(/public/item1@3x.png);
}
.item1,
.item2,
.item3 {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-9xl);
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.item2 {
  flex: 1;
  background-image: url(/public/item2@3x.png);
}
.item3 {
  width: 7.5rem;
  flex-shrink: 0;
  background-image: url(/public/item3@3x.png);
}
.content3 {
  flex-direction: column;
  padding: var(--padding-base);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-9xs);
}
.content3,
.itemTextLast {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
}
.itemLast {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-9xl);
  width: 3.5rem;
  overflow: hidden;
  flex-shrink: 0;
  background-image: url(/public/itemlast@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.uniformfalseContexttablet {
  position: absolute;
  top: 36.75rem;
  left: 1rem;
  width: 37.5rem;
  height: 13.81rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.item11 {
  flex: 1;
  background-image: url(/public/item11@3x.png);
}
.item11,
.item21,
.itemLast1 {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-9xl);
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.item21 {
  flex: 1;
  background-image: url(/public/item21@3x.png);
}
.itemLast1 {
  width: 3.5rem;
  flex-shrink: 0;
  background-image: url(/public/itemlast1@3x.png);
}
.uniformtrueContextmobile {
  position: absolute;
  top: 19.81rem;
  left: 1rem;
  width: 25.75rem;
  height: 13.81rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.item12,
.item22 {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-9xl);
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.item12 {
  flex: 1;
  background-image: url(/public/item12@3x.png);
}
.item22 {
  width: 7.5rem;
  flex-shrink: 0;
  background-image: url(/public/item22@3x.png);
}
.uniformfalseContextmobile {
  position: absolute;
  top: 2.88rem;
  left: 1rem;
  width: 25.75rem;
  height: 13.81rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-base);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.carousel1 {
  position: relative;
  border-radius: var(--br-base);
  width: 39.5rem;
  height: 52.56rem;
  z-index: 1;
  color: var(--m3-sys-light-on-error);
}
.label13 {
  position: relative;
  line-height: 1.5rem;
  font-weight: 500;
}
.supportingTextfalse {
  position: relative;
  width: 12.75rem;
  height: 12.81rem;
}
.supportingText11 {
  position: relative;
  font-size: var(--m3-label-small-size);
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 500;
}
.buildingBlocksCarouselItem {
  border-radius: var(--br-8xs);
  background-color: var(--m3-sys-dark-on-surface);
  width: 39.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-21xl);
  color: var(--m3-sys-light-on-error);
}
.buildingBlocks,
.lightScheme {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buildingBlocks {
  justify-content: flex-start;
  gap: var(--gap-xl);
  z-index: 0;
}
.lightScheme {
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-surface);
  border: 1px solid var(--m3-sys-dark-on-surface-variant);
  padding: var(--padding-xl);
  justify-content: center;
  position: relative;
  gap: var(--gap-21xl);
}
.labels1 {
  position: absolute;
  margin: 0 !important;
  top: 2.88rem;
  left: 3.25rem;
  width: 12.56rem;
  height: 35.38rem;
  z-index: 0;
}
.carouselDark {
  position: relative;
  border-radius: var(--br-base);
  width: 39.5rem;
  height: 52.56rem;
  z-index: 1;
}
.buildingBlocks1,
.buildingBlocksCarouselItem1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buildingBlocksCarouselItem1 {
  border-radius: var(--br-8xs);
  background-color: var(--m3-sys-dark-surface-container-highest);
  width: 39.5rem;
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-21xl);
}
.buildingBlocks1 {
  flex-direction: column;
  gap: var(--gap-xl);
  z-index: 2;
}
.darkScheme {
  border-radius: var(--br-base);
  background-color: var(--m3-sys-dark-surface);
  border: 1px solid var(--m3-sys-light-on-surface-variant);
  flex-direction: column;
  padding: var(--padding-xl);
  position: relative;
  gap: var(--gap-21xl);
  color: var(--m3-sys-light-on-error);
}
.carousel,
.components,
.darkScheme {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.components {
  flex-direction: row;
  padding: var(--padding-51xl);
  gap: var(--gap-21xl);
  text-align: left;
  font-size: var(--m3-title-medium-size);
}
.carousel {
  position: relative;
  border-radius: var(--br-41xl) var(--br-41xl) var(--br-base) var(--br-base);
  background-color: var(--m3-sys-light-on-error);
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  text-align: center;
  font-size: var(--font-size-61xl);
  color: var(--m3-black);
  font-family: var(--m3-headline-medium);
}
