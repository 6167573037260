.logoIcon {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 2.5rem;
  flex-shrink: 0;
}
.componentName,
.link,
.logoIcon {
  position: relative;
}
.link {
  font-size: var(--m3-headline-small-size);
  text-decoration: underline;
  line-height: 2rem;
  color: var(--m3-sys-light-primary);
}
.definition {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 1.5rem;
  text-align: left;
}
.header,
.title {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.header {
  background-color: var(--m3-sys-dark-on-surface);
  overflow: hidden;
  padding: var(--padding-33xl) var(--padding-33xl) var(--padding-51xl);
  gap: var(--gap-41xl);
}
.scrim {
  position: absolute;
  margin: 0 !important;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--m3-black);
  height: 48.81rem;
  overflow: hidden;
  opacity: 0.32;
  z-index: 0;
}
.iconsarrowBack24px {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
}
.stateLayer {
  flex-direction: row;
  padding: var(--padding-5xs);
}
.backAffordance,
.container1,
.stateLayer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container1 {
  border-radius: var(--br-81xl);
  overflow: hidden;
  flex-direction: row;
}
.backAffordance {
  width: 3rem;
  height: 3rem;
  flex-direction: column;
}
.headlineText {
  flex: 1;
  position: relative;
  line-height: 1.75rem;
}
.content,
.header1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.content {
  flex: 1;
  padding: var(--padding-xs) 0 0;
}
.header1 {
  align-self: stretch;
  padding: var(--padding-xs) var(--padding-xs) var(--padding-base)
    var(--padding-9xs);
}
.content1 {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
}
.divider {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--m3-sys-dark-on-surface-variant);
  box-sizing: border-box;
  height: 0.06rem;
}
.horizontalDivider {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.labelText {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  font-weight: 500;
}
.stateLayer2 {
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xl);
  align-items: center;
  justify-content: center;
}
.primaryButton {
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-primary);
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
}
.buttons,
.primaryButton,
.secondaryButton {
  display: flex;
  align-items: center;
}
.secondaryButton {
  border-radius: var(--br-81xl);
  border: 1px solid var(--m3-sys-light-outline);
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  color: var(--m3-sys-light-primary);
}
.buttons {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-base) var(--padding-5xl) 0;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.actions,
.sideSheet {
  flex-direction: column;
}
.actions {
  align-self: stretch;
  display: none;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  font-size: var(--m3-label-large-prominent-size);
  color: var(--m3-sys-light-on-error);
}
.sideSheet {
  position: absolute;
  top: 50.75rem;
  left: 25.13rem;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-surface-container-low);
  width: 20rem;
  height: 43.75rem;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.header2 {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-xs) var(--padding-base)
    var(--padding-5xl);
}
.header2,
.header3,
.sideSheet1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.sideSheet1 {
  position: absolute;
  top: 50.75rem;
  left: 1.25rem;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-surface-container-low);
  width: 20rem;
  height: 43.75rem;
  overflow: hidden;
  flex-direction: column;
}
.header3 {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-xs) var(--padding-base)
    var(--padding-9xs);
  z-index: 0;
}
.content5 {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  z-index: 1;
}
.actions2 {
  align-self: stretch;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: 2;
  text-align: center;
  font-size: var(--m3-label-large-prominent-size);
  color: var(--m3-sys-light-on-error);
}
.divider3 {
  flex: 1;
  position: relative;
  border-right: 1px solid var(--m3-sys-dark-on-surface-variant);
  box-sizing: border-box;
  width: 0.06rem;
}
.sideSheet2,
.verticalDivider {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.verticalDivider {
  margin: 0 !important;
  height: calc(100% + 1px);
  top: 0;
  bottom: -0.06rem;
  left: 0;
  width: 0.06rem;
  overflow: hidden;
  justify-content: center;
  z-index: 3;
}
.sideSheet2 {
  top: 1.25rem;
  left: 25.13rem;
  background-color: var(--m3-sys-light-surface);
  width: 20rem;
  height: 43.75rem;
  padding: 0 0 var(--padding-5xl);
  box-sizing: border-box;
  justify-content: flex-start;
}
.actions4,
.header4 {
  align-self: stretch;
  display: flex;
}
.header4 {
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-xs) var(--padding-base)
    var(--padding-5xl);
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 0;
}
.actions4 {
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  font-size: var(--m3-label-large-prominent-size);
  color: var(--m3-sys-light-on-error);
}
.typemodalShowBackfalse,
.typemodalShowBacktrue {
  position: absolute;
  top: 50.75rem;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-surface-container-low);
  width: 20rem;
  height: 43.75rem;
  overflow: hidden;
  padding: 0 0 var(--padding-5xl);
  box-sizing: border-box;
  justify-content: flex-start;
}
.typemodalShowBackfalse {
  left: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.typemodalShowBacktrue {
  left: 25.25rem;
}
.actions6,
.typemodalShowBacktrue,
.typestandardShowBackfalse {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.actions6 {
  align-self: stretch;
  justify-content: flex-end;
  z-index: 2;
  text-align: center;
  font-size: var(--m3-label-large-prominent-size);
  color: var(--m3-sys-light-on-error);
}
.typestandardShowBackfalse {
  top: 1.25rem;
  left: 1.25rem;
  width: 20rem;
  height: 43.75rem;
  padding: 0 0 var(--padding-5xl);
  box-sizing: border-box;
  justify-content: flex-start;
}
.sideSheet4,
.typestandardShowBackfalse,
.typestandardShowBacktrue {
  position: absolute;
  background-color: var(--m3-sys-light-surface);
}
.typestandardShowBacktrue {
  top: 1.25rem;
  left: 25.25rem;
  width: 20rem;
  height: 43.75rem;
  display: flex;
  flex-direction: column;
  padding: 0 0 var(--padding-5xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.sideSheet4 {
  top: 0;
  left: 47.75rem;
  border-radius: var(--br-base);
  width: 46.5rem;
  height: 47.19rem;
}
.container,
.lightScheme {
  overflow: hidden;
  position: relative;
}
.container {
  width: 94.25rem;
  height: 95.81rem;
  flex-shrink: 0;
  z-index: 1;
}
.lightScheme {
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-surface);
  border: 1px solid var(--m3-sys-dark-on-surface-variant);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xl);
  align-items: flex-start;
  justify-content: flex-start;
}
.divider12 {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--m3-sys-light-on-surface-variant);
  box-sizing: border-box;
  height: 0.06rem;
}
.primaryButton8 {
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-dark-primary);
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.actions8,
.primaryButton8,
.secondaryButton8 {
  flex-direction: column;
  align-items: center;
}
.secondaryButton8 {
  border-radius: var(--br-81xl);
  border: 1px solid var(--m3-sys-dark-outline);
  overflow: hidden;
  display: flex;
  justify-content: center;
  color: var(--m3-sys-dark-primary);
}
.actions8 {
  align-self: stretch;
  display: none;
  justify-content: flex-end;
  text-align: center;
  font-size: var(--m3-label-large-prominent-size);
  color: var(--m3-sys-dark-on-primary);
}
.actions10,
.sideSheetDark {
  flex-direction: column;
  align-items: flex-start;
}
.sideSheetDark {
  position: absolute;
  top: 50.75rem;
  left: 25.13rem;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-on-surface);
  width: 20rem;
  height: 43.75rem;
  overflow: hidden;
  display: flex;
  padding: 0 0 var(--padding-5xl);
  box-sizing: border-box;
  justify-content: flex-start;
}
.actions10 {
  align-self: stretch;
  display: none;
  justify-content: flex-end;
  z-index: 2;
  text-align: center;
  font-size: var(--m3-label-large-prominent-size);
  color: var(--m3-sys-dark-on-primary);
}
.divider15 {
  flex: 1;
  position: relative;
  border-right: 1px solid var(--m3-sys-light-on-surface-variant);
  box-sizing: border-box;
  width: 0.06rem;
}
.actions12,
.sideSheetDark2 {
  display: flex;
  flex-direction: column;
}
.sideSheetDark2 {
  position: absolute;
  top: 1.25rem;
  left: 25.13rem;
  background-color: var(--m3-sys-dark-surface);
  width: 20rem;
  height: 43.75rem;
  padding: 0 0 var(--padding-5xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.actions12 {
  align-self: stretch;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  font-size: var(--m3-label-large-prominent-size);
  color: var(--m3-sys-dark-on-primary);
}
.typemodalShowBackButtonf {
  position: absolute;
  top: 50.75rem;
  left: 1.25rem;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-on-surface);
  width: 20rem;
  height: 43.75rem;
  overflow: hidden;
  padding: 0 0 var(--padding-5xl);
  box-sizing: border-box;
  justify-content: flex-start;
}
.actions14,
.typemodalShowBackButtonf,
.typemodalShowBackButtont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.typemodalShowBackButtont {
  position: absolute;
  top: 50.75rem;
  left: 25.25rem;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-on-surface);
  width: 20rem;
  height: 43.75rem;
  overflow: hidden;
  padding: 0 0 var(--padding-5xl);
  box-sizing: border-box;
  justify-content: flex-start;
}
.actions14 {
  align-self: stretch;
  justify-content: flex-end;
  z-index: 2;
  text-align: center;
  font-size: var(--m3-label-large-prominent-size);
  color: var(--m3-sys-dark-on-primary);
}
.typestandardShowBackButto,
.typestandardShowBackButto1 {
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 20rem;
  height: 43.75rem;
  display: flex;
  flex-direction: column;
  padding: 0 0 var(--padding-5xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.typestandardShowBackButto1 {
  left: 25.25rem;
}
.darkScheme,
.sideSheetDark4 {
  border-radius: var(--br-base);
  background-color: var(--m3-sys-dark-surface);
}
.sideSheetDark4 {
  position: absolute;
  top: 0;
  left: 47.75rem;
  width: 46.5rem;
  height: 47.44rem;
}
.darkScheme {
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-xl);
  position: relative;
  color: var(--m3-sys-dark-on-surface-variant);
}
.components,
.darkScheme,
.sideSheets {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.components {
  flex-direction: row;
  padding: var(--padding-51xl);
  gap: var(--gap-21xl);
  text-align: left;
  font-size: var(--m3-title-large-size);
  color: var(--m3-sys-light-on-surface-variant);
}
.sideSheets {
  position: relative;
  border-radius: var(--br-41xl) var(--br-41xl) var(--br-base) var(--br-base);
  background-color: var(--m3-sys-light-on-error);
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  text-align: center;
  font-size: var(--font-size-61xl);
  color: var(--m3-black);
  font-family: var(--m3-headline-medium);
}
