.logoIcon {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 2.5rem;
  flex-shrink: 0;
}
.componentName,
.link,
.logoIcon {
  position: relative;
}
.link {
  font-size: var(--m3-headline-small-size);
  text-decoration: underline;
  line-height: 2rem;
  color: var(--m3-sys-light-primary);
}
.definition {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 1.5rem;
  text-align: left;
}
.header,
.title {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.header {
  background-color: var(--m3-sys-dark-on-surface);
  overflow: hidden;
  padding: var(--padding-33xl) var(--padding-33xl) var(--padding-51xl);
  gap: var(--gap-41xl);
}
.stateLayerOverlay {
  position: absolute;
  margin: 0 !important;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.initial {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
}
.buildingBlocksmonogram {
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-dark-on-primary-container);
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  flex-shrink: 0;
}
.leadingElement {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: var(--m3-sys-light-on-primary-container);
}
.overline {
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 500;
  display: none;
}
.headline,
.overline,
.supportingText {
  align-self: stretch;
  position: relative;
}
.headline {
  font-size: var(--m3-title-medium-size);
  letter-spacing: 0.5px;
  line-height: 1.5rem;
  color: var(--m3-sys-light-on-surface);
}
.supportingText {
  font-size: var(--m3-label-large-prominent-size);
  letter-spacing: 0.25px;
  line-height: 1.25rem;
  display: none;
}
.content {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: var(--m3-label-medium-size);
}
.metadata {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 500;
  display: none;
}
.container {
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--m3-sys-light-primary);
  width: 1.13rem;
  height: 1.13rem;
  z-index: 0;
}
.iconscheckSmall {
  position: absolute;
  margin: 0 !important;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  width: 1.5rem;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.stateLayer1 {
  border-radius: var(--br-81xl);
  padding: var(--padding-2xs);
  justify-content: center;
  position: relative;
}
.checkboxes,
.stateLayer1,
.trailingElement {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.checkboxes {
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
}
.trailingElement {
  justify-content: flex-start;
  gap: var(--gap-3xs);
  text-align: right;
  font-size: var(--m3-label-small-size);
}
.divider,
.stateLayer {
  align-self: stretch;
  box-sizing: border-box;
}
.stateLayer {
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-5xl) var(--padding-5xs)
    var(--padding-base);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.divider {
  position: relative;
  border-top: 1px solid var(--m3-sys-dark-on-surface-variant);
  height: 0.06rem;
}
.horizontalfullWidth,
.listItem1 {
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
}
.horizontalfullWidth {
  display: none;
  align-items: flex-start;
  z-index: 2;
}
.listItem1 {
  display: flex;
  align-items: center;
  position: relative;
}
.list,
.stateLayer24 {
  display: flex;
  justify-content: flex-start;
}
.list {
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  flex-direction: column;
  align-items: flex-start;
}
.stateLayer24 {
  align-self: stretch;
  height: 3rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xl) var(--padding-9xs)
    var(--padding-base);
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-base);
  z-index: 1;
}
.content24,
.stateLayer48 {
  align-self: stretch;
  display: flex;
  justify-content: center;
}
.content24 {
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
}
.stateLayer48 {
  height: 2.5rem;
  flex-direction: row;
  padding: 0 var(--padding-5xl) 0 var(--padding-base);
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-base);
  z-index: 1;
}
.list4Density,
.lists1 {
  display: flex;
  align-items: flex-start;
}
.list4Density {
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--m3-label-medium-size);
}
.lists1 {
  align-self: stretch;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-surface-container-high);
  flex-direction: row;
  padding: var(--padding-21xl);
  justify-content: center;
  gap: var(--gap-xl);
}
.label {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 1.5rem;
  font-weight: 500;
}
.buildingBlocksTitle {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--m3-black);
}
.handle,
.track {
  position: absolute;
  border-radius: var(--br-81xl);
}
.track {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--m3-sys-dark-on-surface);
  border: 2px solid var(--m3-sys-light-outline);
  box-sizing: border-box;
}
.handle {
  top: calc(50% - 8px);
  left: calc(50% - 8px);
  background-color: var(--m3-sys-light-outline);
  width: 1rem;
  height: 1rem;
}
.handleContainer {
  position: absolute;
  top: calc(50% - 24px);
  left: -0.5rem;
  width: 3rem;
  height: 3rem;
}
.switch {
  position: relative;
  width: 3.25rem;
  height: 2rem;
}
.overline36 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 500;
}
.iconsarrowRight24px {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
}
.condition1LineLeadingswit,
.stateLayer72 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stateLayer72 {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-5xl) var(--padding-5xs)
    var(--padding-base);
  gap: var(--gap-base);
  z-index: 1;
}
.condition1LineLeadingswit {
  position: absolute;
  top: 474rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 3.5rem;
  flex-direction: column;
}
.trailingSupportingText {
  position: relative;
  font-size: var(--m3-label-small-size);
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 500;
  text-align: right;
  display: none;
}
.stateLayer73 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-5xl) var(--padding-5xs)
    var(--padding-base);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.condition1LineLeadingswit1,
.condition2LineLeadingswit,
.condition2LineLeadingswit1 {
  position: absolute;
  top: 474rem;
  left: 1rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingswit,
.condition2LineLeadingswit1 {
  top: 468.25rem;
  left: 24.75rem;
  height: 4.5rem;
}
.condition2LineLeadingswit1 {
  left: 1rem;
}
.supportingText40 {
  align-self: stretch;
  position: relative;
  font-size: var(--m3-label-large-prominent-size);
  letter-spacing: 0.25px;
  line-height: 1.25rem;
}
.content40,
.stateLayer76 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.content40 {
  flex: 1;
  overflow: hidden;
  flex-direction: column;
}
.stateLayer76 {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-5xl) var(--padding-xs)
    var(--padding-base);
  gap: var(--gap-base);
  z-index: 1;
}
.condition3LineLeadingswi {
  position: absolute;
  top: 460.5rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  align-items: center;
}
.condition3LineLeadingswi,
.condition3LineLeadingswi1,
.leadingElement30 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.condition3LineLeadingswi1 {
  position: absolute;
  top: 460.5rem;
  left: 1rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  align-items: center;
}
.leadingElement30 {
  height: 4rem;
  align-items: flex-start;
}
.condition3LineLeadingswi2,
.condition3LineLeadingswi3,
.condition3LineLeadingswi4,
.condition3LineLeadingswi5 {
  position: absolute;
  top: 453.75rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingswi3,
.condition3LineLeadingswi4,
.condition3LineLeadingswi5 {
  left: 1rem;
}
.condition3LineLeadingswi4,
.condition3LineLeadingswi5 {
  top: 447rem;
  left: 24.75rem;
}
.condition3LineLeadingswi5 {
  left: 1rem;
}
.stateLayer83 {
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: center;
}
.container36 {
  border-radius: var(--br-81xl);
  overflow: hidden;
}
.container36,
.leadingElement34 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingradi,
.condition1LineLeadingradi1 {
  position: absolute;
  top: 437.25rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingradi1 {
  left: 1rem;
}
.condition2LineLeadingradi {
  top: 431.5rem;
  left: 24.75rem;
  height: 4.5rem;
  justify-content: center;
}
.condition2LineLeadingradi,
.condition2LineLeadingradi1,
.condition3LineLeadingrad,
.condition3LineLeadingrad1 {
  position: absolute;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingradi1 {
  top: 431.5rem;
  left: 1rem;
  height: 4.5rem;
  justify-content: center;
}
.condition3LineLeadingrad,
.condition3LineLeadingrad1 {
  top: 423.75rem;
  left: 24.75rem;
  justify-content: flex-start;
}
.condition3LineLeadingrad1 {
  left: 1rem;
}
.leadingElement40 {
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.condition3LineLeadingrad2,
.condition3LineLeadingrad3 {
  position: absolute;
  top: 417rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingrad3 {
  left: 1rem;
}
.condition1LineLeadingchec,
.condition1LineLeadingchec1,
.condition2LineLeadingchec,
.condition2LineLeadingchec1,
.condition3LineLeadingrad4,
.condition3LineLeadingrad5 {
  position: absolute;
  top: 410.25rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingchec,
.condition1LineLeadingchec1,
.condition2LineLeadingchec,
.condition2LineLeadingchec1,
.condition3LineLeadingrad5 {
  left: 1rem;
}
.condition1LineLeadingchec,
.condition1LineLeadingchec1,
.condition2LineLeadingchec,
.condition2LineLeadingchec1 {
  top: 400.5rem;
  left: 24.75rem;
  height: 3.5rem;
  justify-content: center;
}
.condition1LineLeadingchec1,
.condition2LineLeadingchec,
.condition2LineLeadingchec1 {
  left: 1rem;
}
.condition2LineLeadingchec,
.condition2LineLeadingchec1 {
  top: 394.75rem;
  left: 24.75rem;
  height: 4.5rem;
}
.condition2LineLeadingchec1 {
  left: 1rem;
}
.condition3LineLeadingche,
.condition3LineLeadingche1,
.condition3LineLeadingche2,
.condition3LineLeadingche3,
.condition3LineLeadingche4,
.condition3LineLeadingche5 {
  position: absolute;
  top: 387rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingche1,
.condition3LineLeadingche2,
.condition3LineLeadingche3,
.condition3LineLeadingche4,
.condition3LineLeadingche5 {
  left: 1rem;
}
.condition3LineLeadingche2,
.condition3LineLeadingche3,
.condition3LineLeadingche4,
.condition3LineLeadingche5 {
  top: 380.25rem;
  left: 24.75rem;
}
.condition3LineLeadingche3,
.condition3LineLeadingche4,
.condition3LineLeadingche5 {
  left: 1rem;
}
.condition3LineLeadingche4,
.condition3LineLeadingche5 {
  top: 373.5rem;
  left: 24.75rem;
}
.condition3LineLeadingche5 {
  left: 1rem;
}
.thumbnailIcon {
  position: relative;
  width: 7.13rem;
  height: 4rem;
  object-fit: cover;
}
.buildingBlocksvideoThumbnai,
.leadingElement54 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.leadingElement54 {
  overflow: hidden;
  justify-content: center;
}
.stateLayer122 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-5xl) var(--padding-xs) 0;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.condition2LineLeadingvide,
.condition2LineLeadingvide1,
.condition2LineLeadingvide2,
.condition2LineLeadingvide3,
.condition2LineLeadingvide4 {
  position: absolute;
  top: 361.75rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 5.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingvide1,
.condition2LineLeadingvide2,
.condition2LineLeadingvide3,
.condition2LineLeadingvide4 {
  left: 72.25rem;
}
.condition2LineLeadingvide2,
.condition2LineLeadingvide3,
.condition2LineLeadingvide4 {
  left: 48.5rem;
}
.condition2LineLeadingvide3,
.condition2LineLeadingvide4 {
  left: 24.75rem;
}
.condition2LineLeadingvide4 {
  left: 1rem;
}
.leadingElement59,
.stateLayer129 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.leadingElement59 {
  overflow: hidden;
  flex-direction: column;
}
.stateLayer129 {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-5xl) var(--padding-xs) 0;
  gap: var(--gap-base);
  z-index: 1;
}
.condition3LineLeadingvid,
.condition3LineLeadingvid1 {
  position: absolute;
  top: 353.38rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  flex-direction: column;
}
.condition3LineLeadingvid {
  left: 96rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingvid1 {
  left: 48.5rem;
}
.condition3LineLeadingvid1,
.condition3LineLeadingvid2,
.stateLayer134 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingvid2 {
  position: absolute;
  top: 353.38rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  flex-direction: column;
}
.stateLayer134 {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-5xl) var(--padding-xs) 0;
  gap: var(--gap-base);
  z-index: 1;
}
.condition3LineLeadingvid3,
.condition3LineLeadingvid4,
.condition3LineLeadingvid5,
.condition3LineLeadingvid6,
.condition3LineLeadingvid7 {
  position: absolute;
  top: 353.38rem;
  left: 1rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingvid4,
.condition3LineLeadingvid5,
.condition3LineLeadingvid6,
.condition3LineLeadingvid7 {
  top: 352.75rem;
  left: 72.25rem;
}
.condition3LineLeadingvid5,
.condition3LineLeadingvid6,
.condition3LineLeadingvid7 {
  top: 346rem;
  left: 96rem;
}
.condition3LineLeadingvid6,
.condition3LineLeadingvid7 {
  left: 72.25rem;
}
.condition3LineLeadingvid7 {
  left: 48.5rem;
}
.condition3LineLeadingvid10,
.condition3LineLeadingvid11,
.condition3LineLeadingvid12,
.condition3LineLeadingvid13,
.condition3LineLeadingvid14,
.condition3LineLeadingvid8,
.condition3LineLeadingvid9 {
  position: absolute;
  top: 346rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingvid10,
.condition3LineLeadingvid11,
.condition3LineLeadingvid12,
.condition3LineLeadingvid13,
.condition3LineLeadingvid14,
.condition3LineLeadingvid9 {
  left: 1rem;
}
.condition3LineLeadingvid10,
.condition3LineLeadingvid11,
.condition3LineLeadingvid12,
.condition3LineLeadingvid13,
.condition3LineLeadingvid14 {
  top: 339.25rem;
  left: 96rem;
}
.condition3LineLeadingvid11,
.condition3LineLeadingvid12,
.condition3LineLeadingvid13,
.condition3LineLeadingvid14 {
  left: 72.25rem;
}
.condition3LineLeadingvid12,
.condition3LineLeadingvid13,
.condition3LineLeadingvid14 {
  left: 48.5rem;
}
.condition3LineLeadingvid13,
.condition3LineLeadingvid14 {
  left: 24.75rem;
}
.condition3LineLeadingvid14 {
  left: 1rem;
}
.thumbnailIcon20 {
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  object-fit: cover;
}
.buildingBlocksimageThumbnai {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.condition2LineLeadingimag,
.condition2LineLeadingimag1,
.condition2LineLeadingimag2,
.condition2LineLeadingimag3,
.condition2LineLeadingimag4 {
  position: absolute;
  top: 324.5rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingimag1,
.condition2LineLeadingimag2,
.condition2LineLeadingimag3,
.condition2LineLeadingimag4 {
  left: 72.25rem;
}
.condition2LineLeadingimag2,
.condition2LineLeadingimag3,
.condition2LineLeadingimag4 {
  left: 48.5rem;
}
.condition2LineLeadingimag3,
.condition2LineLeadingimag4 {
  left: 24.75rem;
}
.condition2LineLeadingimag4 {
  left: 1rem;
}
.condition3LineLeadingima,
.condition3LineLeadingima1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.condition3LineLeadingima {
  position: absolute;
  top: 316.13rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  align-items: center;
}
.condition3LineLeadingima1 {
  left: 48.5rem;
}
.condition3LineLeadingima1,
.condition3LineLeadingima2,
.condition3LineLeadingima3 {
  position: absolute;
  top: 316.13rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  align-items: center;
}
.condition3LineLeadingima2 {
  left: 24.75rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.condition3LineLeadingima3 {
  left: 1rem;
}
.condition3LineLeadingima3,
.condition3LineLeadingima4,
.leadingElement84 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.condition3LineLeadingima4 {
  position: absolute;
  top: 315.5rem;
  left: 72.25rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  align-items: center;
}
.leadingElement84 {
  height: 4rem;
  overflow: hidden;
  align-items: flex-start;
}
.condition3LineLeadingima5,
.condition3LineLeadingima6,
.condition3LineLeadingima7,
.condition3LineLeadingima8,
.condition3LineLeadingima9 {
  position: absolute;
  top: 308.75rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingima6,
.condition3LineLeadingima7,
.condition3LineLeadingima8,
.condition3LineLeadingima9 {
  left: 72.25rem;
}
.condition3LineLeadingima7,
.condition3LineLeadingima8,
.condition3LineLeadingima9 {
  left: 48.5rem;
}
.condition3LineLeadingima8,
.condition3LineLeadingima9 {
  left: 24.75rem;
}
.condition3LineLeadingima9 {
  left: 1rem;
}
.condition1LineLeadingicon,
.condition3LineLeadingima10,
.condition3LineLeadingima11,
.condition3LineLeadingima12,
.condition3LineLeadingima13,
.condition3LineLeadingima14 {
  position: absolute;
  top: 302rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingicon,
.condition3LineLeadingima11,
.condition3LineLeadingima12,
.condition3LineLeadingima13,
.condition3LineLeadingima14 {
  left: 72.25rem;
}
.condition1LineLeadingicon,
.condition3LineLeadingima12,
.condition3LineLeadingima13,
.condition3LineLeadingima14 {
  left: 48.5rem;
}
.condition1LineLeadingicon,
.condition3LineLeadingima13,
.condition3LineLeadingima14 {
  left: 24.75rem;
}
.condition1LineLeadingicon,
.condition3LineLeadingima14 {
  left: 1rem;
}
.condition1LineLeadingicon {
  top: 292.25rem;
  left: 96rem;
  height: 3.5rem;
  justify-content: center;
}
.condition1LineLeadingicon1,
.condition1LineLeadingicon2,
.condition1LineLeadingicon3,
.condition1LineLeadingicon4,
.condition2LineLeadingicon,
.condition2LineLeadingicon1,
.condition2LineLeadingicon2 {
  position: absolute;
  top: 292.25rem;
  left: 72.25rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingicon2,
.condition1LineLeadingicon3,
.condition1LineLeadingicon4,
.condition2LineLeadingicon,
.condition2LineLeadingicon1,
.condition2LineLeadingicon2 {
  left: 48.5rem;
}
.condition1LineLeadingicon3,
.condition1LineLeadingicon4,
.condition2LineLeadingicon,
.condition2LineLeadingicon1,
.condition2LineLeadingicon2 {
  left: 24.75rem;
}
.condition1LineLeadingicon4,
.condition2LineLeadingicon,
.condition2LineLeadingicon1,
.condition2LineLeadingicon2 {
  left: 1rem;
}
.condition2LineLeadingicon,
.condition2LineLeadingicon1,
.condition2LineLeadingicon2 {
  top: 286.5rem;
  left: 96rem;
  height: 4.5rem;
}
.condition2LineLeadingicon1,
.condition2LineLeadingicon2 {
  left: 72.25rem;
}
.condition2LineLeadingicon2 {
  left: 48.5rem;
}
.condition2LineLeadingicon3 {
  top: 286.5rem;
  left: 24.75rem;
  height: 4.5rem;
  justify-content: center;
}
.condition2LineLeadingicon3,
.condition2LineLeadingicon4,
.condition3LineLeadingico,
.condition3LineLeadingico1,
.condition3LineLeadingico2 {
  position: absolute;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingicon4 {
  top: 286.5rem;
  left: 1rem;
  height: 4.5rem;
  justify-content: center;
}
.condition3LineLeadingico,
.condition3LineLeadingico1,
.condition3LineLeadingico2 {
  top: 278.75rem;
  left: 96rem;
  justify-content: flex-start;
}
.condition3LineLeadingico1,
.condition3LineLeadingico2 {
  left: 72.25rem;
}
.condition3LineLeadingico2 {
  left: 48.5rem;
}
.condition3LineLeadingico3,
.condition3LineLeadingico4,
.condition3LineLeadingico5,
.condition3LineLeadingico6,
.condition3LineLeadingico7,
.condition3LineLeadingico8,
.condition3LineLeadingico9 {
  position: absolute;
  top: 278.75rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingico4,
.condition3LineLeadingico5,
.condition3LineLeadingico6,
.condition3LineLeadingico7,
.condition3LineLeadingico8,
.condition3LineLeadingico9 {
  left: 1rem;
}
.condition3LineLeadingico5,
.condition3LineLeadingico6,
.condition3LineLeadingico7,
.condition3LineLeadingico8,
.condition3LineLeadingico9 {
  top: 272rem;
  left: 96rem;
}
.condition3LineLeadingico6,
.condition3LineLeadingico7,
.condition3LineLeadingico8,
.condition3LineLeadingico9 {
  left: 72.25rem;
}
.condition3LineLeadingico7,
.condition3LineLeadingico8,
.condition3LineLeadingico9 {
  left: 48.5rem;
}
.condition3LineLeadingico8,
.condition3LineLeadingico9 {
  left: 24.75rem;
}
.condition3LineLeadingico9 {
  left: 1rem;
}
.condition1LineLeadingmono,
.condition3LineLeadingico10,
.condition3LineLeadingico11,
.condition3LineLeadingico12,
.condition3LineLeadingico13,
.condition3LineLeadingico14 {
  position: absolute;
  top: 265.25rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingmono,
.condition3LineLeadingico11,
.condition3LineLeadingico12,
.condition3LineLeadingico13,
.condition3LineLeadingico14 {
  left: 72.25rem;
}
.condition1LineLeadingmono,
.condition3LineLeadingico12,
.condition3LineLeadingico13,
.condition3LineLeadingico14 {
  left: 48.5rem;
}
.condition1LineLeadingmono,
.condition3LineLeadingico13,
.condition3LineLeadingico14 {
  left: 24.75rem;
}
.condition1LineLeadingmono,
.condition3LineLeadingico14 {
  left: 1rem;
}
.condition1LineLeadingmono {
  top: 255.5rem;
  left: 96rem;
  height: 3.5rem;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition1LineLeadingmono1,
.condition1LineLeadingmono2,
.condition1LineLeadingmono3,
.condition1LineLeadingmono4,
.condition2LineLeadingmono,
.condition2LineLeadingmono1,
.condition2LineLeadingmono2,
.condition2LineLeadingmono3,
.condition2LineLeadingmono4 {
  position: absolute;
  top: 255.5rem;
  left: 72.25rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition1LineLeadingmono2,
.condition1LineLeadingmono3,
.condition1LineLeadingmono4,
.condition2LineLeadingmono,
.condition2LineLeadingmono1,
.condition2LineLeadingmono2,
.condition2LineLeadingmono3,
.condition2LineLeadingmono4 {
  left: 48.5rem;
}
.condition1LineLeadingmono3,
.condition1LineLeadingmono4,
.condition2LineLeadingmono,
.condition2LineLeadingmono1,
.condition2LineLeadingmono2,
.condition2LineLeadingmono3,
.condition2LineLeadingmono4 {
  left: 24.75rem;
}
.condition1LineLeadingmono4,
.condition2LineLeadingmono,
.condition2LineLeadingmono1,
.condition2LineLeadingmono2,
.condition2LineLeadingmono3,
.condition2LineLeadingmono4 {
  left: 1rem;
}
.condition2LineLeadingmono,
.condition2LineLeadingmono1,
.condition2LineLeadingmono2,
.condition2LineLeadingmono3,
.condition2LineLeadingmono4 {
  top: 249.75rem;
  left: 96rem;
  height: 4.5rem;
}
.condition2LineLeadingmono1,
.condition2LineLeadingmono2,
.condition2LineLeadingmono3,
.condition2LineLeadingmono4 {
  left: 72.25rem;
}
.condition2LineLeadingmono2,
.condition2LineLeadingmono3,
.condition2LineLeadingmono4 {
  left: 48.5rem;
}
.condition2LineLeadingmono3,
.condition2LineLeadingmono4 {
  left: 24.75rem;
}
.condition2LineLeadingmono4 {
  left: 1rem;
}
.leadingElement129 {
  overflow: hidden;
  align-items: flex-start;
  color: var(--m3-sys-light-on-primary-container);
}
.condition3LineLeadingmon,
.content141,
.leadingElement129 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.content141 {
  flex: 1;
  overflow: hidden;
  align-items: flex-start;
  text-align: left;
  font-size: var(--m3-label-medium-size);
}
.condition3LineLeadingmon {
  left: 96rem;
}
.condition3LineLeadingmon,
.condition3LineLeadingmon1,
.condition3LineLeadingmon2 {
  position: absolute;
  top: 242rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  align-items: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon1 {
  left: 72.25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.condition3LineLeadingmon2 {
  left: 48.5rem;
}
.condition3LineLeadingmon2,
.condition3LineLeadingmon3,
.leadingElement133 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.condition3LineLeadingmon3 {
  position: absolute;
  top: 242rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  align-items: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.leadingElement133 {
  height: 4rem;
  overflow: hidden;
  align-items: flex-start;
  color: var(--m3-sys-light-on-primary-container);
}
.condition3LineLeadingmon4,
.condition3LineLeadingmon5 {
  position: absolute;
  top: 242rem;
  left: 1rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon5 {
  top: 235.25rem;
  left: 96rem;
}
.condition3LineLeadingmon10,
.condition3LineLeadingmon11,
.condition3LineLeadingmon12,
.condition3LineLeadingmon13,
.condition3LineLeadingmon14,
.condition3LineLeadingmon6,
.condition3LineLeadingmon7,
.condition3LineLeadingmon8,
.condition3LineLeadingmon9 {
  position: absolute;
  top: 235.25rem;
  left: 72.25rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon10,
.condition3LineLeadingmon11,
.condition3LineLeadingmon12,
.condition3LineLeadingmon13,
.condition3LineLeadingmon14,
.condition3LineLeadingmon7,
.condition3LineLeadingmon8,
.condition3LineLeadingmon9 {
  left: 48.5rem;
}
.condition3LineLeadingmon10,
.condition3LineLeadingmon11,
.condition3LineLeadingmon12,
.condition3LineLeadingmon13,
.condition3LineLeadingmon14,
.condition3LineLeadingmon8,
.condition3LineLeadingmon9 {
  left: 24.75rem;
}
.condition3LineLeadingmon10,
.condition3LineLeadingmon11,
.condition3LineLeadingmon12,
.condition3LineLeadingmon13,
.condition3LineLeadingmon14,
.condition3LineLeadingmon9 {
  left: 1rem;
}
.condition3LineLeadingmon10,
.condition3LineLeadingmon11,
.condition3LineLeadingmon12,
.condition3LineLeadingmon13,
.condition3LineLeadingmon14 {
  top: 228.5rem;
  left: 96rem;
}
.condition3LineLeadingmon11,
.condition3LineLeadingmon12,
.condition3LineLeadingmon13,
.condition3LineLeadingmon14 {
  left: 72.25rem;
}
.condition3LineLeadingmon12,
.condition3LineLeadingmon13,
.condition3LineLeadingmon14 {
  left: 48.5rem;
}
.condition3LineLeadingmon13,
.condition3LineLeadingmon14 {
  left: 24.75rem;
}
.condition3LineLeadingmon14 {
  left: 1rem;
}
.condition1LineLeadingnone,
.condition1LineLeadingnone1,
.condition1LineLeadingnone2,
.condition1LineLeadingnone3 {
  position: absolute;
  top: 218.75rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingnone1,
.condition1LineLeadingnone2,
.condition1LineLeadingnone3 {
  left: 72.25rem;
}
.condition1LineLeadingnone2,
.condition1LineLeadingnone3 {
  left: 48.5rem;
}
.condition1LineLeadingnone3 {
  left: 24.75rem;
}
.overline160 {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 16.5rem;
}
.condition1LineLeadingnone4,
.condition2LineLeadingnone,
.condition2LineLeadingnone1,
.condition2LineLeadingnone2,
.condition2LineLeadingnone3,
.condition2LineLeadingnone4 {
  position: absolute;
  top: 218.75rem;
  left: 1rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingnone,
.condition2LineLeadingnone1,
.condition2LineLeadingnone2,
.condition2LineLeadingnone3,
.condition2LineLeadingnone4 {
  top: 213rem;
  left: 96rem;
  height: 4.5rem;
}
.condition2LineLeadingnone1,
.condition2LineLeadingnone2,
.condition2LineLeadingnone3,
.condition2LineLeadingnone4 {
  left: 72.25rem;
}
.condition2LineLeadingnone2,
.condition2LineLeadingnone3,
.condition2LineLeadingnone4 {
  left: 48.5rem;
}
.condition2LineLeadingnone3,
.condition2LineLeadingnone4 {
  left: 24.75rem;
}
.condition2LineLeadingnone4 {
  left: 1rem;
}
.content166,
.trailingElement131 {
  display: flex;
  align-items: flex-start;
}
.content166 {
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
}
.trailingElement131 {
  height: 4rem;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  text-align: right;
  font-size: var(--m3-label-small-size);
}
.condition3LineLeadingnon {
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
}
.condition3LineLeadingnon,
.condition3LineLeadingnon1,
.condition3LineLeadingnon2 {
  position: absolute;
  top: 205.25rem;
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingnon1 {
  left: 72.25rem;
  background-color: var(--m3-sys-light-surface);
}
.condition3LineLeadingnon2 {
  left: 48.5rem;
}
.condition3LineLeadingnon2,
.condition3LineLeadingnon3,
.minHeightFix {
  background-color: var(--m3-sys-light-surface);
}
.condition3LineLeadingnon3 {
  position: absolute;
  top: 205.25rem;
  left: 24.75rem;
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.minHeightFix {
  position: relative;
  width: 0.5rem;
  height: 4rem;
  opacity: 0;
}
.stateLayer268 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) 0 var(--padding-xs) var(--padding-base);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.condition3LineLeadingnon4,
.condition3LineLeadingnon5,
.condition3LineLeadingnon6,
.condition3LineLeadingnon7,
.condition3LineLeadingnon8,
.condition3LineLeadingnon9 {
  position: absolute;
  top: 205.25rem;
  left: 1rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingnon5,
.condition3LineLeadingnon6,
.condition3LineLeadingnon7,
.condition3LineLeadingnon8,
.condition3LineLeadingnon9 {
  top: 198.5rem;
  left: 96rem;
}
.condition3LineLeadingnon6,
.condition3LineLeadingnon7,
.condition3LineLeadingnon8,
.condition3LineLeadingnon9 {
  left: 72.25rem;
}
.condition3LineLeadingnon7,
.condition3LineLeadingnon8,
.condition3LineLeadingnon9 {
  left: 48.5rem;
}
.condition3LineLeadingnon8,
.condition3LineLeadingnon9 {
  left: 24.75rem;
}
.condition3LineLeadingnon9 {
  left: 1rem;
}
.condition1LineLeadingswit2,
.condition1LineLeadingswit3,
.condition3LineLeadingnon10,
.condition3LineLeadingnon11,
.condition3LineLeadingnon12,
.condition3LineLeadingnon13,
.condition3LineLeadingnon14 {
  position: absolute;
  top: 191.75rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingswit2,
.condition1LineLeadingswit3,
.condition3LineLeadingnon11,
.condition3LineLeadingnon12,
.condition3LineLeadingnon13,
.condition3LineLeadingnon14 {
  left: 72.25rem;
}
.condition1LineLeadingswit2,
.condition1LineLeadingswit3,
.condition3LineLeadingnon12,
.condition3LineLeadingnon13,
.condition3LineLeadingnon14 {
  left: 48.5rem;
}
.condition1LineLeadingswit2,
.condition1LineLeadingswit3,
.condition3LineLeadingnon13,
.condition3LineLeadingnon14 {
  left: 24.75rem;
}
.condition1LineLeadingswit2,
.condition1LineLeadingswit3,
.condition3LineLeadingnon14 {
  left: 1rem;
}
.condition1LineLeadingswit2,
.condition1LineLeadingswit3 {
  top: 175.75rem;
  left: 24.75rem;
  height: 3.5rem;
  justify-content: center;
}
.condition1LineLeadingswit3 {
  left: 1rem;
}
.supportingText183 {
  align-self: stretch;
  position: relative;
  font-size: var(--m3-label-large-prominent-size);
  letter-spacing: 0.25px;
  line-height: 1.25rem;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 1.25rem;
  flex-shrink: 0;
}
.condition2LineLeadingswit2,
.condition2LineLeadingswit3 {
  position: absolute;
  top: 170rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingswit3 {
  left: 1rem;
}
.condition1LineLeadingradi2,
.condition1LineLeadingradi3,
.condition2LineLeadingradi2,
.condition2LineLeadingradi3,
.condition3LineLeadingswi6,
.condition3LineLeadingswi7 {
  position: absolute;
  top: 163.25rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingradi2,
.condition1LineLeadingradi3,
.condition2LineLeadingradi2,
.condition2LineLeadingradi3,
.condition3LineLeadingswi7 {
  left: 1rem;
}
.condition1LineLeadingradi2,
.condition1LineLeadingradi3,
.condition2LineLeadingradi2,
.condition2LineLeadingradi3 {
  top: 153.5rem;
  left: 24.75rem;
  height: 3.5rem;
  justify-content: center;
}
.condition1LineLeadingradi3,
.condition2LineLeadingradi2,
.condition2LineLeadingradi3 {
  left: 1rem;
}
.condition2LineLeadingradi2,
.condition2LineLeadingradi3 {
  top: 147.75rem;
  left: 24.75rem;
  height: 4.5rem;
}
.condition2LineLeadingradi3 {
  left: 1rem;
}
.condition1LineLeadingchec2,
.condition1LineLeadingchec3,
.condition2LineLeadingchec2,
.condition2LineLeadingchec3,
.condition3LineLeadingrad6,
.condition3LineLeadingrad7 {
  position: absolute;
  top: 141rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingchec2,
.condition1LineLeadingchec3,
.condition2LineLeadingchec2,
.condition2LineLeadingchec3,
.condition3LineLeadingrad7 {
  left: 1rem;
}
.condition1LineLeadingchec2,
.condition1LineLeadingchec3,
.condition2LineLeadingchec2,
.condition2LineLeadingchec3 {
  top: 131.25rem;
  left: 24.75rem;
  height: 3.5rem;
  justify-content: center;
}
.condition1LineLeadingchec3,
.condition2LineLeadingchec2,
.condition2LineLeadingchec3 {
  left: 1rem;
}
.condition2LineLeadingchec2,
.condition2LineLeadingchec3 {
  top: 125.5rem;
  left: 24.75rem;
  height: 4.5rem;
}
.condition2LineLeadingchec3 {
  left: 1rem;
}
.condition1LineLeadingvide,
.condition1LineLeadingvide1,
.condition1LineLeadingvide2,
.condition3LineLeadingche6,
.condition3LineLeadingche7 {
  position: absolute;
  top: 118.75rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingvide,
.condition1LineLeadingvide1,
.condition1LineLeadingvide2,
.condition3LineLeadingche7 {
  left: 1rem;
}
.condition1LineLeadingvide,
.condition1LineLeadingvide1,
.condition1LineLeadingvide2 {
  top: 107rem;
  left: 96rem;
  height: 5.5rem;
  justify-content: center;
}
.condition1LineLeadingvide1,
.condition1LineLeadingvide2 {
  left: 72.25rem;
}
.condition1LineLeadingvide2 {
  left: 48.5rem;
}
.condition1LineLeadingvide3,
.condition1LineLeadingvide4,
.condition2LineLeadingvide5,
.condition2LineLeadingvide6,
.condition2LineLeadingvide7,
.condition2LineLeadingvide8,
.condition2LineLeadingvide9 {
  position: absolute;
  top: 107rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 5.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingvide4,
.condition2LineLeadingvide5,
.condition2LineLeadingvide6,
.condition2LineLeadingvide7,
.condition2LineLeadingvide8,
.condition2LineLeadingvide9 {
  left: 1rem;
}
.condition2LineLeadingvide5,
.condition2LineLeadingvide6,
.condition2LineLeadingvide7,
.condition2LineLeadingvide8,
.condition2LineLeadingvide9 {
  top: 100.25rem;
  left: 96rem;
}
.condition2LineLeadingvide6,
.condition2LineLeadingvide7,
.condition2LineLeadingvide8,
.condition2LineLeadingvide9 {
  left: 72.25rem;
}
.condition2LineLeadingvide7,
.condition2LineLeadingvide8,
.condition2LineLeadingvide9 {
  left: 48.5rem;
}
.condition2LineLeadingvide8,
.condition2LineLeadingvide9 {
  left: 24.75rem;
}
.condition2LineLeadingvide9 {
  left: 1rem;
}
.condition1LineLeadingimag,
.condition3LineLeadingvid15,
.condition3LineLeadingvid16,
.condition3LineLeadingvid17,
.condition3LineLeadingvid18,
.condition3LineLeadingvid19 {
  position: absolute;
  top: 92.25rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingimag,
.condition3LineLeadingvid16,
.condition3LineLeadingvid17,
.condition3LineLeadingvid18,
.condition3LineLeadingvid19 {
  left: 72.25rem;
}
.condition1LineLeadingimag,
.condition3LineLeadingvid17,
.condition3LineLeadingvid18,
.condition3LineLeadingvid19 {
  left: 48.5rem;
}
.condition1LineLeadingimag,
.condition3LineLeadingvid18,
.condition3LineLeadingvid19 {
  left: 24.75rem;
}
.condition1LineLeadingimag,
.condition3LineLeadingvid19 {
  left: 1rem;
}
.condition1LineLeadingimag {
  top: 81.5rem;
  left: 96rem;
  height: 4.5rem;
  justify-content: center;
}
.condition1LineLeadingimag1,
.condition1LineLeadingimag2,
.condition1LineLeadingimag3,
.condition1LineLeadingimag4,
.condition2LineLeadingimag5,
.condition2LineLeadingimag6,
.condition2LineLeadingimag7 {
  position: absolute;
  top: 81.5rem;
  left: 72.25rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingimag2,
.condition1LineLeadingimag3,
.condition1LineLeadingimag4,
.condition2LineLeadingimag5,
.condition2LineLeadingimag6,
.condition2LineLeadingimag7 {
  left: 48.5rem;
}
.condition1LineLeadingimag3,
.condition1LineLeadingimag4,
.condition2LineLeadingimag5,
.condition2LineLeadingimag6,
.condition2LineLeadingimag7 {
  left: 24.75rem;
}
.condition1LineLeadingimag4,
.condition2LineLeadingimag5,
.condition2LineLeadingimag6,
.condition2LineLeadingimag7 {
  left: 1rem;
}
.condition2LineLeadingimag5,
.condition2LineLeadingimag6,
.condition2LineLeadingimag7 {
  top: 75.75rem;
  left: 96rem;
}
.condition2LineLeadingimag6,
.condition2LineLeadingimag7 {
  left: 72.25rem;
}
.condition2LineLeadingimag7 {
  left: 48.5rem;
}
.condition2LineLeadingimag8 {
  top: 75.75rem;
  left: 24.75rem;
  height: 4.5rem;
  justify-content: center;
}
.condition2LineLeadingimag8,
.condition2LineLeadingimag9,
.condition3LineLeadingima15 {
  position: absolute;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingimag9 {
  top: 75.75rem;
  left: 1rem;
  height: 4.5rem;
  justify-content: center;
}
.condition3LineLeadingima15 {
  top: 67.75rem;
  left: 96rem;
  justify-content: flex-start;
}
.condition1LineLeadingicon5,
.condition1LineLeadingicon6,
.condition1LineLeadingicon7,
.condition3LineLeadingima16,
.condition3LineLeadingima17,
.condition3LineLeadingima18,
.condition3LineLeadingima19 {
  position: absolute;
  top: 67.75rem;
  left: 72.25rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingicon5,
.condition1LineLeadingicon6,
.condition1LineLeadingicon7,
.condition3LineLeadingima17,
.condition3LineLeadingima18,
.condition3LineLeadingima19 {
  left: 48.5rem;
}
.condition1LineLeadingicon5,
.condition1LineLeadingicon6,
.condition1LineLeadingicon7,
.condition3LineLeadingima18,
.condition3LineLeadingima19 {
  left: 24.75rem;
}
.condition1LineLeadingicon5,
.condition1LineLeadingicon6,
.condition1LineLeadingicon7,
.condition3LineLeadingima19 {
  left: 1rem;
}
.condition1LineLeadingicon5,
.condition1LineLeadingicon6,
.condition1LineLeadingicon7 {
  top: 58rem;
  left: 96rem;
  height: 3.5rem;
  justify-content: center;
}
.condition1LineLeadingicon6,
.condition1LineLeadingicon7 {
  left: 72.25rem;
}
.condition1LineLeadingicon7 {
  left: 48.5rem;
}
.condition1LineLeadingicon8,
.condition1LineLeadingicon9,
.condition2LineLeadingicon5,
.condition2LineLeadingicon6,
.condition2LineLeadingicon7,
.condition2LineLeadingicon8,
.condition2LineLeadingicon9 {
  position: absolute;
  top: 58rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingicon9,
.condition2LineLeadingicon5,
.condition2LineLeadingicon6,
.condition2LineLeadingicon7,
.condition2LineLeadingicon8,
.condition2LineLeadingicon9 {
  left: 1rem;
}
.condition2LineLeadingicon5,
.condition2LineLeadingicon6,
.condition2LineLeadingicon7,
.condition2LineLeadingicon8,
.condition2LineLeadingicon9 {
  top: 52.25rem;
  left: 96rem;
  height: 4.5rem;
}
.condition2LineLeadingicon6,
.condition2LineLeadingicon7,
.condition2LineLeadingicon8,
.condition2LineLeadingicon9 {
  left: 72.25rem;
}
.condition2LineLeadingicon7,
.condition2LineLeadingicon8,
.condition2LineLeadingicon9 {
  left: 48.5rem;
}
.condition2LineLeadingicon8,
.condition2LineLeadingicon9 {
  left: 24.75rem;
}
.condition2LineLeadingicon9 {
  left: 1rem;
}
.condition3LineLeadingico15,
.condition3LineLeadingico16,
.condition3LineLeadingico17,
.condition3LineLeadingico18,
.condition3LineLeadingico19 {
  position: absolute;
  top: 45.5rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingico16,
.condition3LineLeadingico17,
.condition3LineLeadingico18,
.condition3LineLeadingico19 {
  left: 72.25rem;
}
.condition3LineLeadingico17,
.condition3LineLeadingico18,
.condition3LineLeadingico19 {
  left: 48.5rem;
}
.condition3LineLeadingico18,
.condition3LineLeadingico19 {
  left: 24.75rem;
}
.condition3LineLeadingico19 {
  left: 1rem;
}
.condition1LineLeadingmono5,
.condition1LineLeadingmono6,
.condition1LineLeadingmono7,
.condition1LineLeadingmono8,
.condition1LineLeadingmono9,
.condition2LineLeadingmono5,
.condition2LineLeadingmono6,
.condition2LineLeadingmono7 {
  position: absolute;
  top: 35.75rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition1LineLeadingmono6,
.condition1LineLeadingmono7,
.condition1LineLeadingmono8,
.condition1LineLeadingmono9,
.condition2LineLeadingmono5,
.condition2LineLeadingmono6,
.condition2LineLeadingmono7 {
  left: 72.25rem;
}
.condition1LineLeadingmono7,
.condition1LineLeadingmono8,
.condition1LineLeadingmono9,
.condition2LineLeadingmono5,
.condition2LineLeadingmono6,
.condition2LineLeadingmono7 {
  left: 48.5rem;
}
.condition1LineLeadingmono8,
.condition1LineLeadingmono9,
.condition2LineLeadingmono5,
.condition2LineLeadingmono6,
.condition2LineLeadingmono7 {
  left: 24.75rem;
}
.condition1LineLeadingmono9,
.condition2LineLeadingmono5,
.condition2LineLeadingmono6,
.condition2LineLeadingmono7 {
  left: 1rem;
}
.condition2LineLeadingmono5,
.condition2LineLeadingmono6,
.condition2LineLeadingmono7 {
  top: 30rem;
  left: 96rem;
  height: 4.5rem;
}
.condition2LineLeadingmono6,
.condition2LineLeadingmono7 {
  left: 72.25rem;
}
.condition2LineLeadingmono7 {
  left: 48.5rem;
}
.condition2LineLeadingmono8 {
  top: 30rem;
  left: 24.75rem;
  height: 4.5rem;
  justify-content: center;
}
.condition2LineLeadingmono8,
.condition2LineLeadingmono9,
.condition3LineLeadingmon15,
.condition3LineLeadingmon16,
.condition3LineLeadingmon17,
.condition3LineLeadingmon18,
.condition3LineLeadingmon19 {
  position: absolute;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition2LineLeadingmono9 {
  top: 30rem;
  left: 1rem;
  height: 4.5rem;
  justify-content: center;
}
.condition3LineLeadingmon15,
.condition3LineLeadingmon16,
.condition3LineLeadingmon17,
.condition3LineLeadingmon18,
.condition3LineLeadingmon19 {
  top: 23.25rem;
  left: 96rem;
  justify-content: flex-start;
}
.condition3LineLeadingmon16,
.condition3LineLeadingmon17,
.condition3LineLeadingmon18,
.condition3LineLeadingmon19 {
  left: 72.25rem;
}
.condition3LineLeadingmon17,
.condition3LineLeadingmon18,
.condition3LineLeadingmon19 {
  left: 48.5rem;
}
.condition3LineLeadingmon18,
.condition3LineLeadingmon19 {
  left: 24.75rem;
}
.condition3LineLeadingmon19 {
  left: 1rem;
}
.condition1LineLeadingnone5,
.condition1LineLeadingnone6,
.condition1LineLeadingnone7,
.condition1LineLeadingnone8 {
  position: absolute;
  top: 13.5rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingnone6,
.condition1LineLeadingnone7,
.condition1LineLeadingnone8 {
  left: 72.25rem;
}
.condition1LineLeadingnone7,
.condition1LineLeadingnone8 {
  left: 48.5rem;
}
.condition1LineLeadingnone8 {
  left: 24.75rem;
}
.overline263 {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 500;
  display: none;
  align-items: center;
  width: 16.5rem;
}
.condition1LineLeadingnone9,
.condition2LineLeadingnone5,
.condition2LineLeadingnone6,
.condition2LineLeadingnone7,
.condition2LineLeadingnone8,
.condition2LineLeadingnone9 {
  position: absolute;
  top: 13.5rem;
  left: 1rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingnone5,
.condition2LineLeadingnone6,
.condition2LineLeadingnone7,
.condition2LineLeadingnone8,
.condition2LineLeadingnone9 {
  top: 7.75rem;
  left: 96rem;
  height: 4.5rem;
}
.condition2LineLeadingnone6,
.condition2LineLeadingnone7,
.condition2LineLeadingnone8,
.condition2LineLeadingnone9 {
  left: 72.25rem;
}
.condition2LineLeadingnone7,
.condition2LineLeadingnone8,
.condition2LineLeadingnone9 {
  left: 48.5rem;
}
.condition2LineLeadingnone8,
.condition2LineLeadingnone9 {
  left: 24.75rem;
}
.condition2LineLeadingnone9 {
  left: 1rem;
}
.condition3LineLeadingnon15,
.condition3LineLeadingnon16,
.condition3LineLeadingnon17,
.condition3LineLeadingnon18,
.condition3LineLeadingnon19 {
  position: absolute;
  top: 1rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingnon16,
.condition3LineLeadingnon17,
.condition3LineLeadingnon18,
.condition3LineLeadingnon19 {
  left: 72.25rem;
}
.condition3LineLeadingnon17,
.condition3LineLeadingnon18,
.condition3LineLeadingnon19 {
  left: 48.5rem;
}
.condition3LineLeadingnon18,
.condition3LineLeadingnon19 {
  left: 24.75rem;
}
.condition3LineLeadingnon19 {
  left: 1rem;
}
.listItemlistItem0Density {
  position: relative;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-surface-container-low);
  width: 47.5rem;
  height: 181.38rem;
  overflow: hidden;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--m3-label-medium-size);
}
.condition2LineLeadingswit4,
.stateLayer418 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stateLayer418 {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xl) var(--padding-9xs)
    var(--padding-base);
  gap: var(--gap-base);
  z-index: 1;
}
.condition2LineLeadingswit4 {
  position: absolute;
  top: 468.25rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 4rem;
  flex-direction: column;
}
.stateLayer419 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xl) var(--padding-9xs)
    var(--padding-base);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.condition2LineLeadingswit5 {
  position: absolute;
  top: 468.25rem;
  left: 1rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.stateLayer420 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-5xl) var(--padding-5xs)
    var(--padding-base);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.condition2LineLeadingchec4,
.condition2LineLeadingchec5,
.condition2LineLeadingradi4,
.condition2LineLeadingradi5 {
  position: absolute;
  top: 431.5rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingchec4,
.condition2LineLeadingchec5,
.condition2LineLeadingradi5 {
  left: 1rem;
}
.condition2LineLeadingchec4,
.condition2LineLeadingchec5 {
  top: 394.75rem;
  left: 24.75rem;
}
.condition2LineLeadingchec5 {
  left: 1rem;
}
.stateLayer458 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-5xl) var(--padding-5xs) 0;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.condition2LineLeadingvide10,
.condition2LineLeadingvide11,
.condition2LineLeadingvide12,
.condition2LineLeadingvide13,
.condition2LineLeadingvide14 {
  position: absolute;
  top: 361.75rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingvide11,
.condition2LineLeadingvide12,
.condition2LineLeadingvide13,
.condition2LineLeadingvide14 {
  left: 72.25rem;
}
.condition2LineLeadingvide12,
.condition2LineLeadingvide13,
.condition2LineLeadingvide14 {
  left: 48.5rem;
}
.condition2LineLeadingvide13,
.condition2LineLeadingvide14 {
  left: 24.75rem;
}
.condition2LineLeadingvide14 {
  left: 1rem;
}
.stateLayer465,
.stateLayer470 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-5xl) var(--padding-5xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.stateLayer470 {
  align-items: center;
}
.condition2LineLeadingimag10 {
  position: absolute;
  top: 324.5rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingicon10,
.condition2LineLeadingicon11,
.condition2LineLeadingicon12,
.condition2LineLeadingimag11,
.condition2LineLeadingimag12,
.condition2LineLeadingimag13,
.condition2LineLeadingimag14 {
  position: absolute;
  top: 324.5rem;
  left: 72.25rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingicon10,
.condition2LineLeadingicon11,
.condition2LineLeadingicon12,
.condition2LineLeadingimag12,
.condition2LineLeadingimag13,
.condition2LineLeadingimag14 {
  left: 48.5rem;
}
.condition2LineLeadingicon10,
.condition2LineLeadingicon11,
.condition2LineLeadingicon12,
.condition2LineLeadingimag13,
.condition2LineLeadingimag14 {
  left: 24.75rem;
}
.condition2LineLeadingicon10,
.condition2LineLeadingicon11,
.condition2LineLeadingicon12,
.condition2LineLeadingimag14 {
  left: 1rem;
}
.condition2LineLeadingicon10,
.condition2LineLeadingicon11,
.condition2LineLeadingicon12 {
  top: 286.5rem;
  left: 96rem;
}
.condition2LineLeadingicon11,
.condition2LineLeadingicon12 {
  left: 72.25rem;
}
.condition2LineLeadingicon12 {
  left: 48.5rem;
}
.condition2LineLeadingicon13,
.condition2LineLeadingicon14,
.condition2LineLeadingmono10,
.condition2LineLeadingmono11,
.condition2LineLeadingmono12,
.condition2LineLeadingmono13,
.condition2LineLeadingmono14 {
  position: absolute;
  top: 286.5rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingicon14,
.condition2LineLeadingmono10,
.condition2LineLeadingmono11,
.condition2LineLeadingmono12,
.condition2LineLeadingmono13,
.condition2LineLeadingmono14 {
  left: 1rem;
}
.condition2LineLeadingmono10,
.condition2LineLeadingmono11,
.condition2LineLeadingmono12,
.condition2LineLeadingmono13,
.condition2LineLeadingmono14 {
  top: 249.75rem;
  left: 96rem;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition2LineLeadingmono11,
.condition2LineLeadingmono12,
.condition2LineLeadingmono13,
.condition2LineLeadingmono14 {
  left: 72.25rem;
}
.condition2LineLeadingmono12,
.condition2LineLeadingmono13,
.condition2LineLeadingmono14 {
  left: 48.5rem;
}
.condition2LineLeadingmono13,
.condition2LineLeadingmono14 {
  left: 24.75rem;
}
.condition2LineLeadingmono14 {
  left: 1rem;
}
.condition2LineLeadingnone10,
.condition2LineLeadingnone11,
.condition2LineLeadingnone12,
.condition2LineLeadingnone13,
.condition2LineLeadingnone14 {
  position: absolute;
  top: 213rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingnone11,
.condition2LineLeadingnone12,
.condition2LineLeadingnone13,
.condition2LineLeadingnone14 {
  left: 72.25rem;
}
.condition2LineLeadingnone12,
.condition2LineLeadingnone13,
.condition2LineLeadingnone14 {
  left: 48.5rem;
}
.condition2LineLeadingnone13,
.condition2LineLeadingnone14 {
  left: 24.75rem;
}
.condition2LineLeadingnone14 {
  left: 1rem;
}
.stateLayer583 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 0 var(--padding-5xs) var(--padding-base);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.condition1LineLeadingswit4,
.condition1LineLeadingswit5 {
  position: absolute;
  top: 175.75rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingswit5 {
  left: 1rem;
}
.condition1LineLeadingradi4,
.condition1LineLeadingradi5,
.condition2LineLeadingradi6,
.condition2LineLeadingradi7,
.condition2LineLeadingswit6,
.condition2LineLeadingswit7 {
  position: absolute;
  top: 170rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingradi4,
.condition1LineLeadingradi5,
.condition2LineLeadingradi6,
.condition2LineLeadingradi7,
.condition2LineLeadingswit7 {
  left: 1rem;
}
.condition1LineLeadingradi4,
.condition1LineLeadingradi5,
.condition2LineLeadingradi6,
.condition2LineLeadingradi7 {
  top: 153.5rem;
  left: 24.75rem;
  height: 3rem;
}
.condition1LineLeadingradi5,
.condition2LineLeadingradi6,
.condition2LineLeadingradi7 {
  left: 1rem;
}
.condition2LineLeadingradi6,
.condition2LineLeadingradi7 {
  top: 147.75rem;
  left: 24.75rem;
  height: 4rem;
}
.condition2LineLeadingradi7 {
  left: 1rem;
}
.condition1LineLeadingchec4,
.condition1LineLeadingchec5,
.condition1LineLeadingvide5,
.condition1LineLeadingvide6,
.condition1LineLeadingvide7,
.condition2LineLeadingchec6,
.condition2LineLeadingchec7 {
  position: absolute;
  top: 131.25rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingchec5,
.condition1LineLeadingvide5,
.condition1LineLeadingvide6,
.condition1LineLeadingvide7,
.condition2LineLeadingchec6,
.condition2LineLeadingchec7 {
  left: 1rem;
}
.condition1LineLeadingvide5,
.condition1LineLeadingvide6,
.condition1LineLeadingvide7,
.condition2LineLeadingchec6,
.condition2LineLeadingchec7 {
  top: 125.5rem;
  left: 24.75rem;
  height: 4rem;
}
.condition1LineLeadingvide5,
.condition1LineLeadingvide6,
.condition1LineLeadingvide7,
.condition2LineLeadingchec7 {
  left: 1rem;
}
.condition1LineLeadingvide5,
.condition1LineLeadingvide6,
.condition1LineLeadingvide7 {
  top: 107rem;
  left: 96rem;
  height: 5rem;
}
.condition1LineLeadingvide6,
.condition1LineLeadingvide7 {
  left: 72.25rem;
}
.condition1LineLeadingvide7 {
  left: 48.5rem;
}
.condition1LineLeadingvide8,
.condition1LineLeadingvide9,
.condition2LineLeadingvide15,
.condition2LineLeadingvide16,
.condition2LineLeadingvide17,
.condition2LineLeadingvide18,
.condition2LineLeadingvide19 {
  position: absolute;
  top: 107rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingvide9,
.condition2LineLeadingvide15,
.condition2LineLeadingvide16,
.condition2LineLeadingvide17,
.condition2LineLeadingvide18,
.condition2LineLeadingvide19 {
  left: 1rem;
}
.condition2LineLeadingvide15,
.condition2LineLeadingvide16,
.condition2LineLeadingvide17,
.condition2LineLeadingvide18,
.condition2LineLeadingvide19 {
  top: 100.25rem;
  left: 96rem;
}
.condition2LineLeadingvide16,
.condition2LineLeadingvide17,
.condition2LineLeadingvide18,
.condition2LineLeadingvide19 {
  left: 72.25rem;
}
.condition2LineLeadingvide17,
.condition2LineLeadingvide18,
.condition2LineLeadingvide19 {
  left: 48.5rem;
}
.condition2LineLeadingvide18,
.condition2LineLeadingvide19 {
  left: 24.75rem;
}
.condition2LineLeadingvide19 {
  left: 1rem;
}
.condition1LineLeadingimag5,
.condition1LineLeadingimag6,
.condition1LineLeadingimag7,
.condition1LineLeadingimag8,
.condition1LineLeadingimag9,
.condition2LineLeadingimag15 {
  position: absolute;
  top: 81.5rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingimag6,
.condition1LineLeadingimag7,
.condition1LineLeadingimag8,
.condition1LineLeadingimag9,
.condition2LineLeadingimag15 {
  left: 72.25rem;
}
.condition1LineLeadingimag7,
.condition1LineLeadingimag8,
.condition1LineLeadingimag9,
.condition2LineLeadingimag15 {
  left: 48.5rem;
}
.condition1LineLeadingimag8,
.condition1LineLeadingimag9,
.condition2LineLeadingimag15 {
  left: 24.75rem;
}
.condition1LineLeadingimag9,
.condition2LineLeadingimag15 {
  left: 1rem;
}
.condition2LineLeadingimag15 {
  top: 75.75rem;
  left: 96rem;
}
.condition1LineLeadingicon10,
.condition1LineLeadingicon11,
.condition1LineLeadingicon12,
.condition2LineLeadingimag16,
.condition2LineLeadingimag17,
.condition2LineLeadingimag18,
.condition2LineLeadingimag19 {
  position: absolute;
  top: 75.75rem;
  left: 72.25rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingicon10,
.condition1LineLeadingicon11,
.condition1LineLeadingicon12,
.condition2LineLeadingimag17,
.condition2LineLeadingimag18,
.condition2LineLeadingimag19 {
  left: 48.5rem;
}
.condition1LineLeadingicon10,
.condition1LineLeadingicon11,
.condition1LineLeadingicon12,
.condition2LineLeadingimag18,
.condition2LineLeadingimag19 {
  left: 24.75rem;
}
.condition1LineLeadingicon10,
.condition1LineLeadingicon11,
.condition1LineLeadingicon12,
.condition2LineLeadingimag19 {
  left: 1rem;
}
.condition1LineLeadingicon10,
.condition1LineLeadingicon11,
.condition1LineLeadingicon12 {
  top: 58rem;
  left: 96rem;
  height: 3rem;
}
.condition1LineLeadingicon11,
.condition1LineLeadingicon12 {
  left: 72.25rem;
}
.condition1LineLeadingicon12 {
  left: 48.5rem;
}
.condition1LineLeadingicon13,
.condition1LineLeadingicon14,
.condition2LineLeadingicon15,
.condition2LineLeadingicon16,
.condition2LineLeadingicon17,
.condition2LineLeadingicon18,
.condition2LineLeadingicon19 {
  position: absolute;
  top: 58rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingicon14,
.condition2LineLeadingicon15,
.condition2LineLeadingicon16,
.condition2LineLeadingicon17,
.condition2LineLeadingicon18,
.condition2LineLeadingicon19 {
  left: 1rem;
}
.condition2LineLeadingicon15,
.condition2LineLeadingicon16,
.condition2LineLeadingicon17,
.condition2LineLeadingicon18,
.condition2LineLeadingicon19 {
  top: 52.25rem;
  left: 96rem;
  height: 4rem;
}
.condition2LineLeadingicon16,
.condition2LineLeadingicon17,
.condition2LineLeadingicon18,
.condition2LineLeadingicon19 {
  left: 72.25rem;
}
.condition2LineLeadingicon17,
.condition2LineLeadingicon18,
.condition2LineLeadingicon19 {
  left: 48.5rem;
}
.condition2LineLeadingicon18,
.condition2LineLeadingicon19 {
  left: 24.75rem;
}
.condition2LineLeadingicon19 {
  left: 1rem;
}
.condition1LineLeadingmono10 {
  top: 35.75rem;
  left: 96rem;
  height: 3rem;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition1LineLeadingmono10,
.condition1LineLeadingmono11,
.condition1LineLeadingmono12 {
  position: absolute;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingmono11 {
  top: 35.75rem;
  left: 72.25rem;
  height: 3rem;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition1LineLeadingmono12 {
  left: 48.5rem;
}
.condition1LineLeadingmono12,
.condition1LineLeadingmono13,
.condition1LineLeadingmono14 {
  top: 35.75rem;
  height: 3rem;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition1LineLeadingmono13 {
  position: absolute;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingmono14 {
  left: 1rem;
}
.condition1LineLeadingmono14,
.condition2LineLeadingmono15,
.condition2LineLeadingmono16 {
  position: absolute;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingmono15 {
  top: 30rem;
  left: 96rem;
  height: 4rem;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition2LineLeadingmono16 {
  left: 72.25rem;
}
.condition2LineLeadingmono16,
.condition2LineLeadingmono17,
.condition2LineLeadingmono18 {
  top: 30rem;
  height: 4rem;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition2LineLeadingmono17 {
  position: absolute;
  left: 48.5rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingmono18 {
  left: 24.75rem;
}
.condition1LineLeadingnone10,
.condition1LineLeadingnone11,
.condition1LineLeadingnone12,
.condition2LineLeadingmono18,
.condition2LineLeadingmono19 {
  position: absolute;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingmono19 {
  top: 30rem;
  left: 1rem;
  height: 4rem;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition1LineLeadingnone10,
.condition1LineLeadingnone11,
.condition1LineLeadingnone12 {
  top: 13.5rem;
  left: 96rem;
  height: 3rem;
}
.condition1LineLeadingnone11,
.condition1LineLeadingnone12 {
  left: 72.25rem;
}
.condition1LineLeadingnone12 {
  left: 48.5rem;
}
.condition1LineLeadingnone13,
.condition1LineLeadingnone14,
.condition2LineLeadingnone15,
.condition2LineLeadingnone16,
.condition2LineLeadingnone17,
.condition2LineLeadingnone18,
.condition2LineLeadingnone19 {
  position: absolute;
  top: 13.5rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingnone14,
.condition2LineLeadingnone15,
.condition2LineLeadingnone16,
.condition2LineLeadingnone17,
.condition2LineLeadingnone18,
.condition2LineLeadingnone19 {
  left: 1rem;
}
.condition2LineLeadingnone15,
.condition2LineLeadingnone16,
.condition2LineLeadingnone17,
.condition2LineLeadingnone18,
.condition2LineLeadingnone19 {
  top: 7.75rem;
  left: 96rem;
  height: 4rem;
}
.condition2LineLeadingnone16,
.condition2LineLeadingnone17,
.condition2LineLeadingnone18,
.condition2LineLeadingnone19 {
  left: 72.25rem;
}
.condition2LineLeadingnone17,
.condition2LineLeadingnone18,
.condition2LineLeadingnone19 {
  left: 48.5rem;
}
.condition2LineLeadingnone18,
.condition2LineLeadingnone19 {
  left: 24.75rem;
}
.condition2LineLeadingnone19 {
  left: 1rem;
}
.listItemlistItem2Densit {
  position: relative;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-surface-container-low);
  width: 48.75rem;
  height: 181.38rem;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  text-align: left;
  font-size: var(--m3-label-medium-size);
}
.condition2LineLeadingswit8,
.stateLayer733 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stateLayer733 {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: 0 var(--padding-5xl) 0 var(--padding-base);
  gap: var(--gap-base);
  z-index: 1;
}
.condition2LineLeadingswit8 {
  position: absolute;
  top: 468.5rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 3.5rem;
  flex-direction: column;
}
.stateLayer734 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xl) 0 var(--padding-base);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.condition2LineLeadingswit9 {
  position: absolute;
  top: 468.5rem;
  left: 1rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.stateLayer735 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xl) var(--padding-9xs)
    var(--padding-base);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.condition3LineLeadingswi16,
.condition3LineLeadingswi17 {
  position: absolute;
  top: 460.75rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingswi17 {
  left: 1rem;
}
.condition2LineLeadingradi8,
.condition2LineLeadingradi9,
.condition3LineLeadingswi18,
.condition3LineLeadingswi19,
.condition3LineLeadingswi20,
.condition3LineLeadingswi21 {
  position: absolute;
  top: 454rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition2LineLeadingradi8,
.condition2LineLeadingradi9,
.condition3LineLeadingswi19,
.condition3LineLeadingswi20,
.condition3LineLeadingswi21 {
  left: 1rem;
}
.condition2LineLeadingradi8,
.condition2LineLeadingradi9,
.condition3LineLeadingswi20,
.condition3LineLeadingswi21 {
  top: 447.25rem;
  left: 24.75rem;
}
.condition2LineLeadingradi8,
.condition2LineLeadingradi9,
.condition3LineLeadingswi21 {
  left: 1rem;
}
.condition2LineLeadingradi8,
.condition2LineLeadingradi9 {
  top: 431.75rem;
  left: 24.75rem;
  height: 3.5rem;
  justify-content: center;
}
.condition2LineLeadingradi9 {
  left: 1rem;
}
.condition3LineLeadingrad16,
.condition3LineLeadingrad17,
.condition3LineLeadingrad18,
.condition3LineLeadingrad19,
.condition3LineLeadingrad20,
.condition3LineLeadingrad21 {
  position: absolute;
  top: 424rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingrad17,
.condition3LineLeadingrad18,
.condition3LineLeadingrad19,
.condition3LineLeadingrad20,
.condition3LineLeadingrad21 {
  left: 1rem;
}
.condition3LineLeadingrad18,
.condition3LineLeadingrad19,
.condition3LineLeadingrad20,
.condition3LineLeadingrad21 {
  top: 417.25rem;
  left: 24.75rem;
}
.condition3LineLeadingrad19,
.condition3LineLeadingrad20,
.condition3LineLeadingrad21 {
  left: 1rem;
}
.condition3LineLeadingrad20,
.condition3LineLeadingrad21 {
  top: 410.5rem;
  left: 24.75rem;
}
.condition3LineLeadingrad21 {
  left: 1rem;
}
.condition2LineLeadingchec8 {
  top: 395rem;
  left: 24.75rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingchec8,
.condition2LineLeadingchec9,
.condition3LineLeadingche16 {
  position: absolute;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  flex-direction: column;
}
.condition2LineLeadingchec9 {
  top: 395rem;
  left: 1rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.condition3LineLeadingche16 {
  top: 387.25rem;
  left: 24.75rem;
}
.condition3LineLeadingche16,
.condition3LineLeadingche17,
.condition3LineLeadingche18 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingche17 {
  position: absolute;
  top: 387.25rem;
  left: 1rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  flex-direction: column;
}
.condition3LineLeadingche18 {
  top: 380.5rem;
  left: 24.75rem;
}
.condition3LineLeadingche18,
.condition3LineLeadingche19,
.condition3LineLeadingche20 {
  position: absolute;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  flex-direction: column;
}
.condition3LineLeadingche19 {
  top: 380.5rem;
  left: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingche20 {
  top: 373.75rem;
  left: 24.75rem;
}
.condition3LineLeadingche20,
.condition3LineLeadingche21,
.stateLayer773 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingche21 {
  position: absolute;
  top: 373.75rem;
  left: 1rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  flex-direction: column;
}
.stateLayer773 {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xl) var(--padding-9xs) 0;
  gap: var(--gap-base);
  z-index: 1;
}
.condition2LineLeadingvide20,
.condition2LineLeadingvide21,
.condition2LineLeadingvide22,
.condition2LineLeadingvide23,
.condition2LineLeadingvide24 {
  position: absolute;
  top: 362rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingvide21,
.condition2LineLeadingvide22,
.condition2LineLeadingvide23,
.condition2LineLeadingvide24 {
  left: 72.25rem;
}
.condition2LineLeadingvide22,
.condition2LineLeadingvide23,
.condition2LineLeadingvide24 {
  left: 48.5rem;
}
.condition2LineLeadingvide23,
.condition2LineLeadingvide24 {
  left: 24.75rem;
}
.condition2LineLeadingvide24 {
  left: 1rem;
}
.stateLayer780 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xl) var(--padding-9xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.condition3LineLeadingvid40,
.condition3LineLeadingvid41 {
  position: absolute;
  top: 353.63rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  flex-direction: column;
}
.condition3LineLeadingvid40 {
  left: 96rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingvid41 {
  left: 48.5rem;
}
.condition3LineLeadingvid41,
.condition3LineLeadingvid42,
.stateLayer785 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingvid42 {
  position: absolute;
  top: 353.63rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  flex-direction: column;
}
.stateLayer785 {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xl) var(--padding-9xs) 0;
  gap: var(--gap-base);
  z-index: 1;
}
.condition3LineLeadingvid43,
.condition3LineLeadingvid44,
.condition3LineLeadingvid45 {
  position: absolute;
  top: 353.63rem;
  left: 1rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingvid44,
.condition3LineLeadingvid45 {
  top: 353rem;
  left: 72.25rem;
}
.condition3LineLeadingvid45 {
  top: 346.25rem;
  left: 96rem;
}
.condition3LineLeadingvid46,
.condition3LineLeadingvid47,
.condition3LineLeadingvid48,
.condition3LineLeadingvid49,
.condition3LineLeadingvid50,
.condition3LineLeadingvid51,
.condition3LineLeadingvid52 {
  position: absolute;
  top: 346.25rem;
  left: 72.25rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingvid47,
.condition3LineLeadingvid48,
.condition3LineLeadingvid49,
.condition3LineLeadingvid50,
.condition3LineLeadingvid51,
.condition3LineLeadingvid52 {
  left: 48.5rem;
}
.condition3LineLeadingvid48,
.condition3LineLeadingvid49,
.condition3LineLeadingvid50,
.condition3LineLeadingvid51,
.condition3LineLeadingvid52 {
  left: 24.75rem;
}
.condition3LineLeadingvid49,
.condition3LineLeadingvid50,
.condition3LineLeadingvid51,
.condition3LineLeadingvid52 {
  left: 1rem;
}
.condition3LineLeadingvid50,
.condition3LineLeadingvid51,
.condition3LineLeadingvid52 {
  top: 339.5rem;
  left: 96rem;
}
.condition3LineLeadingvid51,
.condition3LineLeadingvid52 {
  left: 72.25rem;
}
.condition3LineLeadingvid52 {
  left: 48.5rem;
}
.condition3LineLeadingima40,
.condition3LineLeadingima41,
.condition3LineLeadingima42,
.condition3LineLeadingima43,
.condition3LineLeadingima44,
.condition3LineLeadingvid53,
.condition3LineLeadingvid54 {
  position: absolute;
  top: 339.5rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingima40,
.condition3LineLeadingima41,
.condition3LineLeadingima42,
.condition3LineLeadingima43,
.condition3LineLeadingima44,
.condition3LineLeadingvid54 {
  left: 1rem;
}
.condition3LineLeadingima40,
.condition3LineLeadingima41,
.condition3LineLeadingima42,
.condition3LineLeadingima43,
.condition3LineLeadingima44 {
  top: 316.38rem;
  left: 96rem;
}
.condition3LineLeadingima41,
.condition3LineLeadingima42,
.condition3LineLeadingima43,
.condition3LineLeadingima44 {
  left: 48.5rem;
}
.condition3LineLeadingima42,
.condition3LineLeadingima43,
.condition3LineLeadingima44 {
  left: 24.75rem;
}
.condition3LineLeadingima43,
.condition3LineLeadingima44 {
  left: 1rem;
}
.condition3LineLeadingima44 {
  top: 315.75rem;
  left: 72.25rem;
}
.condition3LineLeadingima45,
.condition3LineLeadingima46,
.condition3LineLeadingima47,
.condition3LineLeadingima48,
.condition3LineLeadingima49,
.condition3LineLeadingima50 {
  position: absolute;
  top: 309rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingima46,
.condition3LineLeadingima47,
.condition3LineLeadingima48,
.condition3LineLeadingima49,
.condition3LineLeadingima50 {
  left: 72.25rem;
}
.condition3LineLeadingima47,
.condition3LineLeadingima48,
.condition3LineLeadingima49,
.condition3LineLeadingima50 {
  left: 48.5rem;
}
.condition3LineLeadingima48,
.condition3LineLeadingima49,
.condition3LineLeadingima50 {
  left: 24.75rem;
}
.condition3LineLeadingima49,
.condition3LineLeadingima50 {
  left: 1rem;
}
.condition3LineLeadingima50 {
  top: 302.25rem;
  left: 96rem;
}
.condition2LineLeadingicon20,
.condition2LineLeadingicon21,
.condition2LineLeadingicon22,
.condition3LineLeadingima51,
.condition3LineLeadingima52,
.condition3LineLeadingima53,
.condition3LineLeadingima54 {
  position: absolute;
  top: 302.25rem;
  left: 72.25rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition2LineLeadingicon20,
.condition2LineLeadingicon21,
.condition2LineLeadingicon22,
.condition3LineLeadingima52,
.condition3LineLeadingima53,
.condition3LineLeadingima54 {
  left: 48.5rem;
}
.condition2LineLeadingicon20,
.condition2LineLeadingicon21,
.condition2LineLeadingicon22,
.condition3LineLeadingima53,
.condition3LineLeadingima54 {
  left: 24.75rem;
}
.condition2LineLeadingicon20,
.condition2LineLeadingicon21,
.condition2LineLeadingicon22,
.condition3LineLeadingima54 {
  left: 1rem;
}
.condition2LineLeadingicon20,
.condition2LineLeadingicon21,
.condition2LineLeadingicon22 {
  top: 286.75rem;
  left: 96rem;
  height: 3.5rem;
  justify-content: center;
}
.condition2LineLeadingicon21,
.condition2LineLeadingicon22 {
  left: 72.25rem;
}
.condition2LineLeadingicon22 {
  left: 48.5rem;
}
.condition2LineLeadingicon23 {
  top: 286.75rem;
  left: 24.75rem;
  height: 3.5rem;
  justify-content: center;
}
.condition2LineLeadingicon23,
.condition2LineLeadingicon24,
.condition3LineLeadingico40 {
  position: absolute;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingicon24 {
  top: 286.75rem;
  left: 1rem;
  height: 3.5rem;
  justify-content: center;
}
.condition3LineLeadingico40 {
  top: 279rem;
  left: 96rem;
  justify-content: flex-start;
}
.condition3LineLeadingico41,
.condition3LineLeadingico42,
.condition3LineLeadingico43,
.condition3LineLeadingico44,
.condition3LineLeadingico45,
.condition3LineLeadingico46,
.condition3LineLeadingico47 {
  position: absolute;
  top: 279rem;
  left: 72.25rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingico42,
.condition3LineLeadingico43,
.condition3LineLeadingico44,
.condition3LineLeadingico45,
.condition3LineLeadingico46,
.condition3LineLeadingico47 {
  left: 48.5rem;
}
.condition3LineLeadingico43,
.condition3LineLeadingico44,
.condition3LineLeadingico45,
.condition3LineLeadingico46,
.condition3LineLeadingico47 {
  left: 24.75rem;
}
.condition3LineLeadingico44,
.condition3LineLeadingico45,
.condition3LineLeadingico46,
.condition3LineLeadingico47 {
  left: 1rem;
}
.condition3LineLeadingico45,
.condition3LineLeadingico46,
.condition3LineLeadingico47 {
  top: 272.25rem;
  left: 96rem;
}
.condition3LineLeadingico46,
.condition3LineLeadingico47 {
  left: 72.25rem;
}
.condition3LineLeadingico47 {
  left: 48.5rem;
}
.condition3LineLeadingico48,
.condition3LineLeadingico49,
.condition3LineLeadingico50,
.condition3LineLeadingico51,
.condition3LineLeadingico52,
.condition3LineLeadingico53,
.condition3LineLeadingico54 {
  position: absolute;
  top: 272.25rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingico49,
.condition3LineLeadingico50,
.condition3LineLeadingico51,
.condition3LineLeadingico52,
.condition3LineLeadingico53,
.condition3LineLeadingico54 {
  left: 1rem;
}
.condition3LineLeadingico50,
.condition3LineLeadingico51,
.condition3LineLeadingico52,
.condition3LineLeadingico53,
.condition3LineLeadingico54 {
  top: 265.5rem;
  left: 96rem;
}
.condition3LineLeadingico51,
.condition3LineLeadingico52,
.condition3LineLeadingico53,
.condition3LineLeadingico54 {
  left: 72.25rem;
}
.condition3LineLeadingico52,
.condition3LineLeadingico53,
.condition3LineLeadingico54 {
  left: 48.5rem;
}
.condition3LineLeadingico53,
.condition3LineLeadingico54 {
  left: 24.75rem;
}
.condition3LineLeadingico54 {
  left: 1rem;
}
.condition2LineLeadingmono20,
.condition2LineLeadingmono21,
.condition2LineLeadingmono22,
.condition2LineLeadingmono23,
.condition2LineLeadingmono24 {
  position: absolute;
  top: 250rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition2LineLeadingmono21,
.condition2LineLeadingmono22,
.condition2LineLeadingmono23,
.condition2LineLeadingmono24 {
  left: 72.25rem;
}
.condition2LineLeadingmono22,
.condition2LineLeadingmono23,
.condition2LineLeadingmono24 {
  left: 48.5rem;
}
.condition2LineLeadingmono23,
.condition2LineLeadingmono24 {
  left: 24.75rem;
}
.condition2LineLeadingmono24 {
  left: 1rem;
}
.condition3LineLeadingmon40,
.condition3LineLeadingmon41,
.condition3LineLeadingmon42,
.condition3LineLeadingmon43,
.condition3LineLeadingmon44,
.condition3LineLeadingmon45 {
  position: absolute;
  top: 242.25rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon41,
.condition3LineLeadingmon42,
.condition3LineLeadingmon43,
.condition3LineLeadingmon44,
.condition3LineLeadingmon45 {
  left: 72.25rem;
}
.condition3LineLeadingmon42,
.condition3LineLeadingmon43,
.condition3LineLeadingmon44,
.condition3LineLeadingmon45 {
  left: 48.5rem;
}
.condition3LineLeadingmon43,
.condition3LineLeadingmon44,
.condition3LineLeadingmon45 {
  left: 24.75rem;
}
.condition3LineLeadingmon44,
.condition3LineLeadingmon45 {
  left: 1rem;
}
.condition3LineLeadingmon45 {
  top: 235.5rem;
  left: 96rem;
}
.condition3LineLeadingmon46,
.condition3LineLeadingmon47,
.condition3LineLeadingmon48,
.condition3LineLeadingmon49,
.condition3LineLeadingmon50,
.condition3LineLeadingmon51,
.condition3LineLeadingmon52,
.condition3LineLeadingmon53,
.condition3LineLeadingmon54 {
  position: absolute;
  top: 235.5rem;
  left: 72.25rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon47,
.condition3LineLeadingmon48,
.condition3LineLeadingmon49,
.condition3LineLeadingmon50,
.condition3LineLeadingmon51,
.condition3LineLeadingmon52,
.condition3LineLeadingmon53,
.condition3LineLeadingmon54 {
  left: 48.5rem;
}
.condition3LineLeadingmon48,
.condition3LineLeadingmon49,
.condition3LineLeadingmon50,
.condition3LineLeadingmon51,
.condition3LineLeadingmon52,
.condition3LineLeadingmon53,
.condition3LineLeadingmon54 {
  left: 24.75rem;
}
.condition3LineLeadingmon49,
.condition3LineLeadingmon50,
.condition3LineLeadingmon51,
.condition3LineLeadingmon52,
.condition3LineLeadingmon53,
.condition3LineLeadingmon54 {
  left: 1rem;
}
.condition3LineLeadingmon50,
.condition3LineLeadingmon51,
.condition3LineLeadingmon52,
.condition3LineLeadingmon53,
.condition3LineLeadingmon54 {
  top: 228.75rem;
  left: 96rem;
}
.condition3LineLeadingmon51,
.condition3LineLeadingmon52,
.condition3LineLeadingmon53,
.condition3LineLeadingmon54 {
  left: 72.25rem;
}
.condition3LineLeadingmon52,
.condition3LineLeadingmon53,
.condition3LineLeadingmon54 {
  left: 48.5rem;
}
.condition3LineLeadingmon53,
.condition3LineLeadingmon54 {
  left: 24.75rem;
}
.condition3LineLeadingmon54 {
  left: 1rem;
}
.condition2LineLeadingnone20,
.condition2LineLeadingnone21,
.condition2LineLeadingnone22,
.condition2LineLeadingnone23,
.condition2LineLeadingnone24 {
  position: absolute;
  top: 213.25rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingnone21,
.condition2LineLeadingnone22,
.condition2LineLeadingnone23,
.condition2LineLeadingnone24 {
  left: 72.25rem;
}
.condition2LineLeadingnone22,
.condition2LineLeadingnone23,
.condition2LineLeadingnone24 {
  left: 48.5rem;
}
.condition2LineLeadingnone23,
.condition2LineLeadingnone24 {
  left: 24.75rem;
}
.condition2LineLeadingnone24 {
  left: 1rem;
}
.condition3LineLeadingnon40,
.condition3LineLeadingnon41,
.condition3LineLeadingnon42,
.condition3LineLeadingnon43 {
  position: absolute;
  top: 205.5rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingnon41,
.condition3LineLeadingnon42,
.condition3LineLeadingnon43 {
  left: 72.25rem;
}
.condition3LineLeadingnon42,
.condition3LineLeadingnon43 {
  left: 48.5rem;
}
.condition3LineLeadingnon43 {
  left: 24.75rem;
}
.stateLayer891 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) 0 var(--padding-9xs) var(--padding-base);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  z-index: 1;
}
.condition3LineLeadingnon44,
.condition3LineLeadingnon45 {
  position: absolute;
  top: 205.5rem;
  left: 1rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingnon45 {
  top: 198.75rem;
  left: 96rem;
}
.condition3LineLeadingnon46,
.condition3LineLeadingnon47,
.condition3LineLeadingnon48,
.condition3LineLeadingnon49,
.condition3LineLeadingnon50,
.condition3LineLeadingnon51,
.condition3LineLeadingnon52 {
  position: absolute;
  top: 198.75rem;
  left: 72.25rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingnon47,
.condition3LineLeadingnon48,
.condition3LineLeadingnon49,
.condition3LineLeadingnon50,
.condition3LineLeadingnon51,
.condition3LineLeadingnon52 {
  left: 48.5rem;
}
.condition3LineLeadingnon48,
.condition3LineLeadingnon49,
.condition3LineLeadingnon50,
.condition3LineLeadingnon51,
.condition3LineLeadingnon52 {
  left: 24.75rem;
}
.condition3LineLeadingnon49,
.condition3LineLeadingnon50,
.condition3LineLeadingnon51,
.condition3LineLeadingnon52 {
  left: 1rem;
}
.condition3LineLeadingnon50,
.condition3LineLeadingnon51,
.condition3LineLeadingnon52 {
  top: 192rem;
  left: 96rem;
}
.condition3LineLeadingnon51,
.condition3LineLeadingnon52 {
  left: 72.25rem;
}
.condition3LineLeadingnon52 {
  left: 48.5rem;
}
.condition1LineLeadingswit6,
.condition1LineLeadingswit7,
.condition2LineLeadingswit10,
.condition2LineLeadingswit11,
.condition3LineLeadingnon53,
.condition3LineLeadingnon54 {
  position: absolute;
  top: 192rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingswit6,
.condition1LineLeadingswit7,
.condition2LineLeadingswit10,
.condition2LineLeadingswit11,
.condition3LineLeadingnon54 {
  left: 1rem;
}
.condition1LineLeadingswit6,
.condition1LineLeadingswit7,
.condition2LineLeadingswit10,
.condition2LineLeadingswit11 {
  top: 176rem;
  left: 24.75rem;
  height: 2.5rem;
  justify-content: center;
}
.condition1LineLeadingswit7,
.condition2LineLeadingswit10,
.condition2LineLeadingswit11 {
  left: 1rem;
}
.condition2LineLeadingswit10,
.condition2LineLeadingswit11 {
  top: 170.25rem;
  left: 24.75rem;
  height: 3.5rem;
}
.condition2LineLeadingswit11 {
  left: 1rem;
}
.condition1LineLeadingradi6,
.condition1LineLeadingradi7,
.condition2LineLeadingradi10,
.condition2LineLeadingradi11,
.condition3LineLeadingswi22,
.condition3LineLeadingswi23 {
  position: absolute;
  top: 163.5rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingradi6,
.condition1LineLeadingradi7,
.condition2LineLeadingradi10,
.condition2LineLeadingradi11,
.condition3LineLeadingswi23 {
  left: 1rem;
}
.condition1LineLeadingradi6,
.condition1LineLeadingradi7,
.condition2LineLeadingradi10,
.condition2LineLeadingradi11 {
  top: 153.75rem;
  left: 24.75rem;
  height: 2.5rem;
  justify-content: center;
}
.condition1LineLeadingradi7,
.condition2LineLeadingradi10,
.condition2LineLeadingradi11 {
  left: 1rem;
}
.condition2LineLeadingradi10,
.condition2LineLeadingradi11 {
  top: 148rem;
  left: 24.75rem;
  height: 3.5rem;
}
.condition2LineLeadingradi11 {
  left: 1rem;
}
.condition1LineLeadingchec6,
.condition1LineLeadingchec7,
.condition2LineLeadingchec10,
.condition2LineLeadingchec11,
.condition3LineLeadingrad22,
.condition3LineLeadingrad23 {
  position: absolute;
  top: 141.25rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingchec6,
.condition1LineLeadingchec7,
.condition2LineLeadingchec10,
.condition2LineLeadingchec11,
.condition3LineLeadingrad23 {
  left: 1rem;
}
.condition1LineLeadingchec6,
.condition1LineLeadingchec7,
.condition2LineLeadingchec10,
.condition2LineLeadingchec11 {
  top: 131.5rem;
  left: 24.75rem;
  height: 2.5rem;
  justify-content: center;
}
.condition1LineLeadingchec7,
.condition2LineLeadingchec10,
.condition2LineLeadingchec11 {
  left: 1rem;
}
.condition2LineLeadingchec10,
.condition2LineLeadingchec11 {
  top: 125.75rem;
  left: 24.75rem;
  height: 3.5rem;
}
.condition2LineLeadingchec11 {
  left: 1rem;
}
.condition1LineLeadingvide10,
.condition1LineLeadingvide11,
.condition1LineLeadingvide12,
.condition3LineLeadingche22,
.condition3LineLeadingche23 {
  position: absolute;
  top: 119rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingvide10,
.condition1LineLeadingvide11,
.condition1LineLeadingvide12,
.condition3LineLeadingche23 {
  left: 1rem;
}
.condition1LineLeadingvide10,
.condition1LineLeadingvide11,
.condition1LineLeadingvide12 {
  top: 107.25rem;
  left: 96rem;
  height: 4.5rem;
  justify-content: center;
}
.condition1LineLeadingvide11,
.condition1LineLeadingvide12 {
  left: 72.25rem;
}
.condition1LineLeadingvide12 {
  left: 48.5rem;
}
.condition1LineLeadingvide13,
.condition1LineLeadingvide14,
.condition2LineLeadingvide25,
.condition2LineLeadingvide26,
.condition2LineLeadingvide27,
.condition2LineLeadingvide28,
.condition2LineLeadingvide29 {
  position: absolute;
  top: 107.25rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingvide14,
.condition2LineLeadingvide25,
.condition2LineLeadingvide26,
.condition2LineLeadingvide27,
.condition2LineLeadingvide28,
.condition2LineLeadingvide29 {
  left: 1rem;
}
.condition2LineLeadingvide25,
.condition2LineLeadingvide26,
.condition2LineLeadingvide27,
.condition2LineLeadingvide28,
.condition2LineLeadingvide29 {
  top: 100.5rem;
  left: 96rem;
}
.condition2LineLeadingvide26,
.condition2LineLeadingvide27,
.condition2LineLeadingvide28,
.condition2LineLeadingvide29 {
  left: 72.25rem;
}
.condition2LineLeadingvide27,
.condition2LineLeadingvide28,
.condition2LineLeadingvide29 {
  left: 48.5rem;
}
.condition2LineLeadingvide28,
.condition2LineLeadingvide29 {
  left: 24.75rem;
}
.condition2LineLeadingvide29 {
  left: 1rem;
}
.condition3LineLeadingima55,
.condition3LineLeadingima56,
.condition3LineLeadingvid55,
.condition3LineLeadingvid56,
.condition3LineLeadingvid57,
.condition3LineLeadingvid58,
.condition3LineLeadingvid59 {
  position: absolute;
  top: 92.5rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingima55,
.condition3LineLeadingima56,
.condition3LineLeadingvid56,
.condition3LineLeadingvid57,
.condition3LineLeadingvid58,
.condition3LineLeadingvid59 {
  left: 72.25rem;
}
.condition3LineLeadingima55,
.condition3LineLeadingima56,
.condition3LineLeadingvid57,
.condition3LineLeadingvid58,
.condition3LineLeadingvid59 {
  left: 48.5rem;
}
.condition3LineLeadingima55,
.condition3LineLeadingima56,
.condition3LineLeadingvid58,
.condition3LineLeadingvid59 {
  left: 24.75rem;
}
.condition3LineLeadingima55,
.condition3LineLeadingima56,
.condition3LineLeadingvid59 {
  left: 1rem;
}
.condition3LineLeadingima55,
.condition3LineLeadingima56 {
  top: 68rem;
  left: 96rem;
}
.condition3LineLeadingima56 {
  left: 72.25rem;
}
.condition1LineLeadingicon15,
.condition1LineLeadingicon16,
.condition1LineLeadingicon17,
.condition3LineLeadingima57,
.condition3LineLeadingima58,
.condition3LineLeadingima59 {
  position: absolute;
  top: 68rem;
  left: 48.5rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingicon15,
.condition1LineLeadingicon16,
.condition1LineLeadingicon17,
.condition3LineLeadingima58,
.condition3LineLeadingima59 {
  left: 24.75rem;
}
.condition1LineLeadingicon15,
.condition1LineLeadingicon16,
.condition1LineLeadingicon17,
.condition3LineLeadingima59 {
  left: 1rem;
}
.condition1LineLeadingicon15,
.condition1LineLeadingicon16,
.condition1LineLeadingicon17 {
  top: 58.25rem;
  left: 96rem;
  height: 2.5rem;
  justify-content: center;
}
.condition1LineLeadingicon16,
.condition1LineLeadingicon17 {
  left: 72.25rem;
}
.condition1LineLeadingicon17 {
  left: 48.5rem;
}
.condition1LineLeadingicon18,
.condition1LineLeadingicon19,
.condition2LineLeadingicon25,
.condition2LineLeadingicon26,
.condition2LineLeadingicon27,
.condition2LineLeadingicon28,
.condition2LineLeadingicon29 {
  position: absolute;
  top: 58.25rem;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingicon19,
.condition2LineLeadingicon25,
.condition2LineLeadingicon26,
.condition2LineLeadingicon27,
.condition2LineLeadingicon28,
.condition2LineLeadingicon29 {
  left: 1rem;
}
.condition2LineLeadingicon25,
.condition2LineLeadingicon26,
.condition2LineLeadingicon27,
.condition2LineLeadingicon28,
.condition2LineLeadingicon29 {
  top: 52.5rem;
  left: 96rem;
  height: 3.5rem;
}
.condition2LineLeadingicon26,
.condition2LineLeadingicon27,
.condition2LineLeadingicon28,
.condition2LineLeadingicon29 {
  left: 72.25rem;
}
.condition2LineLeadingicon27,
.condition2LineLeadingicon28,
.condition2LineLeadingicon29 {
  left: 48.5rem;
}
.condition2LineLeadingicon28,
.condition2LineLeadingicon29 {
  left: 24.75rem;
}
.condition2LineLeadingicon29 {
  left: 1rem;
}
.condition3LineLeadingico55,
.condition3LineLeadingico56,
.condition3LineLeadingico57,
.condition3LineLeadingico58,
.condition3LineLeadingico59 {
  position: absolute;
  top: 45.75rem;
  left: 96rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingico56,
.condition3LineLeadingico57,
.condition3LineLeadingico58,
.condition3LineLeadingico59 {
  left: 72.25rem;
}
.condition3LineLeadingico57,
.condition3LineLeadingico58,
.condition3LineLeadingico59 {
  left: 48.5rem;
}
.condition3LineLeadingico58,
.condition3LineLeadingico59 {
  left: 24.75rem;
}
.condition3LineLeadingico59 {
  left: 1rem;
}
.condition2LineLeadingmono25 {
  top: 30.25rem;
  left: 96rem;
  height: 3.5rem;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition2LineLeadingmono25,
.condition2LineLeadingmono26,
.condition2LineLeadingmono27 {
  position: absolute;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingmono26 {
  top: 30.25rem;
  left: 72.25rem;
  height: 3.5rem;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition2LineLeadingmono27 {
  left: 48.5rem;
}
.condition2LineLeadingmono27,
.condition2LineLeadingmono28,
.condition2LineLeadingmono29 {
  top: 30.25rem;
  height: 3.5rem;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition2LineLeadingmono28 {
  position: absolute;
  left: 24.75rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingmono29 {
  left: 1rem;
}
.condition2LineLeadingmono29,
.condition3LineLeadingmon55,
.condition3LineLeadingmon56 {
  position: absolute;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon55 {
  top: 23.5rem;
  left: 96rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon56 {
  left: 72.25rem;
}
.condition3LineLeadingmon56,
.condition3LineLeadingmon57,
.condition3LineLeadingmon58 {
  top: 23.5rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon57 {
  position: absolute;
  left: 48.5rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon58 {
  left: 24.75rem;
}
.condition1LineLeadingnone15,
.condition3LineLeadingmon58,
.condition3LineLeadingmon59 {
  position: absolute;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon59 {
  top: 23.5rem;
  left: 1rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition1LineLeadingnone15 {
  top: 13.75rem;
  left: 96rem;
  height: 2.5rem;
  justify-content: center;
}
.condition1LineLeadingnone16,
.condition1LineLeadingnone17,
.condition1LineLeadingnone18,
.condition1LineLeadingnone19,
.condition2LineLeadingnone25,
.condition2LineLeadingnone26,
.condition2LineLeadingnone27 {
  position: absolute;
  top: 13.75rem;
  left: 72.25rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  height: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingnone17,
.condition1LineLeadingnone18,
.condition1LineLeadingnone19,
.condition2LineLeadingnone25,
.condition2LineLeadingnone26,
.condition2LineLeadingnone27 {
  left: 48.5rem;
}
.condition1LineLeadingnone18,
.condition1LineLeadingnone19,
.condition2LineLeadingnone25,
.condition2LineLeadingnone26,
.condition2LineLeadingnone27 {
  left: 24.75rem;
}
.condition1LineLeadingnone19,
.condition2LineLeadingnone25,
.condition2LineLeadingnone26,
.condition2LineLeadingnone27 {
  left: 1rem;
}
.condition2LineLeadingnone25,
.condition2LineLeadingnone26,
.condition2LineLeadingnone27 {
  top: 8rem;
  left: 96rem;
  height: 3.5rem;
}
.condition2LineLeadingnone26,
.condition2LineLeadingnone27 {
  left: 72.25rem;
}
.condition2LineLeadingnone27 {
  left: 48.5rem;
}
.condition2LineLeadingnone28 {
  top: 8rem;
  left: 24.75rem;
  height: 3.5rem;
  justify-content: center;
}
.condition2LineLeadingnone28,
.condition2LineLeadingnone29,
.condition3LineLeadingnon55 {
  position: absolute;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingnone29 {
  top: 8rem;
  left: 1rem;
  height: 3.5rem;
  justify-content: center;
}
.condition3LineLeadingnon55 {
  top: 1.25rem;
  left: 96rem;
  justify-content: flex-start;
}
.condition3LineLeadingnon56 {
  left: 72.25rem;
  display: flex;
  justify-content: flex-start;
}
.condition3LineLeadingnon56,
.condition3LineLeadingnon57,
.condition3LineLeadingnon58 {
  position: absolute;
  top: 1.25rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingnon57 {
  left: 48.5rem;
  display: flex;
  justify-content: flex-start;
}
.condition3LineLeadingnon58 {
  left: 24.75rem;
}
.condition3LineLeadingnon58,
.condition3LineLeadingnon59,
.label1 {
  display: flex;
  justify-content: flex-start;
}
.condition3LineLeadingnon59 {
  position: absolute;
  top: 1.25rem;
  left: 1rem;
  background-color: var(--m3-sys-light-surface);
  width: 22.5rem;
  flex-direction: column;
  align-items: center;
}
.label1 {
  flex-direction: row;
  padding: var(--padding-3xs);
  align-items: flex-start;
}
.buildingBlocksstateLayer2Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--m3-state-layers-light-on-surface-opacity-008);
}
.buildingBlocksstateLayer1,
.buildingBlocksstateLayer2 {
  position: absolute;
  top: 1.25rem;
  left: 5.38rem;
  width: 2.5rem;
  height: 2.5rem;
}
.buildingBlocksstateLayer1 {
  left: 1.25rem;
}
.buildingBlocksstateLayer3Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--m3-state-layers-light-on-surface-opacity-012);
}
.buildingBlocksstateLayer3 {
  position: absolute;
  top: 1.25rem;
  left: 9.5rem;
  width: 2.5rem;
  height: 2.5rem;
}
.rippleIcon {
  position: absolute;
  height: 67.5%;
  width: 105%;
  top: 42.5%;
  right: -22.5%;
  bottom: -10%;
  left: 17.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.buildingBlocksstateLayer4 {
  position: absolute;
  top: 1.25rem;
  left: 13.63rem;
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
}
.buildingBlocksstateLayer5Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--m3-state-layers-light-on-surface-opacity-016);
}
.buildingBlocksstateLayer5 {
  position: absolute;
  top: 1.25rem;
  left: 17.75rem;
  background-color: var(--m3-sys-light-surface);
  box-shadow: var(--m3-elevation-dark-5);
  width: 2.5rem;
  height: 2.5rem;
}
.stateLayerColors {
  position: relative;
  width: 21.5rem;
  height: 5rem;
}
.buildingBlocks1,
.sharedBuildingBlocks {
  width: 48.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.buildingBlocks1 {
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-surface-container-low);
  padding: var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-21xl);
  color: var(--m3-sys-light-on-primary-container);
}
.sharedBuildingBlocks {
  gap: 3.31rem;
}
.buildingBlocks,
.lightScheme {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buildingBlocks {
  align-self: stretch;
  justify-content: center;
  gap: var(--gap-3xs);
  color: var(--m3-black);
}
.lightScheme {
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-surface);
  border: 1px solid var(--m3-sys-dark-on-surface-variant);
  padding: 0 0 var(--padding-21xl);
  justify-content: flex-start;
  gap: var(--gap-21xl);
}
.buildingBlocksmonogramDark {
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-dark-primary-container);
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  flex-shrink: 0;
}
.headline688 {
  align-self: stretch;
  position: relative;
  font-size: var(--m3-title-medium-size);
  letter-spacing: 0.5px;
  line-height: 1.5rem;
  color: var(--m3-sys-dark-on-surface);
}
.content688 {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-light-on-surface-variant);
}
.container332 {
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--m3-sys-dark-primary);
  width: 1.13rem;
  height: 1.13rem;
  z-index: 0;
}
.iconscheckSmall184 {
  position: absolute;
  margin: 0 !important;
  top: 0.5rem;
  left: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.stateLayer1021,
.trailingElement518 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.stateLayer1021 {
  border-radius: var(--br-81xl);
  overflow: hidden;
  padding: var(--padding-2xs);
  justify-content: center;
  position: relative;
}
.trailingElement518 {
  justify-content: flex-start;
  gap: var(--gap-3xs);
  text-align: right;
  font-size: var(--m3-label-small-size);
  color: var(--m3-sys-dark-on-surface-variant);
}
.divider1340 {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--m3-sys-light-on-surface-variant);
  box-sizing: border-box;
  height: 0.06rem;
}
.listDark {
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  flex-direction: column;
  align-items: flex-start;
}
.list4DensityDark,
.listDark,
.trailingElement530 {
  display: flex;
  justify-content: flex-start;
}
.trailingElement530 {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-3xs);
  text-align: right;
  font-size: var(--m3-label-small-size);
  color: var(--color-gainsboro-100);
}
.list4DensityDark {
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-light-on-surface-variant);
}
.buildingBlocksTitleEmpty,
.listsDark {
  align-self: stretch;
  display: flex;
  justify-content: center;
}
.listsDark {
  border-radius: var(--br-base);
  background-color: var(--m3-sys-dark-surface-container-high);
  flex-direction: row;
  padding: var(--padding-21xl);
  align-items: flex-start;
  gap: var(--gap-xl);
  color: var(--m3-sys-dark-on-primary-container);
}
.buildingBlocksTitleEmpty {
  flex-direction: column;
  align-items: center;
  color: var(--m3-sys-light-on-error);
}
.handle148,
.track148 {
  position: absolute;
  border-radius: var(--br-81xl);
}
.track148 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--m3-sys-dark-surface-container-highest);
  border: 2px solid var(--m3-sys-dark-outline);
  box-sizing: border-box;
}
.handle148 {
  top: calc(50% - 8px);
  left: calc(50% - 8px);
  background-color: var(--m3-sys-dark-outline);
  width: 1rem;
  height: 1rem;
}
.condition1LineLeadingswit8,
.condition1LineLeadingswit9 {
  position: absolute;
  top: 474rem;
  left: 25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingswit9 {
  left: 1.25rem;
}
.condition2LineLeadingswit12 {
  top: 468.25rem;
  left: 25rem;
  height: 4.5rem;
  justify-content: center;
}
.condition2LineLeadingswit12,
.condition2LineLeadingswit13,
.condition3LineLeadingswi24,
.condition3LineLeadingswi25 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingswit13 {
  top: 468.25rem;
  left: 1.25rem;
  height: 4.5rem;
  justify-content: center;
}
.condition3LineLeadingswi24,
.condition3LineLeadingswi25 {
  top: 460.5rem;
  left: 25rem;
  justify-content: flex-start;
}
.condition3LineLeadingswi25 {
  left: 1.25rem;
}
.condition1LineLeadingradi8,
.condition1LineLeadingradi9,
.condition3LineLeadingswi26,
.condition3LineLeadingswi27,
.condition3LineLeadingswi28,
.condition3LineLeadingswi29 {
  position: absolute;
  top: 453.75rem;
  left: 25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingradi8,
.condition1LineLeadingradi9,
.condition3LineLeadingswi27,
.condition3LineLeadingswi28,
.condition3LineLeadingswi29 {
  left: 1.25rem;
}
.condition1LineLeadingradi8,
.condition1LineLeadingradi9,
.condition3LineLeadingswi28,
.condition3LineLeadingswi29 {
  top: 447rem;
  left: 25rem;
}
.condition1LineLeadingradi8,
.condition1LineLeadingradi9,
.condition3LineLeadingswi29 {
  left: 1.25rem;
}
.condition1LineLeadingradi8,
.condition1LineLeadingradi9 {
  top: 437.25rem;
  left: 25rem;
  height: 3.5rem;
  justify-content: center;
}
.condition1LineLeadingradi9 {
  left: 1.25rem;
}
.condition2LineLeadingradi12 {
  top: 431.5rem;
  left: 25rem;
  height: 4.5rem;
  justify-content: center;
}
.condition2LineLeadingradi12,
.condition2LineLeadingradi13,
.condition3LineLeadingrad24,
.condition3LineLeadingrad25 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingradi13 {
  top: 431.5rem;
  left: 1.25rem;
  height: 4.5rem;
  justify-content: center;
}
.condition3LineLeadingrad24,
.condition3LineLeadingrad25 {
  top: 423.75rem;
  left: 25rem;
  justify-content: flex-start;
}
.condition3LineLeadingrad25 {
  left: 1.25rem;
}
.condition1LineLeadingchec8,
.condition1LineLeadingchec9,
.condition3LineLeadingrad26,
.condition3LineLeadingrad27,
.condition3LineLeadingrad28,
.condition3LineLeadingrad29 {
  position: absolute;
  top: 417rem;
  left: 25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingchec8,
.condition1LineLeadingchec9,
.condition3LineLeadingrad27,
.condition3LineLeadingrad28,
.condition3LineLeadingrad29 {
  left: 1.25rem;
}
.condition1LineLeadingchec8,
.condition1LineLeadingchec9,
.condition3LineLeadingrad28,
.condition3LineLeadingrad29 {
  top: 410.25rem;
  left: 25rem;
}
.condition1LineLeadingchec8,
.condition1LineLeadingchec9,
.condition3LineLeadingrad29 {
  left: 1.25rem;
}
.condition1LineLeadingchec8,
.condition1LineLeadingchec9 {
  top: 400.5rem;
  left: 25rem;
  height: 3.5rem;
  justify-content: center;
}
.condition1LineLeadingchec9 {
  left: 1.25rem;
}
.condition2LineLeadingchec12 {
  top: 394.75rem;
  left: 25rem;
  height: 4.5rem;
  justify-content: center;
}
.condition2LineLeadingchec12,
.condition2LineLeadingchec13,
.condition3LineLeadingche24,
.condition3LineLeadingche25,
.condition3LineLeadingche26 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingchec13 {
  top: 394.75rem;
  left: 1.25rem;
  height: 4.5rem;
  justify-content: center;
}
.condition3LineLeadingche24,
.condition3LineLeadingche25,
.condition3LineLeadingche26 {
  top: 387rem;
  left: 25rem;
  justify-content: flex-start;
}
.condition3LineLeadingche25,
.condition3LineLeadingche26 {
  left: 1.25rem;
}
.condition3LineLeadingche26 {
  top: 380.25rem;
  left: 25rem;
}
.condition2LineLeadingvide30,
.condition2LineLeadingvide31,
.condition2LineLeadingvide32,
.condition3LineLeadingche27,
.condition3LineLeadingche28,
.condition3LineLeadingche29 {
  position: absolute;
  top: 380.25rem;
  left: 1.25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition2LineLeadingvide30,
.condition2LineLeadingvide31,
.condition2LineLeadingvide32,
.condition3LineLeadingche28,
.condition3LineLeadingche29 {
  top: 373.5rem;
  left: 25rem;
}
.condition2LineLeadingvide30,
.condition2LineLeadingvide31,
.condition2LineLeadingvide32,
.condition3LineLeadingche29 {
  left: 1.25rem;
}
.condition2LineLeadingvide30,
.condition2LineLeadingvide31,
.condition2LineLeadingvide32 {
  top: 361.75rem;
  left: 96.25rem;
  height: 5.5rem;
  justify-content: center;
}
.condition2LineLeadingvide31,
.condition2LineLeadingvide32 {
  left: 72.5rem;
}
.condition2LineLeadingvide32 {
  left: 48.75rem;
}
.condition2LineLeadingvide33 {
  top: 361.75rem;
  left: 25rem;
  height: 5.5rem;
  justify-content: center;
}
.condition2LineLeadingvide33,
.condition2LineLeadingvide34,
.condition3LineLeadingvid60,
.condition3LineLeadingvid61 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingvide34 {
  top: 361.75rem;
  left: 1.25rem;
  height: 5.5rem;
  justify-content: center;
}
.condition3LineLeadingvid60,
.condition3LineLeadingvid61 {
  top: 353.38rem;
  left: 96.25rem;
  justify-content: flex-start;
}
.condition3LineLeadingvid61 {
  left: 48.75rem;
}
.condition3LineLeadingvid62,
.condition3LineLeadingvid63,
.condition3LineLeadingvid64,
.condition3LineLeadingvid65,
.condition3LineLeadingvid66,
.condition3LineLeadingvid67,
.condition3LineLeadingvid68 {
  position: absolute;
  top: 353.38rem;
  left: 25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingvid63,
.condition3LineLeadingvid64,
.condition3LineLeadingvid65,
.condition3LineLeadingvid66,
.condition3LineLeadingvid67,
.condition3LineLeadingvid68 {
  left: 1.25rem;
}
.condition3LineLeadingvid64,
.condition3LineLeadingvid65,
.condition3LineLeadingvid66,
.condition3LineLeadingvid67,
.condition3LineLeadingvid68 {
  top: 352.75rem;
  left: 72.5rem;
}
.condition3LineLeadingvid65,
.condition3LineLeadingvid66,
.condition3LineLeadingvid67,
.condition3LineLeadingvid68 {
  top: 346rem;
  left: 96.25rem;
}
.condition3LineLeadingvid66,
.condition3LineLeadingvid67,
.condition3LineLeadingvid68 {
  left: 72.5rem;
}
.condition3LineLeadingvid67,
.condition3LineLeadingvid68 {
  left: 48.75rem;
}
.condition3LineLeadingvid68 {
  left: 25rem;
}
.condition3LineLeadingvid69,
.condition3LineLeadingvid70,
.condition3LineLeadingvid71,
.condition3LineLeadingvid72,
.condition3LineLeadingvid73,
.condition3LineLeadingvid74 {
  position: absolute;
  top: 346rem;
  left: 1.25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingvid70,
.condition3LineLeadingvid71,
.condition3LineLeadingvid72,
.condition3LineLeadingvid73,
.condition3LineLeadingvid74 {
  top: 339.25rem;
  left: 96.25rem;
}
.condition3LineLeadingvid71,
.condition3LineLeadingvid72,
.condition3LineLeadingvid73,
.condition3LineLeadingvid74 {
  left: 72.5rem;
}
.condition3LineLeadingvid72,
.condition3LineLeadingvid73,
.condition3LineLeadingvid74 {
  left: 48.75rem;
}
.condition3LineLeadingvid73,
.condition3LineLeadingvid74 {
  left: 25rem;
}
.condition3LineLeadingvid74 {
  left: 1.25rem;
}
.condition2LineLeadingimag20,
.condition2LineLeadingimag21,
.condition2LineLeadingimag22,
.condition2LineLeadingimag23,
.condition2LineLeadingimag24 {
  position: absolute;
  top: 324.5rem;
  left: 96.25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingimag21,
.condition2LineLeadingimag22,
.condition2LineLeadingimag23,
.condition2LineLeadingimag24 {
  left: 72.5rem;
}
.condition2LineLeadingimag22,
.condition2LineLeadingimag23,
.condition2LineLeadingimag24 {
  left: 48.75rem;
}
.condition2LineLeadingimag23,
.condition2LineLeadingimag24 {
  left: 25rem;
}
.condition2LineLeadingimag24 {
  left: 1.25rem;
}
.condition3LineLeadingima60,
.condition3LineLeadingima61,
.condition3LineLeadingima62,
.condition3LineLeadingima63 {
  position: absolute;
  top: 316.13rem;
  left: 96.25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingima61,
.condition3LineLeadingima62,
.condition3LineLeadingima63 {
  left: 48.75rem;
}
.condition3LineLeadingima62,
.condition3LineLeadingima63 {
  left: 25rem;
}
.condition3LineLeadingima63 {
  left: 1.25rem;
}
.condition3LineLeadingima64,
.condition3LineLeadingima65,
.condition3LineLeadingima66,
.condition3LineLeadingima67,
.condition3LineLeadingima68,
.condition3LineLeadingima69 {
  position: absolute;
  top: 315.5rem;
  left: 72.5rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingima65,
.condition3LineLeadingima66,
.condition3LineLeadingima67,
.condition3LineLeadingima68,
.condition3LineLeadingima69 {
  top: 308.75rem;
  left: 96.25rem;
}
.condition3LineLeadingima66,
.condition3LineLeadingima67,
.condition3LineLeadingima68,
.condition3LineLeadingima69 {
  left: 72.5rem;
}
.condition3LineLeadingima67,
.condition3LineLeadingima68,
.condition3LineLeadingima69 {
  left: 48.75rem;
}
.condition3LineLeadingima68,
.condition3LineLeadingima69 {
  left: 25rem;
}
.condition3LineLeadingima69 {
  left: 1.25rem;
}
.condition1LineLeadingicon20,
.condition3LineLeadingima70,
.condition3LineLeadingima71,
.condition3LineLeadingima72,
.condition3LineLeadingima73,
.condition3LineLeadingima74 {
  position: absolute;
  top: 302rem;
  left: 96.25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingicon20,
.condition3LineLeadingima71,
.condition3LineLeadingima72,
.condition3LineLeadingima73,
.condition3LineLeadingima74 {
  left: 72.5rem;
}
.condition1LineLeadingicon20,
.condition3LineLeadingima72,
.condition3LineLeadingima73,
.condition3LineLeadingima74 {
  left: 48.75rem;
}
.condition1LineLeadingicon20,
.condition3LineLeadingima73,
.condition3LineLeadingima74 {
  left: 25rem;
}
.condition1LineLeadingicon20,
.condition3LineLeadingima74 {
  left: 1.25rem;
}
.condition1LineLeadingicon20 {
  top: 292.25rem;
  left: 96.25rem;
  height: 3.5rem;
  justify-content: center;
}
.condition1LineLeadingicon21,
.condition1LineLeadingicon22,
.condition1LineLeadingicon23,
.condition1LineLeadingicon24,
.condition2LineLeadingicon30,
.condition2LineLeadingicon31,
.condition2LineLeadingicon32 {
  position: absolute;
  top: 292.25rem;
  left: 72.5rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingicon22,
.condition1LineLeadingicon23,
.condition1LineLeadingicon24,
.condition2LineLeadingicon30,
.condition2LineLeadingicon31,
.condition2LineLeadingicon32 {
  left: 48.75rem;
}
.condition1LineLeadingicon23,
.condition1LineLeadingicon24,
.condition2LineLeadingicon30,
.condition2LineLeadingicon31,
.condition2LineLeadingicon32 {
  left: 25rem;
}
.condition1LineLeadingicon24,
.condition2LineLeadingicon30,
.condition2LineLeadingicon31,
.condition2LineLeadingicon32 {
  left: 1.25rem;
}
.condition2LineLeadingicon30,
.condition2LineLeadingicon31,
.condition2LineLeadingicon32 {
  top: 286.5rem;
  left: 96.25rem;
  height: 4.5rem;
}
.condition2LineLeadingicon31,
.condition2LineLeadingicon32 {
  left: 72.5rem;
}
.condition2LineLeadingicon32 {
  left: 48.75rem;
}
.condition2LineLeadingicon33 {
  top: 286.5rem;
  left: 25rem;
  height: 4.5rem;
  justify-content: center;
}
.condition2LineLeadingicon33,
.condition2LineLeadingicon34,
.condition3LineLeadingico60,
.condition3LineLeadingico61 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingicon34 {
  top: 286.5rem;
  left: 1.25rem;
  height: 4.5rem;
  justify-content: center;
}
.condition3LineLeadingico60,
.condition3LineLeadingico61 {
  top: 278.75rem;
  left: 96.25rem;
  justify-content: flex-start;
}
.condition3LineLeadingico61 {
  left: 72.5rem;
}
.condition3LineLeadingico62,
.condition3LineLeadingico63,
.condition3LineLeadingico64,
.condition3LineLeadingico65,
.condition3LineLeadingico66,
.condition3LineLeadingico67,
.condition3LineLeadingico68 {
  position: absolute;
  top: 278.75rem;
  left: 48.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingico63,
.condition3LineLeadingico64,
.condition3LineLeadingico65,
.condition3LineLeadingico66,
.condition3LineLeadingico67,
.condition3LineLeadingico68 {
  left: 25rem;
}
.condition3LineLeadingico64,
.condition3LineLeadingico65,
.condition3LineLeadingico66,
.condition3LineLeadingico67,
.condition3LineLeadingico68 {
  left: 1.25rem;
}
.condition3LineLeadingico65,
.condition3LineLeadingico66,
.condition3LineLeadingico67,
.condition3LineLeadingico68 {
  top: 272rem;
  left: 96.25rem;
}
.condition3LineLeadingico66,
.condition3LineLeadingico67,
.condition3LineLeadingico68 {
  left: 72.5rem;
}
.condition3LineLeadingico67,
.condition3LineLeadingico68 {
  left: 48.75rem;
}
.condition3LineLeadingico68 {
  left: 25rem;
}
.condition3LineLeadingico69,
.condition3LineLeadingico70,
.condition3LineLeadingico71,
.condition3LineLeadingico72,
.condition3LineLeadingico73,
.condition3LineLeadingico74 {
  position: absolute;
  top: 272rem;
  left: 1.25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingico70,
.condition3LineLeadingico71,
.condition3LineLeadingico72,
.condition3LineLeadingico73,
.condition3LineLeadingico74 {
  top: 265.25rem;
  left: 96.25rem;
}
.condition3LineLeadingico71,
.condition3LineLeadingico72,
.condition3LineLeadingico73,
.condition3LineLeadingico74 {
  left: 72.5rem;
}
.condition3LineLeadingico72,
.condition3LineLeadingico73,
.condition3LineLeadingico74 {
  left: 48.75rem;
}
.condition3LineLeadingico73,
.condition3LineLeadingico74 {
  left: 25rem;
}
.condition3LineLeadingico74 {
  left: 1.25rem;
}
.condition1LineLeadingmono15,
.leadingElement685 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.leadingElement685 {
  overflow: hidden;
  align-items: flex-start;
  color: var(--m3-sys-dark-on-primary-container);
}
.condition1LineLeadingmono15 {
  position: absolute;
  top: 255.5rem;
  left: 96.25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 3.5rem;
  align-items: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition1LineLeadingmono16,
.condition1LineLeadingmono17,
.condition1LineLeadingmono18,
.condition1LineLeadingmono19,
.condition2LineLeadingmono30,
.condition2LineLeadingmono31,
.condition2LineLeadingmono32,
.condition2LineLeadingmono33,
.condition2LineLeadingmono34 {
  position: absolute;
  top: 255.5rem;
  left: 72.5rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition1LineLeadingmono17,
.condition1LineLeadingmono18,
.condition1LineLeadingmono19,
.condition2LineLeadingmono30,
.condition2LineLeadingmono31,
.condition2LineLeadingmono32,
.condition2LineLeadingmono33,
.condition2LineLeadingmono34 {
  left: 48.75rem;
}
.condition1LineLeadingmono18,
.condition1LineLeadingmono19,
.condition2LineLeadingmono30,
.condition2LineLeadingmono31,
.condition2LineLeadingmono32,
.condition2LineLeadingmono33,
.condition2LineLeadingmono34 {
  left: 25rem;
}
.condition1LineLeadingmono19,
.condition2LineLeadingmono30,
.condition2LineLeadingmono31,
.condition2LineLeadingmono32,
.condition2LineLeadingmono33,
.condition2LineLeadingmono34 {
  left: 1.25rem;
}
.condition2LineLeadingmono30,
.condition2LineLeadingmono31,
.condition2LineLeadingmono32,
.condition2LineLeadingmono33,
.condition2LineLeadingmono34 {
  top: 249.75rem;
  left: 96.25rem;
  height: 4.5rem;
}
.condition2LineLeadingmono31,
.condition2LineLeadingmono32,
.condition2LineLeadingmono33,
.condition2LineLeadingmono34 {
  left: 72.5rem;
}
.condition2LineLeadingmono32,
.condition2LineLeadingmono33,
.condition2LineLeadingmono34 {
  left: 48.75rem;
}
.condition2LineLeadingmono33,
.condition2LineLeadingmono34 {
  left: 25rem;
}
.condition2LineLeadingmono34 {
  left: 1.25rem;
}
.leadingElement695 {
  height: 4rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--m3-sys-dark-on-primary-container);
}
.condition3LineLeadingmon60,
.condition3LineLeadingmon61 {
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon60 {
  position: absolute;
  top: 242rem;
  left: 96.25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon61 {
  left: 72.5rem;
}
.condition3LineLeadingmon61,
.condition3LineLeadingmon62,
.condition3LineLeadingmon63 {
  position: absolute;
  top: 242rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon62 {
  left: 48.75rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon63 {
  left: 25rem;
}
.condition3LineLeadingmon63,
.condition3LineLeadingmon64,
.condition3LineLeadingmon65 {
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon64 {
  position: absolute;
  top: 242rem;
  left: 1.25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon65 {
  top: 235.25rem;
  left: 96.25rem;
}
.condition3LineLeadingmon65,
.condition3LineLeadingmon66,
.condition3LineLeadingmon67 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon66 {
  top: 235.25rem;
  left: 72.5rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon67 {
  left: 48.75rem;
}
.condition3LineLeadingmon67,
.condition3LineLeadingmon68,
.condition3LineLeadingmon69 {
  top: 235.25rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon68 {
  position: absolute;
  left: 25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon69 {
  left: 1.25rem;
}
.condition3LineLeadingmon69,
.condition3LineLeadingmon70,
.condition3LineLeadingmon71 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon70 {
  top: 228.5rem;
  left: 96.25rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon71 {
  left: 72.5rem;
}
.condition3LineLeadingmon71,
.condition3LineLeadingmon72,
.condition3LineLeadingmon73 {
  top: 228.5rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon72 {
  position: absolute;
  left: 48.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon73 {
  left: 25rem;
}
.condition1LineLeadingnone20,
.condition1LineLeadingnone21,
.condition1LineLeadingnone22,
.condition3LineLeadingmon73,
.condition3LineLeadingmon74 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon74 {
  top: 228.5rem;
  left: 1.25rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition1LineLeadingnone20,
.condition1LineLeadingnone21,
.condition1LineLeadingnone22 {
  top: 218.75rem;
  left: 96.25rem;
  height: 3.5rem;
  justify-content: center;
}
.condition1LineLeadingnone21,
.condition1LineLeadingnone22 {
  left: 72.5rem;
}
.condition1LineLeadingnone22 {
  left: 48.75rem;
}
.condition1LineLeadingnone23,
.condition1LineLeadingnone24,
.condition2LineLeadingnone30,
.condition2LineLeadingnone31,
.condition2LineLeadingnone32,
.condition2LineLeadingnone33,
.condition2LineLeadingnone34 {
  position: absolute;
  top: 218.75rem;
  left: 25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingnone24,
.condition2LineLeadingnone30,
.condition2LineLeadingnone31,
.condition2LineLeadingnone32,
.condition2LineLeadingnone33,
.condition2LineLeadingnone34 {
  left: 1.25rem;
}
.condition2LineLeadingnone30,
.condition2LineLeadingnone31,
.condition2LineLeadingnone32,
.condition2LineLeadingnone33,
.condition2LineLeadingnone34 {
  top: 213rem;
  left: 96.25rem;
  height: 4.5rem;
}
.condition2LineLeadingnone31,
.condition2LineLeadingnone32,
.condition2LineLeadingnone33,
.condition2LineLeadingnone34 {
  left: 72.5rem;
}
.condition2LineLeadingnone32,
.condition2LineLeadingnone33,
.condition2LineLeadingnone34 {
  left: 48.75rem;
}
.condition2LineLeadingnone33,
.condition2LineLeadingnone34 {
  left: 25rem;
}
.condition2LineLeadingnone34 {
  left: 1.25rem;
}
.condition3LineLeadingnon60,
.condition3LineLeadingnon61,
.condition3LineLeadingnon62,
.condition3LineLeadingnon63 {
  position: absolute;
  top: 205.25rem;
  left: 96.25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingnon61,
.condition3LineLeadingnon62,
.condition3LineLeadingnon63 {
  left: 72.5rem;
}
.condition3LineLeadingnon62,
.condition3LineLeadingnon63 {
  left: 48.75rem;
}
.condition3LineLeadingnon63 {
  left: 25rem;
}
.minHeightFix12 {
  position: relative;
  background-color: var(--m3-sys-dark-surface);
  width: 0.5rem;
  height: 4rem;
  opacity: 0;
}
.condition3LineLeadingnon64,
.condition3LineLeadingnon65 {
  position: absolute;
  top: 205.25rem;
  left: 1.25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingnon65 {
  top: 198.5rem;
  left: 96.25rem;
}
.condition3LineLeadingnon66,
.condition3LineLeadingnon67,
.condition3LineLeadingnon68,
.condition3LineLeadingnon69,
.condition3LineLeadingnon70,
.condition3LineLeadingnon71,
.condition3LineLeadingnon72 {
  position: absolute;
  top: 198.5rem;
  left: 72.5rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingnon67,
.condition3LineLeadingnon68,
.condition3LineLeadingnon69,
.condition3LineLeadingnon70,
.condition3LineLeadingnon71,
.condition3LineLeadingnon72 {
  left: 48.75rem;
}
.condition3LineLeadingnon68,
.condition3LineLeadingnon69,
.condition3LineLeadingnon70,
.condition3LineLeadingnon71,
.condition3LineLeadingnon72 {
  left: 25rem;
}
.condition3LineLeadingnon69,
.condition3LineLeadingnon70,
.condition3LineLeadingnon71,
.condition3LineLeadingnon72 {
  left: 1.25rem;
}
.condition3LineLeadingnon70,
.condition3LineLeadingnon71,
.condition3LineLeadingnon72 {
  top: 191.75rem;
  left: 96.25rem;
}
.condition3LineLeadingnon71,
.condition3LineLeadingnon72 {
  left: 72.5rem;
}
.condition3LineLeadingnon72 {
  left: 48.75rem;
}
.condition1LineLeadingswit10,
.condition1LineLeadingswit11,
.condition2LineLeadingswit14,
.condition2LineLeadingswit15,
.condition3LineLeadingnon73,
.condition3LineLeadingnon74 {
  position: absolute;
  top: 191.75rem;
  left: 25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingswit10,
.condition1LineLeadingswit11,
.condition2LineLeadingswit14,
.condition2LineLeadingswit15,
.condition3LineLeadingnon74 {
  left: 1.25rem;
}
.condition1LineLeadingswit10,
.condition1LineLeadingswit11,
.condition2LineLeadingswit14,
.condition2LineLeadingswit15 {
  top: 175.75rem;
  left: 24.75rem;
  height: 3.5rem;
  justify-content: center;
}
.condition1LineLeadingswit11,
.condition2LineLeadingswit14,
.condition2LineLeadingswit15 {
  left: 1rem;
}
.condition2LineLeadingswit14,
.condition2LineLeadingswit15 {
  top: 170rem;
  left: 24.75rem;
  height: 4.5rem;
}
.condition2LineLeadingswit15 {
  left: 1rem;
}
.condition1LineLeadingradi10,
.condition1LineLeadingradi11,
.condition2LineLeadingradi14,
.condition2LineLeadingradi15,
.condition3LineLeadingswi30,
.condition3LineLeadingswi31 {
  position: absolute;
  top: 163.25rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingradi10,
.condition1LineLeadingradi11,
.condition2LineLeadingradi14,
.condition2LineLeadingradi15,
.condition3LineLeadingswi31 {
  left: 1rem;
}
.condition1LineLeadingradi10,
.condition1LineLeadingradi11,
.condition2LineLeadingradi14,
.condition2LineLeadingradi15 {
  top: 153.5rem;
  left: 24.75rem;
  height: 3.5rem;
  justify-content: center;
}
.condition1LineLeadingradi11,
.condition2LineLeadingradi14,
.condition2LineLeadingradi15 {
  left: 1rem;
}
.condition2LineLeadingradi14,
.condition2LineLeadingradi15 {
  top: 147.75rem;
  left: 24.75rem;
  height: 4.5rem;
}
.condition2LineLeadingradi15 {
  left: 1rem;
}
.condition1LineLeadingchec10,
.condition1LineLeadingchec11,
.condition2LineLeadingchec14,
.condition2LineLeadingchec15,
.condition3LineLeadingrad30,
.condition3LineLeadingrad31 {
  position: absolute;
  top: 141rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingchec10,
.condition1LineLeadingchec11,
.condition2LineLeadingchec14,
.condition2LineLeadingchec15,
.condition3LineLeadingrad31 {
  left: 1rem;
}
.condition1LineLeadingchec10,
.condition1LineLeadingchec11,
.condition2LineLeadingchec14,
.condition2LineLeadingchec15 {
  top: 131.25rem;
  left: 24.75rem;
  height: 3.5rem;
  justify-content: center;
}
.condition1LineLeadingchec11,
.condition2LineLeadingchec14,
.condition2LineLeadingchec15 {
  left: 1rem;
}
.condition2LineLeadingchec14,
.condition2LineLeadingchec15 {
  top: 125.5rem;
  left: 24.75rem;
  height: 4.5rem;
}
.condition2LineLeadingchec15 {
  left: 1rem;
}
.condition1LineLeadingvide15,
.condition1LineLeadingvide16,
.condition1LineLeadingvide17,
.condition3LineLeadingche30,
.condition3LineLeadingche31 {
  position: absolute;
  top: 118.75rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingvide15,
.condition1LineLeadingvide16,
.condition1LineLeadingvide17,
.condition3LineLeadingche31 {
  left: 1rem;
}
.condition1LineLeadingvide15,
.condition1LineLeadingvide16,
.condition1LineLeadingvide17 {
  top: 107rem;
  left: 96rem;
  height: 5.5rem;
  justify-content: center;
}
.condition1LineLeadingvide16,
.condition1LineLeadingvide17 {
  left: 72.25rem;
}
.condition1LineLeadingvide17 {
  left: 48.5rem;
}
.condition1LineLeadingvide18,
.condition1LineLeadingvide19,
.condition2LineLeadingvide35,
.condition2LineLeadingvide36,
.condition2LineLeadingvide37,
.condition2LineLeadingvide38,
.condition2LineLeadingvide39 {
  position: absolute;
  top: 107rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 5.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingvide19,
.condition2LineLeadingvide35,
.condition2LineLeadingvide36,
.condition2LineLeadingvide37,
.condition2LineLeadingvide38,
.condition2LineLeadingvide39 {
  left: 1rem;
}
.condition2LineLeadingvide35,
.condition2LineLeadingvide36,
.condition2LineLeadingvide37,
.condition2LineLeadingvide38,
.condition2LineLeadingvide39 {
  top: 100.25rem;
  left: 96rem;
}
.condition2LineLeadingvide36,
.condition2LineLeadingvide37,
.condition2LineLeadingvide38,
.condition2LineLeadingvide39 {
  left: 72.25rem;
}
.condition2LineLeadingvide37,
.condition2LineLeadingvide38,
.condition2LineLeadingvide39 {
  left: 48.5rem;
}
.condition2LineLeadingvide38,
.condition2LineLeadingvide39 {
  left: 24.75rem;
}
.condition2LineLeadingvide39 {
  left: 1rem;
}
.condition1LineLeadingimag10,
.condition3LineLeadingvid75,
.condition3LineLeadingvid76,
.condition3LineLeadingvid77,
.condition3LineLeadingvid78,
.condition3LineLeadingvid79 {
  position: absolute;
  top: 92.25rem;
  left: 96rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingimag10,
.condition3LineLeadingvid76,
.condition3LineLeadingvid77,
.condition3LineLeadingvid78,
.condition3LineLeadingvid79 {
  left: 72.25rem;
}
.condition1LineLeadingimag10,
.condition3LineLeadingvid77,
.condition3LineLeadingvid78,
.condition3LineLeadingvid79 {
  left: 48.5rem;
}
.condition1LineLeadingimag10,
.condition3LineLeadingvid78,
.condition3LineLeadingvid79 {
  left: 24.75rem;
}
.condition1LineLeadingimag10,
.condition3LineLeadingvid79 {
  left: 1rem;
}
.condition1LineLeadingimag10 {
  top: 81.5rem;
  left: 96rem;
  height: 4.5rem;
  justify-content: center;
}
.condition1LineLeadingimag11,
.condition1LineLeadingimag12,
.condition1LineLeadingimag13,
.condition1LineLeadingimag14,
.condition2LineLeadingimag25,
.condition2LineLeadingimag26,
.condition2LineLeadingimag27 {
  position: absolute;
  top: 81.5rem;
  left: 72.25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingimag12,
.condition1LineLeadingimag13,
.condition1LineLeadingimag14,
.condition2LineLeadingimag25,
.condition2LineLeadingimag26,
.condition2LineLeadingimag27 {
  left: 48.5rem;
}
.condition1LineLeadingimag13,
.condition1LineLeadingimag14,
.condition2LineLeadingimag25,
.condition2LineLeadingimag26,
.condition2LineLeadingimag27 {
  left: 24.75rem;
}
.condition1LineLeadingimag14,
.condition2LineLeadingimag25,
.condition2LineLeadingimag26,
.condition2LineLeadingimag27 {
  left: 1rem;
}
.condition2LineLeadingimag25,
.condition2LineLeadingimag26,
.condition2LineLeadingimag27 {
  top: 75.75rem;
  left: 96rem;
}
.condition2LineLeadingimag26,
.condition2LineLeadingimag27 {
  left: 72.25rem;
}
.condition2LineLeadingimag27 {
  left: 48.5rem;
}
.condition2LineLeadingimag28 {
  top: 75.75rem;
  left: 24.75rem;
  height: 4.5rem;
  justify-content: center;
}
.condition2LineLeadingimag28,
.condition2LineLeadingimag29,
.condition3LineLeadingima75,
.condition3LineLeadingima76 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingimag29 {
  top: 75.75rem;
  left: 1rem;
  height: 4.5rem;
  justify-content: center;
}
.condition3LineLeadingima75,
.condition3LineLeadingima76 {
  top: 67.75rem;
  left: 96rem;
  justify-content: flex-start;
}
.condition3LineLeadingima76 {
  left: 72.25rem;
}
.condition1LineLeadingicon25,
.condition1LineLeadingicon26,
.condition1LineLeadingicon27,
.condition3LineLeadingima77,
.condition3LineLeadingima78,
.condition3LineLeadingima79 {
  position: absolute;
  top: 67.75rem;
  left: 48.5rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingicon25,
.condition1LineLeadingicon26,
.condition1LineLeadingicon27,
.condition3LineLeadingima78,
.condition3LineLeadingima79 {
  left: 24.75rem;
}
.condition1LineLeadingicon25,
.condition1LineLeadingicon26,
.condition1LineLeadingicon27,
.condition3LineLeadingima79 {
  left: 1rem;
}
.condition1LineLeadingicon25,
.condition1LineLeadingicon26,
.condition1LineLeadingicon27 {
  top: 58rem;
  left: 96rem;
  height: 3.5rem;
  justify-content: center;
}
.condition1LineLeadingicon26,
.condition1LineLeadingicon27 {
  left: 72.25rem;
}
.condition1LineLeadingicon27 {
  left: 48.5rem;
}
.condition1LineLeadingicon28,
.condition1LineLeadingicon29,
.condition2LineLeadingicon35,
.condition2LineLeadingicon36,
.condition2LineLeadingicon37,
.condition2LineLeadingicon38,
.condition2LineLeadingicon39 {
  position: absolute;
  top: 58rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingicon29,
.condition2LineLeadingicon35,
.condition2LineLeadingicon36,
.condition2LineLeadingicon37,
.condition2LineLeadingicon38,
.condition2LineLeadingicon39 {
  left: 1rem;
}
.condition2LineLeadingicon35,
.condition2LineLeadingicon36,
.condition2LineLeadingicon37,
.condition2LineLeadingicon38,
.condition2LineLeadingicon39 {
  top: 52.25rem;
  left: 96rem;
  height: 4.5rem;
}
.condition2LineLeadingicon36,
.condition2LineLeadingicon37,
.condition2LineLeadingicon38,
.condition2LineLeadingicon39 {
  left: 72.25rem;
}
.condition2LineLeadingicon37,
.condition2LineLeadingicon38,
.condition2LineLeadingicon39 {
  left: 48.5rem;
}
.condition2LineLeadingicon38,
.condition2LineLeadingicon39 {
  left: 24.75rem;
}
.condition2LineLeadingicon39 {
  left: 1rem;
}
.condition3LineLeadingico75,
.condition3LineLeadingico76,
.condition3LineLeadingico77,
.condition3LineLeadingico78,
.condition3LineLeadingico79 {
  position: absolute;
  top: 45.5rem;
  left: 96rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingico76,
.condition3LineLeadingico77,
.condition3LineLeadingico78,
.condition3LineLeadingico79 {
  left: 72.25rem;
}
.condition3LineLeadingico77,
.condition3LineLeadingico78,
.condition3LineLeadingico79 {
  left: 48.5rem;
}
.condition3LineLeadingico78,
.condition3LineLeadingico79 {
  left: 24.75rem;
}
.condition3LineLeadingico79 {
  left: 1rem;
}
.condition1LineLeadingmono20,
.condition1LineLeadingmono21 {
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition1LineLeadingmono20 {
  position: absolute;
  top: 35.75rem;
  left: 96rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition1LineLeadingmono21 {
  left: 72.25rem;
}
.condition1LineLeadingmono21,
.condition1LineLeadingmono22,
.condition1LineLeadingmono23 {
  position: absolute;
  top: 35.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition1LineLeadingmono22 {
  left: 48.5rem;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition1LineLeadingmono23 {
  left: 24.75rem;
}
.condition1LineLeadingmono23,
.condition1LineLeadingmono24,
.condition2LineLeadingmono35 {
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition1LineLeadingmono24 {
  position: absolute;
  top: 35.75rem;
  left: 1rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingmono35 {
  top: 30rem;
  left: 96rem;
  height: 4.5rem;
}
.condition2LineLeadingmono35,
.condition2LineLeadingmono36,
.condition2LineLeadingmono37 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingmono36 {
  top: 30rem;
  left: 72.25rem;
  height: 4.5rem;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition2LineLeadingmono37 {
  left: 48.5rem;
}
.condition2LineLeadingmono37,
.condition2LineLeadingmono38,
.condition2LineLeadingmono39 {
  top: 30rem;
  height: 4.5rem;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition2LineLeadingmono38 {
  position: absolute;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingmono39 {
  left: 1rem;
}
.condition2LineLeadingmono39,
.condition3LineLeadingmon75,
.condition3LineLeadingmon76 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon75 {
  top: 23.25rem;
  left: 96rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon76 {
  left: 72.25rem;
}
.condition3LineLeadingmon76,
.condition3LineLeadingmon77,
.condition3LineLeadingmon78 {
  top: 23.25rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon77 {
  position: absolute;
  left: 48.5rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon78 {
  left: 24.75rem;
}
.condition1LineLeadingnone25,
.condition1LineLeadingnone26,
.condition1LineLeadingnone27,
.condition3LineLeadingmon78,
.condition3LineLeadingmon79 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon79 {
  top: 23.25rem;
  left: 1rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition1LineLeadingnone25,
.condition1LineLeadingnone26,
.condition1LineLeadingnone27 {
  top: 13.5rem;
  left: 96rem;
  height: 3.5rem;
  justify-content: center;
}
.condition1LineLeadingnone26,
.condition1LineLeadingnone27 {
  left: 72.25rem;
}
.condition1LineLeadingnone27 {
  left: 48.5rem;
}
.condition1LineLeadingnone28,
.condition1LineLeadingnone29,
.condition2LineLeadingnone35,
.condition2LineLeadingnone36,
.condition2LineLeadingnone37,
.condition2LineLeadingnone38,
.condition2LineLeadingnone39 {
  position: absolute;
  top: 13.5rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingnone29,
.condition2LineLeadingnone35,
.condition2LineLeadingnone36,
.condition2LineLeadingnone37,
.condition2LineLeadingnone38,
.condition2LineLeadingnone39 {
  left: 1rem;
}
.condition2LineLeadingnone35,
.condition2LineLeadingnone36,
.condition2LineLeadingnone37,
.condition2LineLeadingnone38,
.condition2LineLeadingnone39 {
  top: 7.75rem;
  left: 96rem;
  height: 4.5rem;
}
.condition2LineLeadingnone36,
.condition2LineLeadingnone37,
.condition2LineLeadingnone38,
.condition2LineLeadingnone39 {
  left: 72.25rem;
}
.condition2LineLeadingnone37,
.condition2LineLeadingnone38,
.condition2LineLeadingnone39 {
  left: 48.5rem;
}
.condition2LineLeadingnone38,
.condition2LineLeadingnone39 {
  left: 24.75rem;
}
.condition2LineLeadingnone39 {
  left: 1rem;
}
.condition3LineLeadingnon75,
.condition3LineLeadingnon76,
.condition3LineLeadingnon77,
.condition3LineLeadingnon78,
.condition3LineLeadingnon79 {
  position: absolute;
  top: 1rem;
  left: 96rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingnon76,
.condition3LineLeadingnon77,
.condition3LineLeadingnon78,
.condition3LineLeadingnon79 {
  left: 72.25rem;
}
.condition3LineLeadingnon77,
.condition3LineLeadingnon78,
.condition3LineLeadingnon79 {
  left: 48.5rem;
}
.condition3LineLeadingnon78,
.condition3LineLeadingnon79 {
  left: 24.75rem;
}
.condition3LineLeadingnon79 {
  left: 1rem;
}
.listItemlistItem0Density1 {
  position: relative;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-on-surface);
  width: 47.5rem;
  height: 181.38rem;
  overflow: hidden;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--m3-label-medium-size);
}
.trailingElement730 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  text-align: right;
  font-size: var(--m3-label-small-size);
  color: var(--m3-sys-dark-on-surface);
}
.condition2LineLeadingswit16 {
  position: absolute;
  top: 468.25rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.trailingSupportingText232 {
  position: relative;
  font-size: var(--m3-label-small-size);
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 500;
  color: var(--m3-sys-dark-on-surface);
  text-align: right;
  display: none;
}
.condition2LineLeadingswit17,
.condition3LineLeadingswi32,
.condition3LineLeadingswi33 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingswit17 {
  top: 468.25rem;
  left: 1rem;
  height: 4rem;
  justify-content: center;
}
.condition3LineLeadingswi32,
.condition3LineLeadingswi33 {
  top: 460.5rem;
  left: 24.75rem;
  justify-content: flex-start;
}
.condition3LineLeadingswi33 {
  left: 1rem;
}
.condition2LineLeadingradi16,
.condition2LineLeadingradi17,
.condition3LineLeadingswi34,
.condition3LineLeadingswi35,
.condition3LineLeadingswi36,
.condition3LineLeadingswi37 {
  position: absolute;
  top: 453.75rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition2LineLeadingradi16,
.condition2LineLeadingradi17,
.condition3LineLeadingswi35,
.condition3LineLeadingswi36,
.condition3LineLeadingswi37 {
  left: 1rem;
}
.condition2LineLeadingradi16,
.condition2LineLeadingradi17,
.condition3LineLeadingswi36,
.condition3LineLeadingswi37 {
  top: 447rem;
  left: 24.75rem;
}
.condition2LineLeadingradi16,
.condition2LineLeadingradi17,
.condition3LineLeadingswi37 {
  left: 1rem;
}
.condition2LineLeadingradi16,
.condition2LineLeadingradi17 {
  top: 431.5rem;
  left: 24.75rem;
  height: 4rem;
  justify-content: center;
}
.condition2LineLeadingradi17 {
  left: 1rem;
}
.condition3LineLeadingrad32,
.condition3LineLeadingrad33,
.condition3LineLeadingrad34,
.condition3LineLeadingrad35,
.condition3LineLeadingrad36,
.condition3LineLeadingrad37 {
  position: absolute;
  top: 423.75rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingrad33,
.condition3LineLeadingrad34,
.condition3LineLeadingrad35,
.condition3LineLeadingrad36,
.condition3LineLeadingrad37 {
  left: 1rem;
}
.condition3LineLeadingrad34,
.condition3LineLeadingrad35,
.condition3LineLeadingrad36,
.condition3LineLeadingrad37 {
  top: 417rem;
  left: 24.75rem;
}
.condition3LineLeadingrad35,
.condition3LineLeadingrad36,
.condition3LineLeadingrad37 {
  left: 1rem;
}
.condition3LineLeadingrad36,
.condition3LineLeadingrad37 {
  top: 410.25rem;
  left: 24.75rem;
}
.condition3LineLeadingrad37 {
  left: 1rem;
}
.condition2LineLeadingchec16 {
  top: 394.75rem;
  left: 24.75rem;
  height: 4rem;
  justify-content: center;
}
.condition2LineLeadingchec16,
.condition2LineLeadingchec17,
.condition3LineLeadingche32,
.condition3LineLeadingche33,
.condition3LineLeadingche34 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingchec17 {
  top: 394.75rem;
  left: 1rem;
  height: 4rem;
  justify-content: center;
}
.condition3LineLeadingche32,
.condition3LineLeadingche33,
.condition3LineLeadingche34 {
  top: 387rem;
  left: 24.75rem;
  justify-content: flex-start;
}
.condition3LineLeadingche33,
.condition3LineLeadingche34 {
  left: 1rem;
}
.condition3LineLeadingche34 {
  top: 380.25rem;
  left: 24.75rem;
}
.condition2LineLeadingvide40,
.condition2LineLeadingvide41,
.condition2LineLeadingvide42,
.condition3LineLeadingche35,
.condition3LineLeadingche36,
.condition3LineLeadingche37 {
  position: absolute;
  top: 380.25rem;
  left: 1rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition2LineLeadingvide40,
.condition2LineLeadingvide41,
.condition2LineLeadingvide42,
.condition3LineLeadingche36,
.condition3LineLeadingche37 {
  top: 373.5rem;
  left: 24.75rem;
}
.condition2LineLeadingvide40,
.condition2LineLeadingvide41,
.condition2LineLeadingvide42,
.condition3LineLeadingche37 {
  left: 1rem;
}
.condition2LineLeadingvide40,
.condition2LineLeadingvide41,
.condition2LineLeadingvide42 {
  top: 361.75rem;
  left: 96rem;
  height: 5rem;
  justify-content: center;
}
.condition2LineLeadingvide41,
.condition2LineLeadingvide42 {
  left: 72.25rem;
}
.condition2LineLeadingvide42 {
  left: 48.5rem;
}
.condition2LineLeadingvide43 {
  top: 361.75rem;
  left: 24.75rem;
  height: 5rem;
  justify-content: center;
}
.condition2LineLeadingvide43,
.condition2LineLeadingvide44,
.condition3LineLeadingvid80,
.condition3LineLeadingvid81 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingvide44 {
  top: 361.75rem;
  left: 1rem;
  height: 5rem;
  justify-content: center;
}
.condition3LineLeadingvid80,
.condition3LineLeadingvid81 {
  top: 353.38rem;
  left: 96rem;
  justify-content: flex-start;
}
.condition3LineLeadingvid81 {
  left: 48.5rem;
}
.condition3LineLeadingvid82,
.condition3LineLeadingvid83,
.condition3LineLeadingvid84,
.condition3LineLeadingvid85,
.condition3LineLeadingvid86,
.condition3LineLeadingvid87,
.condition3LineLeadingvid88 {
  position: absolute;
  top: 353.38rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingvid83,
.condition3LineLeadingvid84,
.condition3LineLeadingvid85,
.condition3LineLeadingvid86,
.condition3LineLeadingvid87,
.condition3LineLeadingvid88 {
  left: 1rem;
}
.condition3LineLeadingvid84,
.condition3LineLeadingvid85,
.condition3LineLeadingvid86,
.condition3LineLeadingvid87,
.condition3LineLeadingvid88 {
  top: 352.75rem;
  left: 72.25rem;
}
.condition3LineLeadingvid85,
.condition3LineLeadingvid86,
.condition3LineLeadingvid87,
.condition3LineLeadingvid88 {
  top: 346rem;
  left: 96rem;
}
.condition3LineLeadingvid86,
.condition3LineLeadingvid87,
.condition3LineLeadingvid88 {
  left: 72.25rem;
}
.condition3LineLeadingvid87,
.condition3LineLeadingvid88 {
  left: 48.5rem;
}
.condition3LineLeadingvid88 {
  left: 24.75rem;
}
.condition3LineLeadingvid89,
.condition3LineLeadingvid90,
.condition3LineLeadingvid91,
.condition3LineLeadingvid92,
.condition3LineLeadingvid93,
.condition3LineLeadingvid94 {
  position: absolute;
  top: 346rem;
  left: 1rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingvid90,
.condition3LineLeadingvid91,
.condition3LineLeadingvid92,
.condition3LineLeadingvid93,
.condition3LineLeadingvid94 {
  top: 339.25rem;
  left: 96rem;
}
.condition3LineLeadingvid91,
.condition3LineLeadingvid92,
.condition3LineLeadingvid93,
.condition3LineLeadingvid94 {
  left: 72.25rem;
}
.condition3LineLeadingvid92,
.condition3LineLeadingvid93,
.condition3LineLeadingvid94 {
  left: 48.5rem;
}
.condition3LineLeadingvid93,
.condition3LineLeadingvid94 {
  left: 24.75rem;
}
.condition3LineLeadingvid94 {
  left: 1rem;
}
.condition2LineLeadingimag30,
.condition2LineLeadingimag31,
.condition2LineLeadingimag32,
.condition2LineLeadingimag33,
.condition2LineLeadingimag34 {
  position: absolute;
  top: 324.5rem;
  left: 96rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingimag31,
.condition2LineLeadingimag32,
.condition2LineLeadingimag33,
.condition2LineLeadingimag34 {
  left: 72.25rem;
}
.condition2LineLeadingimag32,
.condition2LineLeadingimag33,
.condition2LineLeadingimag34 {
  left: 48.5rem;
}
.condition2LineLeadingimag33,
.condition2LineLeadingimag34 {
  left: 24.75rem;
}
.condition2LineLeadingimag34 {
  left: 1rem;
}
.condition3LineLeadingima80,
.condition3LineLeadingima81,
.condition3LineLeadingima82,
.condition3LineLeadingima83,
.condition3LineLeadingima84 {
  position: absolute;
  top: 316.13rem;
  left: 96rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingima81,
.condition3LineLeadingima82,
.condition3LineLeadingima83,
.condition3LineLeadingima84 {
  left: 48.5rem;
}
.condition3LineLeadingima82,
.condition3LineLeadingima83,
.condition3LineLeadingima84 {
  left: 24.75rem;
}
.condition3LineLeadingima83,
.condition3LineLeadingima84 {
  left: 1rem;
}
.condition3LineLeadingima84 {
  top: 315.5rem;
  left: 72.25rem;
}
.condition3LineLeadingima85,
.condition3LineLeadingima86,
.condition3LineLeadingima87,
.condition3LineLeadingima88,
.condition3LineLeadingima89,
.condition3LineLeadingima90,
.condition3LineLeadingima91 {
  position: absolute;
  top: 308.75rem;
  left: 96rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingima86,
.condition3LineLeadingima87,
.condition3LineLeadingima88,
.condition3LineLeadingima89,
.condition3LineLeadingima90,
.condition3LineLeadingima91 {
  left: 72.25rem;
}
.condition3LineLeadingima87,
.condition3LineLeadingima88,
.condition3LineLeadingima89,
.condition3LineLeadingima90,
.condition3LineLeadingima91 {
  left: 48.5rem;
}
.condition3LineLeadingima88,
.condition3LineLeadingima89,
.condition3LineLeadingima90,
.condition3LineLeadingima91 {
  left: 24.75rem;
}
.condition3LineLeadingima89,
.condition3LineLeadingima90,
.condition3LineLeadingima91 {
  left: 1rem;
}
.condition3LineLeadingima90,
.condition3LineLeadingima91 {
  top: 302rem;
  left: 96rem;
}
.condition3LineLeadingima91 {
  left: 72.25rem;
}
.condition2LineLeadingicon40,
.condition2LineLeadingicon41,
.condition2LineLeadingicon42,
.condition3LineLeadingima92,
.condition3LineLeadingima93,
.condition3LineLeadingima94 {
  position: absolute;
  top: 302rem;
  left: 48.5rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition2LineLeadingicon40,
.condition2LineLeadingicon41,
.condition2LineLeadingicon42,
.condition3LineLeadingima93,
.condition3LineLeadingima94 {
  left: 24.75rem;
}
.condition2LineLeadingicon40,
.condition2LineLeadingicon41,
.condition2LineLeadingicon42,
.condition3LineLeadingima94 {
  left: 1rem;
}
.condition2LineLeadingicon40,
.condition2LineLeadingicon41,
.condition2LineLeadingicon42 {
  top: 286.5rem;
  left: 96rem;
  height: 4rem;
  justify-content: center;
}
.condition2LineLeadingicon41,
.condition2LineLeadingicon42 {
  left: 72.25rem;
}
.condition2LineLeadingicon42 {
  left: 48.5rem;
}
.condition2LineLeadingicon43 {
  top: 286.5rem;
  left: 24.75rem;
  height: 4rem;
  justify-content: center;
}
.condition2LineLeadingicon43,
.condition2LineLeadingicon44,
.condition3LineLeadingico80,
.condition3LineLeadingico81 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingicon44 {
  top: 286.5rem;
  left: 1rem;
  height: 4rem;
  justify-content: center;
}
.condition3LineLeadingico80,
.condition3LineLeadingico81 {
  top: 278.75rem;
  left: 96rem;
  justify-content: flex-start;
}
.condition3LineLeadingico81 {
  left: 72.25rem;
}
.condition3LineLeadingico82,
.condition3LineLeadingico83,
.condition3LineLeadingico84,
.condition3LineLeadingico85,
.condition3LineLeadingico86,
.condition3LineLeadingico87,
.condition3LineLeadingico88 {
  position: absolute;
  top: 278.75rem;
  left: 48.5rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingico83,
.condition3LineLeadingico84,
.condition3LineLeadingico85,
.condition3LineLeadingico86,
.condition3LineLeadingico87,
.condition3LineLeadingico88 {
  left: 24.75rem;
}
.condition3LineLeadingico84,
.condition3LineLeadingico85,
.condition3LineLeadingico86,
.condition3LineLeadingico87,
.condition3LineLeadingico88 {
  left: 1rem;
}
.condition3LineLeadingico85,
.condition3LineLeadingico86,
.condition3LineLeadingico87,
.condition3LineLeadingico88 {
  top: 272rem;
  left: 96rem;
}
.condition3LineLeadingico86,
.condition3LineLeadingico87,
.condition3LineLeadingico88 {
  left: 72.25rem;
}
.condition3LineLeadingico87,
.condition3LineLeadingico88 {
  left: 48.5rem;
}
.condition3LineLeadingico88 {
  left: 24.75rem;
}
.condition3LineLeadingico89,
.condition3LineLeadingico90,
.condition3LineLeadingico91,
.condition3LineLeadingico92,
.condition3LineLeadingico93,
.condition3LineLeadingico94 {
  position: absolute;
  top: 272rem;
  left: 1rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingico90,
.condition3LineLeadingico91,
.condition3LineLeadingico92,
.condition3LineLeadingico93,
.condition3LineLeadingico94 {
  top: 265.25rem;
  left: 96rem;
}
.condition3LineLeadingico91,
.condition3LineLeadingico92,
.condition3LineLeadingico93,
.condition3LineLeadingico94 {
  left: 72.25rem;
}
.condition3LineLeadingico92,
.condition3LineLeadingico93,
.condition3LineLeadingico94 {
  left: 48.5rem;
}
.condition3LineLeadingico93,
.condition3LineLeadingico94 {
  left: 24.75rem;
}
.condition3LineLeadingico94 {
  left: 1rem;
}
.content1046 {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-dark-on-surface-variant);
}
.condition2LineLeadingmono40,
.condition2LineLeadingmono41,
.condition2LineLeadingmono42,
.condition2LineLeadingmono43,
.condition2LineLeadingmono44 {
  position: absolute;
  top: 249.75rem;
  left: 96rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-dark-on-primary-container);
}
.condition2LineLeadingmono41,
.condition2LineLeadingmono42,
.condition2LineLeadingmono43,
.condition2LineLeadingmono44 {
  left: 72.25rem;
}
.condition2LineLeadingmono42,
.condition2LineLeadingmono43,
.condition2LineLeadingmono44 {
  left: 48.5rem;
}
.condition2LineLeadingmono43,
.condition2LineLeadingmono44 {
  left: 24.75rem;
}
.condition2LineLeadingmono44 {
  left: 1rem;
}
.content1051 {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-dark-on-surface-variant);
}
.condition3LineLeadingmon80,
.condition3LineLeadingmon81,
.condition3LineLeadingmon82,
.condition3LineLeadingmon83,
.condition3LineLeadingmon84 {
  position: absolute;
  top: 242rem;
  left: 96rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-dark-on-primary-container);
}
.condition3LineLeadingmon81,
.condition3LineLeadingmon82,
.condition3LineLeadingmon83,
.condition3LineLeadingmon84 {
  left: 72.25rem;
}
.condition3LineLeadingmon82,
.condition3LineLeadingmon83,
.condition3LineLeadingmon84 {
  left: 48.5rem;
}
.condition3LineLeadingmon83,
.condition3LineLeadingmon84 {
  left: 24.75rem;
}
.condition3LineLeadingmon84 {
  left: 1rem;
}
.condition3LineLeadingmon85,
.condition3LineLeadingmon86,
.condition3LineLeadingmon87,
.condition3LineLeadingmon88,
.condition3LineLeadingmon89,
.condition3LineLeadingmon90,
.condition3LineLeadingmon91,
.condition3LineLeadingmon92,
.condition3LineLeadingmon93,
.condition3LineLeadingmon94 {
  position: absolute;
  top: 235.25rem;
  left: 96rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-dark-on-primary-container);
}
.condition3LineLeadingmon86,
.condition3LineLeadingmon87,
.condition3LineLeadingmon88,
.condition3LineLeadingmon89,
.condition3LineLeadingmon90,
.condition3LineLeadingmon91,
.condition3LineLeadingmon92,
.condition3LineLeadingmon93,
.condition3LineLeadingmon94 {
  left: 72.25rem;
}
.condition3LineLeadingmon87,
.condition3LineLeadingmon88,
.condition3LineLeadingmon89,
.condition3LineLeadingmon90,
.condition3LineLeadingmon91,
.condition3LineLeadingmon92,
.condition3LineLeadingmon93,
.condition3LineLeadingmon94 {
  left: 48.5rem;
}
.condition3LineLeadingmon88,
.condition3LineLeadingmon89,
.condition3LineLeadingmon90,
.condition3LineLeadingmon91,
.condition3LineLeadingmon92,
.condition3LineLeadingmon93,
.condition3LineLeadingmon94 {
  left: 24.75rem;
}
.condition3LineLeadingmon89,
.condition3LineLeadingmon90,
.condition3LineLeadingmon91,
.condition3LineLeadingmon92,
.condition3LineLeadingmon93,
.condition3LineLeadingmon94 {
  left: 1rem;
}
.condition3LineLeadingmon90,
.condition3LineLeadingmon91,
.condition3LineLeadingmon92,
.condition3LineLeadingmon93,
.condition3LineLeadingmon94 {
  top: 228.5rem;
  left: 96rem;
}
.condition3LineLeadingmon91,
.condition3LineLeadingmon92,
.condition3LineLeadingmon93,
.condition3LineLeadingmon94 {
  left: 72.25rem;
}
.condition3LineLeadingmon92,
.condition3LineLeadingmon93,
.condition3LineLeadingmon94 {
  left: 48.5rem;
}
.condition3LineLeadingmon93,
.condition3LineLeadingmon94 {
  left: 24.75rem;
}
.condition3LineLeadingmon94 {
  left: 1rem;
}
.condition2LineLeadingnone40,
.condition2LineLeadingnone41,
.condition2LineLeadingnone42,
.condition2LineLeadingnone43,
.condition2LineLeadingnone44 {
  position: absolute;
  top: 213rem;
  left: 96rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition2LineLeadingnone41,
.condition2LineLeadingnone42,
.condition2LineLeadingnone43,
.condition2LineLeadingnone44 {
  left: 72.25rem;
}
.condition2LineLeadingnone42,
.condition2LineLeadingnone43,
.condition2LineLeadingnone44 {
  left: 48.5rem;
}
.condition2LineLeadingnone43,
.condition2LineLeadingnone44 {
  left: 24.75rem;
}
.condition2LineLeadingnone44 {
  left: 1rem;
}
.trailingElement810 {
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  text-align: right;
  font-size: var(--m3-label-small-size);
  color: var(--m3-sys-dark-on-surface);
}
.condition3LineLeadingnon80,
.condition3LineLeadingnon81,
.condition3LineLeadingnon82 {
  position: absolute;
  top: 205.25rem;
  left: 96rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingnon81,
.condition3LineLeadingnon82 {
  left: 72.25rem;
}
.condition3LineLeadingnon82 {
  left: 48.5rem;
}
.condition3LineLeadingnon83,
.condition3LineLeadingnon84,
.condition3LineLeadingnon85,
.condition3LineLeadingnon86,
.condition3LineLeadingnon87,
.condition3LineLeadingnon88,
.condition3LineLeadingnon89 {
  position: absolute;
  top: 205.25rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingnon84,
.condition3LineLeadingnon85,
.condition3LineLeadingnon86,
.condition3LineLeadingnon87,
.condition3LineLeadingnon88,
.condition3LineLeadingnon89 {
  left: 1rem;
}
.condition3LineLeadingnon85,
.condition3LineLeadingnon86,
.condition3LineLeadingnon87,
.condition3LineLeadingnon88,
.condition3LineLeadingnon89 {
  top: 198.5rem;
  left: 96rem;
}
.condition3LineLeadingnon86,
.condition3LineLeadingnon87,
.condition3LineLeadingnon88,
.condition3LineLeadingnon89 {
  left: 72.25rem;
}
.condition3LineLeadingnon87,
.condition3LineLeadingnon88,
.condition3LineLeadingnon89 {
  left: 48.5rem;
}
.condition3LineLeadingnon88,
.condition3LineLeadingnon89 {
  left: 24.75rem;
}
.condition3LineLeadingnon89 {
  left: 1rem;
}
.condition1LineLeadingswit12,
.condition1LineLeadingswit13,
.condition3LineLeadingnon90,
.condition3LineLeadingnon91,
.condition3LineLeadingnon92,
.condition3LineLeadingnon93,
.condition3LineLeadingnon94 {
  position: absolute;
  top: 191.75rem;
  left: 96rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingswit12,
.condition1LineLeadingswit13,
.condition3LineLeadingnon91,
.condition3LineLeadingnon92,
.condition3LineLeadingnon93,
.condition3LineLeadingnon94 {
  left: 72.25rem;
}
.condition1LineLeadingswit12,
.condition1LineLeadingswit13,
.condition3LineLeadingnon92,
.condition3LineLeadingnon93,
.condition3LineLeadingnon94 {
  left: 48.5rem;
}
.condition1LineLeadingswit12,
.condition1LineLeadingswit13,
.condition3LineLeadingnon93,
.condition3LineLeadingnon94 {
  left: 24.75rem;
}
.condition1LineLeadingswit12,
.condition1LineLeadingswit13,
.condition3LineLeadingnon94 {
  left: 1rem;
}
.condition1LineLeadingswit12,
.condition1LineLeadingswit13 {
  top: 175.75rem;
  left: 24.75rem;
  height: 3rem;
  justify-content: center;
}
.condition1LineLeadingswit13 {
  left: 1rem;
}
.condition1LineLeadingradi12,
.condition1LineLeadingradi13,
.condition2LineLeadingradi18,
.condition2LineLeadingradi19,
.condition2LineLeadingswit18,
.condition2LineLeadingswit19 {
  position: absolute;
  top: 170rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingradi12,
.condition1LineLeadingradi13,
.condition2LineLeadingradi18,
.condition2LineLeadingradi19,
.condition2LineLeadingswit19 {
  left: 1rem;
}
.condition1LineLeadingradi12,
.condition1LineLeadingradi13,
.condition2LineLeadingradi18,
.condition2LineLeadingradi19 {
  top: 153.5rem;
  left: 24.75rem;
  height: 3rem;
}
.condition1LineLeadingradi13,
.condition2LineLeadingradi18,
.condition2LineLeadingradi19 {
  left: 1rem;
}
.condition2LineLeadingradi18,
.condition2LineLeadingradi19 {
  top: 147.75rem;
  left: 24.75rem;
  height: 4rem;
}
.condition2LineLeadingradi19 {
  left: 1rem;
}
.condition1LineLeadingchec12,
.condition1LineLeadingchec13,
.condition1LineLeadingvide20,
.condition1LineLeadingvide21,
.condition1LineLeadingvide22,
.condition2LineLeadingchec18,
.condition2LineLeadingchec19 {
  position: absolute;
  top: 131.25rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingchec13,
.condition1LineLeadingvide20,
.condition1LineLeadingvide21,
.condition1LineLeadingvide22,
.condition2LineLeadingchec18,
.condition2LineLeadingchec19 {
  left: 1rem;
}
.condition1LineLeadingvide20,
.condition1LineLeadingvide21,
.condition1LineLeadingvide22,
.condition2LineLeadingchec18,
.condition2LineLeadingchec19 {
  top: 125.5rem;
  left: 24.75rem;
  height: 4rem;
}
.condition1LineLeadingvide20,
.condition1LineLeadingvide21,
.condition1LineLeadingvide22,
.condition2LineLeadingchec19 {
  left: 1rem;
}
.condition1LineLeadingvide20,
.condition1LineLeadingvide21,
.condition1LineLeadingvide22 {
  top: 107rem;
  left: 96rem;
  height: 5rem;
}
.condition1LineLeadingvide21,
.condition1LineLeadingvide22 {
  left: 72.25rem;
}
.condition1LineLeadingvide22 {
  left: 48.5rem;
}
.condition1LineLeadingvide23,
.condition1LineLeadingvide24,
.condition2LineLeadingvide45,
.condition2LineLeadingvide46,
.condition2LineLeadingvide47,
.condition2LineLeadingvide48,
.condition2LineLeadingvide49 {
  position: absolute;
  top: 107rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingvide24,
.condition2LineLeadingvide45,
.condition2LineLeadingvide46,
.condition2LineLeadingvide47,
.condition2LineLeadingvide48,
.condition2LineLeadingvide49 {
  left: 1rem;
}
.condition2LineLeadingvide45,
.condition2LineLeadingvide46,
.condition2LineLeadingvide47,
.condition2LineLeadingvide48,
.condition2LineLeadingvide49 {
  top: 100.25rem;
  left: 96rem;
}
.condition2LineLeadingvide46,
.condition2LineLeadingvide47,
.condition2LineLeadingvide48,
.condition2LineLeadingvide49 {
  left: 72.25rem;
}
.condition2LineLeadingvide47,
.condition2LineLeadingvide48,
.condition2LineLeadingvide49 {
  left: 48.5rem;
}
.condition2LineLeadingvide48,
.condition2LineLeadingvide49 {
  left: 24.75rem;
}
.condition2LineLeadingvide49 {
  left: 1rem;
}
.condition1LineLeadingimag15,
.condition1LineLeadingimag16,
.condition1LineLeadingimag17,
.condition1LineLeadingimag18,
.condition1LineLeadingimag19,
.condition2LineLeadingimag35 {
  position: absolute;
  top: 81.5rem;
  left: 96rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingimag16,
.condition1LineLeadingimag17,
.condition1LineLeadingimag18,
.condition1LineLeadingimag19,
.condition2LineLeadingimag35 {
  left: 72.25rem;
}
.condition1LineLeadingimag17,
.condition1LineLeadingimag18,
.condition1LineLeadingimag19,
.condition2LineLeadingimag35 {
  left: 48.5rem;
}
.condition1LineLeadingimag18,
.condition1LineLeadingimag19,
.condition2LineLeadingimag35 {
  left: 24.75rem;
}
.condition1LineLeadingimag19,
.condition2LineLeadingimag35 {
  left: 1rem;
}
.condition2LineLeadingimag35 {
  top: 75.75rem;
  left: 96rem;
}
.condition1LineLeadingicon30,
.condition1LineLeadingicon31,
.condition1LineLeadingicon32,
.condition2LineLeadingimag36,
.condition2LineLeadingimag37,
.condition2LineLeadingimag38,
.condition2LineLeadingimag39 {
  position: absolute;
  top: 75.75rem;
  left: 72.25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingicon30,
.condition1LineLeadingicon31,
.condition1LineLeadingicon32,
.condition2LineLeadingimag37,
.condition2LineLeadingimag38,
.condition2LineLeadingimag39 {
  left: 48.5rem;
}
.condition1LineLeadingicon30,
.condition1LineLeadingicon31,
.condition1LineLeadingicon32,
.condition2LineLeadingimag38,
.condition2LineLeadingimag39 {
  left: 24.75rem;
}
.condition1LineLeadingicon30,
.condition1LineLeadingicon31,
.condition1LineLeadingicon32,
.condition2LineLeadingimag39 {
  left: 1rem;
}
.condition1LineLeadingicon30,
.condition1LineLeadingicon31,
.condition1LineLeadingicon32 {
  top: 58rem;
  left: 96rem;
  height: 3rem;
}
.condition1LineLeadingicon31,
.condition1LineLeadingicon32 {
  left: 72.25rem;
}
.condition1LineLeadingicon32 {
  left: 48.5rem;
}
.condition1LineLeadingicon33,
.condition1LineLeadingicon34,
.condition2LineLeadingicon45,
.condition2LineLeadingicon46,
.condition2LineLeadingicon47,
.condition2LineLeadingicon48,
.condition2LineLeadingicon49 {
  position: absolute;
  top: 58rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingicon34,
.condition2LineLeadingicon45,
.condition2LineLeadingicon46,
.condition2LineLeadingicon47,
.condition2LineLeadingicon48,
.condition2LineLeadingicon49 {
  left: 1rem;
}
.condition2LineLeadingicon45,
.condition2LineLeadingicon46,
.condition2LineLeadingicon47,
.condition2LineLeadingicon48,
.condition2LineLeadingicon49 {
  top: 52.25rem;
  left: 96rem;
  height: 4rem;
}
.condition2LineLeadingicon46,
.condition2LineLeadingicon47,
.condition2LineLeadingicon48,
.condition2LineLeadingicon49 {
  left: 72.25rem;
}
.condition2LineLeadingicon47,
.condition2LineLeadingicon48,
.condition2LineLeadingicon49 {
  left: 48.5rem;
}
.condition2LineLeadingicon48,
.condition2LineLeadingicon49 {
  left: 24.75rem;
}
.condition2LineLeadingicon49 {
  left: 1rem;
}
.condition1LineLeadingmono25,
.condition1LineLeadingmono26 {
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-dark-on-primary-container);
}
.condition1LineLeadingmono25 {
  position: absolute;
  top: 35.75rem;
  left: 96rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition1LineLeadingmono26 {
  left: 72.25rem;
}
.condition1LineLeadingmono26,
.condition1LineLeadingmono27,
.condition1LineLeadingmono28 {
  position: absolute;
  top: 35.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition1LineLeadingmono27 {
  left: 48.5rem;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-dark-on-primary-container);
}
.condition1LineLeadingmono28 {
  left: 24.75rem;
}
.condition1LineLeadingmono28,
.condition1LineLeadingmono29,
.condition2LineLeadingmono45 {
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-dark-on-primary-container);
}
.condition1LineLeadingmono29 {
  position: absolute;
  top: 35.75rem;
  left: 1rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingmono45 {
  top: 30rem;
  left: 96rem;
  height: 4rem;
}
.condition2LineLeadingmono45,
.condition2LineLeadingmono46,
.condition2LineLeadingmono47 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingmono46 {
  top: 30rem;
  left: 72.25rem;
  height: 4rem;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-dark-on-primary-container);
}
.condition2LineLeadingmono47 {
  left: 48.5rem;
}
.condition2LineLeadingmono47,
.condition2LineLeadingmono48,
.condition2LineLeadingmono49 {
  top: 30rem;
  height: 4rem;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-dark-on-primary-container);
}
.condition2LineLeadingmono48 {
  position: absolute;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingmono49 {
  left: 1rem;
}
.condition2LineLeadingmono49,
.condition3LineLeadingmon95,
.condition3LineLeadingmon96 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon95 {
  top: 23.25rem;
  left: 96rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-dark-on-primary-container);
}
.condition3LineLeadingmon96 {
  left: 72.25rem;
}
.condition3LineLeadingmon96,
.condition3LineLeadingmon97,
.condition3LineLeadingmon98 {
  top: 23.25rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-dark-on-primary-container);
}
.condition3LineLeadingmon97 {
  position: absolute;
  left: 48.5rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon98 {
  left: 24.75rem;
}
.condition1LineLeadingnone30,
.condition1LineLeadingnone31,
.condition1LineLeadingnone32,
.condition3LineLeadingmon98,
.condition3LineLeadingmon99 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon99 {
  top: 23.25rem;
  left: 1rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-dark-on-primary-container);
}
.condition1LineLeadingnone30,
.condition1LineLeadingnone31,
.condition1LineLeadingnone32 {
  top: 13.5rem;
  left: 96rem;
  height: 3rem;
  justify-content: center;
}
.condition1LineLeadingnone31,
.condition1LineLeadingnone32 {
  left: 72.25rem;
}
.condition1LineLeadingnone32 {
  left: 48.5rem;
}
.condition1LineLeadingnone33,
.condition1LineLeadingnone34,
.condition2LineLeadingnone45,
.condition2LineLeadingnone46,
.condition2LineLeadingnone47,
.condition2LineLeadingnone48,
.condition2LineLeadingnone49 {
  position: absolute;
  top: 13.5rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingnone34,
.condition2LineLeadingnone45,
.condition2LineLeadingnone46,
.condition2LineLeadingnone47,
.condition2LineLeadingnone48,
.condition2LineLeadingnone49 {
  left: 1rem;
}
.condition2LineLeadingnone45,
.condition2LineLeadingnone46,
.condition2LineLeadingnone47,
.condition2LineLeadingnone48,
.condition2LineLeadingnone49 {
  top: 7.75rem;
  left: 96rem;
  height: 4rem;
}
.condition2LineLeadingnone46,
.condition2LineLeadingnone47,
.condition2LineLeadingnone48,
.condition2LineLeadingnone49 {
  left: 72.25rem;
}
.condition2LineLeadingnone47,
.condition2LineLeadingnone48,
.condition2LineLeadingnone49 {
  left: 48.5rem;
}
.condition2LineLeadingnone48,
.condition2LineLeadingnone49 {
  left: 24.75rem;
}
.condition2LineLeadingnone49 {
  left: 1rem;
}
.listItemlistItem2Densit1 {
  position: relative;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-on-surface);
  width: 48.75rem;
  height: 181.38rem;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  text-align: left;
  font-size: var(--m3-label-medium-size);
}
.condition2LineLeadingswit20 {
  top: 468.5rem;
  left: 24.75rem;
  height: 3.5rem;
  justify-content: center;
}
.condition2LineLeadingswit20,
.condition2LineLeadingswit21,
.condition3LineLeadingswi40,
.condition3LineLeadingswi41 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingswit21 {
  top: 468.5rem;
  left: 1rem;
  height: 3.5rem;
  justify-content: center;
}
.condition3LineLeadingswi40,
.condition3LineLeadingswi41 {
  top: 460.75rem;
  left: 24.75rem;
  justify-content: flex-start;
}
.condition3LineLeadingswi41 {
  left: 1rem;
}
.condition2LineLeadingradi20,
.condition2LineLeadingradi21,
.condition3LineLeadingswi42,
.condition3LineLeadingswi43,
.condition3LineLeadingswi44,
.condition3LineLeadingswi45 {
  position: absolute;
  top: 454rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition2LineLeadingradi20,
.condition2LineLeadingradi21,
.condition3LineLeadingswi43,
.condition3LineLeadingswi44,
.condition3LineLeadingswi45 {
  left: 1rem;
}
.condition2LineLeadingradi20,
.condition2LineLeadingradi21,
.condition3LineLeadingswi44,
.condition3LineLeadingswi45 {
  top: 447.25rem;
  left: 24.75rem;
}
.condition2LineLeadingradi20,
.condition2LineLeadingradi21,
.condition3LineLeadingswi45 {
  left: 1rem;
}
.condition2LineLeadingradi20,
.condition2LineLeadingradi21 {
  top: 431.75rem;
  left: 24.75rem;
  height: 3.5rem;
  justify-content: center;
}
.condition2LineLeadingradi21 {
  left: 1rem;
}
.condition3LineLeadingrad40,
.condition3LineLeadingrad41,
.condition3LineLeadingrad42,
.condition3LineLeadingrad43,
.condition3LineLeadingrad44,
.condition3LineLeadingrad45 {
  position: absolute;
  top: 424rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingrad41,
.condition3LineLeadingrad42,
.condition3LineLeadingrad43,
.condition3LineLeadingrad44,
.condition3LineLeadingrad45 {
  left: 1rem;
}
.condition3LineLeadingrad42,
.condition3LineLeadingrad43,
.condition3LineLeadingrad44,
.condition3LineLeadingrad45 {
  top: 417.25rem;
  left: 24.75rem;
}
.condition3LineLeadingrad43,
.condition3LineLeadingrad44,
.condition3LineLeadingrad45 {
  left: 1rem;
}
.condition3LineLeadingrad44,
.condition3LineLeadingrad45 {
  top: 410.5rem;
  left: 24.75rem;
}
.condition3LineLeadingrad45 {
  left: 1rem;
}
.condition2LineLeadingchec20 {
  top: 395rem;
  left: 24.75rem;
  height: 3.5rem;
  justify-content: center;
}
.condition2LineLeadingchec20,
.condition2LineLeadingchec21,
.condition3LineLeadingche40,
.condition3LineLeadingche41,
.condition3LineLeadingche42 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingchec21 {
  top: 395rem;
  left: 1rem;
  height: 3.5rem;
  justify-content: center;
}
.condition3LineLeadingche40,
.condition3LineLeadingche41,
.condition3LineLeadingche42 {
  top: 387.25rem;
  left: 24.75rem;
  justify-content: flex-start;
}
.condition3LineLeadingche41,
.condition3LineLeadingche42 {
  left: 1rem;
}
.condition3LineLeadingche42 {
  top: 380.5rem;
  left: 24.75rem;
}
.condition2LineLeadingvide50,
.condition2LineLeadingvide51,
.condition2LineLeadingvide52,
.condition3LineLeadingche43,
.condition3LineLeadingche44,
.condition3LineLeadingche45 {
  position: absolute;
  top: 380.5rem;
  left: 1rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition2LineLeadingvide50,
.condition2LineLeadingvide51,
.condition2LineLeadingvide52,
.condition3LineLeadingche44,
.condition3LineLeadingche45 {
  top: 373.75rem;
  left: 24.75rem;
}
.condition2LineLeadingvide50,
.condition2LineLeadingvide51,
.condition2LineLeadingvide52,
.condition3LineLeadingche45 {
  left: 1rem;
}
.condition2LineLeadingvide50,
.condition2LineLeadingvide51,
.condition2LineLeadingvide52 {
  top: 362rem;
  left: 96rem;
  height: 4.5rem;
  justify-content: center;
}
.condition2LineLeadingvide51,
.condition2LineLeadingvide52 {
  left: 72.25rem;
}
.condition2LineLeadingvide52 {
  left: 48.5rem;
}
.condition2LineLeadingvide53 {
  top: 362rem;
  left: 24.75rem;
  height: 4.5rem;
  justify-content: center;
}
.condition2LineLeadingvide53,
.condition2LineLeadingvide54,
.condition3LineLeadingvid100,
.condition3LineLeadingvid101,
.condition3LineLeadingvid102 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingvide54 {
  top: 362rem;
  left: 1rem;
  height: 4.5rem;
  justify-content: center;
}
.condition3LineLeadingvid100,
.condition3LineLeadingvid101,
.condition3LineLeadingvid102 {
  top: 353.63rem;
  left: 96rem;
  justify-content: flex-start;
}
.condition3LineLeadingvid101,
.condition3LineLeadingvid102 {
  left: 48.5rem;
}
.condition3LineLeadingvid102 {
  left: 24.75rem;
}
.condition3LineLeadingvid103,
.condition3LineLeadingvid104,
.condition3LineLeadingvid105,
.condition3LineLeadingvid106,
.condition3LineLeadingvid107,
.condition3LineLeadingvid108 {
  position: absolute;
  top: 353.63rem;
  left: 1rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingvid104,
.condition3LineLeadingvid105,
.condition3LineLeadingvid106,
.condition3LineLeadingvid107,
.condition3LineLeadingvid108 {
  top: 353rem;
  left: 72.25rem;
}
.condition3LineLeadingvid105,
.condition3LineLeadingvid106,
.condition3LineLeadingvid107,
.condition3LineLeadingvid108 {
  top: 346.25rem;
  left: 96rem;
}
.condition3LineLeadingvid106,
.condition3LineLeadingvid107,
.condition3LineLeadingvid108 {
  left: 72.25rem;
}
.condition3LineLeadingvid107,
.condition3LineLeadingvid108 {
  left: 48.5rem;
}
.condition3LineLeadingvid108 {
  left: 24.75rem;
}
.condition3LineLeadingvid109,
.condition3LineLeadingvid110,
.condition3LineLeadingvid111,
.condition3LineLeadingvid112,
.condition3LineLeadingvid113,
.condition3LineLeadingvid114 {
  position: absolute;
  top: 346.25rem;
  left: 1rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingvid110,
.condition3LineLeadingvid111,
.condition3LineLeadingvid112,
.condition3LineLeadingvid113,
.condition3LineLeadingvid114 {
  top: 339.5rem;
  left: 96rem;
}
.condition3LineLeadingvid111,
.condition3LineLeadingvid112,
.condition3LineLeadingvid113,
.condition3LineLeadingvid114 {
  left: 72.25rem;
}
.condition3LineLeadingvid112,
.condition3LineLeadingvid113,
.condition3LineLeadingvid114 {
  left: 48.5rem;
}
.condition3LineLeadingvid113,
.condition3LineLeadingvid114 {
  left: 24.75rem;
}
.condition3LineLeadingvid114 {
  left: 1rem;
}
.condition3LineLeadingima100,
.condition3LineLeadingima101,
.condition3LineLeadingima102,
.condition3LineLeadingima103,
.condition3LineLeadingima104,
.condition3LineLeadingima105 {
  position: absolute;
  top: 316.38rem;
  left: 96rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingima101,
.condition3LineLeadingima102,
.condition3LineLeadingima103,
.condition3LineLeadingima104,
.condition3LineLeadingima105 {
  left: 48.5rem;
}
.condition3LineLeadingima102,
.condition3LineLeadingima103,
.condition3LineLeadingima104,
.condition3LineLeadingima105 {
  left: 24.75rem;
}
.condition3LineLeadingima103,
.condition3LineLeadingima104,
.condition3LineLeadingima105 {
  left: 1rem;
}
.condition3LineLeadingima104,
.condition3LineLeadingima105 {
  top: 315.75rem;
  left: 72.25rem;
}
.condition3LineLeadingima105 {
  top: 309rem;
  left: 96rem;
}
.condition3LineLeadingima106,
.condition3LineLeadingima107,
.condition3LineLeadingima108,
.condition3LineLeadingima109,
.condition3LineLeadingima110,
.condition3LineLeadingima111 {
  position: absolute;
  top: 309rem;
  left: 72.25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingima107,
.condition3LineLeadingima108,
.condition3LineLeadingima109,
.condition3LineLeadingima110,
.condition3LineLeadingima111 {
  left: 48.5rem;
}
.condition3LineLeadingima108,
.condition3LineLeadingima109,
.condition3LineLeadingima110,
.condition3LineLeadingima111 {
  left: 24.75rem;
}
.condition3LineLeadingima109,
.condition3LineLeadingima110,
.condition3LineLeadingima111 {
  left: 1rem;
}
.condition3LineLeadingima110,
.condition3LineLeadingima111 {
  top: 302.25rem;
  left: 96rem;
}
.condition3LineLeadingima111 {
  left: 72.25rem;
}
.condition2LineLeadingicon50,
.condition2LineLeadingicon51,
.condition2LineLeadingicon52,
.condition3LineLeadingima112,
.condition3LineLeadingima113,
.condition3LineLeadingima114 {
  position: absolute;
  top: 302.25rem;
  left: 48.5rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition2LineLeadingicon50,
.condition2LineLeadingicon51,
.condition2LineLeadingicon52,
.condition3LineLeadingima113,
.condition3LineLeadingima114 {
  left: 24.75rem;
}
.condition2LineLeadingicon50,
.condition2LineLeadingicon51,
.condition2LineLeadingicon52,
.condition3LineLeadingima114 {
  left: 1rem;
}
.condition2LineLeadingicon50,
.condition2LineLeadingicon51,
.condition2LineLeadingicon52 {
  top: 286.75rem;
  left: 96rem;
  height: 3.5rem;
  justify-content: center;
}
.condition2LineLeadingicon51,
.condition2LineLeadingicon52 {
  left: 72.25rem;
}
.condition2LineLeadingicon52 {
  left: 48.5rem;
}
.condition2LineLeadingicon53 {
  top: 286.75rem;
  left: 24.75rem;
  height: 3.5rem;
  justify-content: center;
}
.condition2LineLeadingicon53,
.condition2LineLeadingicon54,
.condition3LineLeadingico100,
.condition3LineLeadingico101,
.condition3LineLeadingico102 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingicon54 {
  top: 286.75rem;
  left: 1rem;
  height: 3.5rem;
  justify-content: center;
}
.condition3LineLeadingico100,
.condition3LineLeadingico101,
.condition3LineLeadingico102 {
  top: 279rem;
  left: 96rem;
  justify-content: flex-start;
}
.condition3LineLeadingico101,
.condition3LineLeadingico102 {
  left: 72.25rem;
}
.condition3LineLeadingico102 {
  left: 48.5rem;
}
.condition3LineLeadingico103,
.condition3LineLeadingico104,
.condition3LineLeadingico105,
.condition3LineLeadingico106,
.condition3LineLeadingico107,
.condition3LineLeadingico108 {
  position: absolute;
  top: 279rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingico104,
.condition3LineLeadingico105,
.condition3LineLeadingico106,
.condition3LineLeadingico107,
.condition3LineLeadingico108 {
  left: 1rem;
}
.condition3LineLeadingico105,
.condition3LineLeadingico106,
.condition3LineLeadingico107,
.condition3LineLeadingico108 {
  top: 272.25rem;
  left: 96rem;
}
.condition3LineLeadingico106,
.condition3LineLeadingico107,
.condition3LineLeadingico108 {
  left: 72.25rem;
}
.condition3LineLeadingico107,
.condition3LineLeadingico108 {
  left: 48.5rem;
}
.condition3LineLeadingico108 {
  left: 24.75rem;
}
.condition3LineLeadingico109,
.condition3LineLeadingico110,
.condition3LineLeadingico111,
.condition3LineLeadingico112,
.condition3LineLeadingico113,
.condition3LineLeadingico114 {
  position: absolute;
  top: 272.25rem;
  left: 1rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingico110,
.condition3LineLeadingico111,
.condition3LineLeadingico112,
.condition3LineLeadingico113,
.condition3LineLeadingico114 {
  top: 265.5rem;
  left: 96rem;
}
.condition3LineLeadingico111,
.condition3LineLeadingico112,
.condition3LineLeadingico113,
.condition3LineLeadingico114 {
  left: 72.25rem;
}
.condition3LineLeadingico112,
.condition3LineLeadingico113,
.condition3LineLeadingico114 {
  left: 48.5rem;
}
.condition3LineLeadingico113,
.condition3LineLeadingico114 {
  left: 24.75rem;
}
.condition3LineLeadingico114 {
  left: 1rem;
}
.condition2LineLeadingmono50,
.condition2LineLeadingmono51,
.condition2LineLeadingmono52,
.condition2LineLeadingmono53,
.condition2LineLeadingmono54 {
  position: absolute;
  top: 250rem;
  left: 96rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition2LineLeadingmono51,
.condition2LineLeadingmono52,
.condition2LineLeadingmono53,
.condition2LineLeadingmono54 {
  left: 72.25rem;
}
.condition2LineLeadingmono52,
.condition2LineLeadingmono53,
.condition2LineLeadingmono54 {
  left: 48.5rem;
}
.condition2LineLeadingmono53,
.condition2LineLeadingmono54 {
  left: 24.75rem;
}
.condition2LineLeadingmono54 {
  left: 1rem;
}
.leadingElement1054 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--m3-sys-dark-on-primary-container);
}
.condition3LineLeadingmon100,
.condition3LineLeadingmon101 {
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon100 {
  position: absolute;
  top: 242.25rem;
  left: 96rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon101 {
  left: 72.25rem;
}
.condition3LineLeadingmon101,
.condition3LineLeadingmon102,
.condition3LineLeadingmon103 {
  position: absolute;
  top: 242.25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon102 {
  left: 48.5rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon103 {
  left: 24.75rem;
}
.condition3LineLeadingmon103,
.condition3LineLeadingmon104,
.condition3LineLeadingmon105 {
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon104 {
  position: absolute;
  top: 242.25rem;
  left: 1rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon105 {
  top: 235.5rem;
  left: 96rem;
}
.condition3LineLeadingmon105,
.condition3LineLeadingmon106,
.condition3LineLeadingmon107 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon106 {
  top: 235.5rem;
  left: 72.25rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon107 {
  left: 48.5rem;
}
.condition3LineLeadingmon107,
.condition3LineLeadingmon108,
.condition3LineLeadingmon109 {
  top: 235.5rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon108 {
  position: absolute;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon109 {
  left: 1rem;
}
.condition3LineLeadingmon109,
.condition3LineLeadingmon110,
.condition3LineLeadingmon111 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon110 {
  top: 228.75rem;
  left: 96rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon111 {
  left: 72.25rem;
}
.condition3LineLeadingmon111,
.condition3LineLeadingmon112,
.condition3LineLeadingmon113 {
  top: 228.75rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon112 {
  position: absolute;
  left: 48.5rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon113 {
  left: 24.75rem;
}
.condition2LineLeadingnone50,
.condition2LineLeadingnone51,
.condition2LineLeadingnone52,
.condition3LineLeadingmon113,
.condition3LineLeadingmon114 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon114 {
  top: 228.75rem;
  left: 1rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition2LineLeadingnone50,
.condition2LineLeadingnone51,
.condition2LineLeadingnone52 {
  top: 213.25rem;
  left: 96rem;
  height: 3.5rem;
  justify-content: center;
}
.condition2LineLeadingnone51,
.condition2LineLeadingnone52 {
  left: 72.25rem;
}
.condition2LineLeadingnone52 {
  left: 48.5rem;
}
.condition2LineLeadingnone53 {
  top: 213.25rem;
  left: 24.75rem;
  height: 3.5rem;
  justify-content: center;
}
.condition2LineLeadingnone53,
.condition2LineLeadingnone54,
.condition3LineLeadingnon100 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingnone54 {
  top: 213.25rem;
  left: 1rem;
  height: 3.5rem;
  justify-content: center;
}
.condition3LineLeadingnon100 {
  top: 205.5rem;
  left: 96rem;
  justify-content: flex-start;
}
.condition3LineLeadingnon101,
.condition3LineLeadingnon102,
.condition3LineLeadingnon103,
.condition3LineLeadingnon104,
.condition3LineLeadingnon105,
.condition3LineLeadingnon106 {
  position: absolute;
  top: 205.5rem;
  left: 72.25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingnon102,
.condition3LineLeadingnon103,
.condition3LineLeadingnon104,
.condition3LineLeadingnon105,
.condition3LineLeadingnon106 {
  left: 48.5rem;
}
.condition3LineLeadingnon103,
.condition3LineLeadingnon104,
.condition3LineLeadingnon105,
.condition3LineLeadingnon106 {
  left: 24.75rem;
}
.condition3LineLeadingnon104,
.condition3LineLeadingnon105,
.condition3LineLeadingnon106 {
  left: 1rem;
}
.condition3LineLeadingnon105,
.condition3LineLeadingnon106 {
  top: 198.75rem;
  left: 96rem;
}
.condition3LineLeadingnon106 {
  left: 72.25rem;
}
.condition3LineLeadingnon107,
.condition3LineLeadingnon108,
.condition3LineLeadingnon109,
.condition3LineLeadingnon110,
.condition3LineLeadingnon111,
.condition3LineLeadingnon112 {
  position: absolute;
  top: 198.75rem;
  left: 48.5rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingnon108,
.condition3LineLeadingnon109,
.condition3LineLeadingnon110,
.condition3LineLeadingnon111,
.condition3LineLeadingnon112 {
  left: 24.75rem;
}
.condition3LineLeadingnon109,
.condition3LineLeadingnon110,
.condition3LineLeadingnon111,
.condition3LineLeadingnon112 {
  left: 1rem;
}
.condition3LineLeadingnon110,
.condition3LineLeadingnon111,
.condition3LineLeadingnon112 {
  top: 192rem;
  left: 96rem;
}
.condition3LineLeadingnon111,
.condition3LineLeadingnon112 {
  left: 72.25rem;
}
.condition3LineLeadingnon112 {
  left: 48.5rem;
}
.condition1LineLeadingswit14,
.condition1LineLeadingswit15,
.condition2LineLeadingswit22,
.condition2LineLeadingswit23,
.condition3LineLeadingnon113,
.condition3LineLeadingnon114 {
  position: absolute;
  top: 192rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingswit14,
.condition1LineLeadingswit15,
.condition2LineLeadingswit22,
.condition2LineLeadingswit23,
.condition3LineLeadingnon114 {
  left: 1rem;
}
.condition1LineLeadingswit14,
.condition1LineLeadingswit15,
.condition2LineLeadingswit22,
.condition2LineLeadingswit23 {
  top: 176rem;
  left: 24.75rem;
  height: 2.5rem;
  justify-content: center;
}
.condition1LineLeadingswit15,
.condition2LineLeadingswit22,
.condition2LineLeadingswit23 {
  left: 1rem;
}
.condition2LineLeadingswit22,
.condition2LineLeadingswit23 {
  top: 170.25rem;
  left: 24.75rem;
  height: 3.5rem;
}
.condition2LineLeadingswit23 {
  left: 1rem;
}
.condition1LineLeadingradi14,
.condition1LineLeadingradi15,
.condition2LineLeadingradi22,
.condition2LineLeadingradi23,
.condition3LineLeadingswi46,
.condition3LineLeadingswi47 {
  position: absolute;
  top: 163.5rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingradi14,
.condition1LineLeadingradi15,
.condition2LineLeadingradi22,
.condition2LineLeadingradi23,
.condition3LineLeadingswi47 {
  left: 1rem;
}
.condition1LineLeadingradi14,
.condition1LineLeadingradi15,
.condition2LineLeadingradi22,
.condition2LineLeadingradi23 {
  top: 153.75rem;
  left: 24.75rem;
  height: 2.5rem;
  justify-content: center;
}
.condition1LineLeadingradi15,
.condition2LineLeadingradi22,
.condition2LineLeadingradi23 {
  left: 1rem;
}
.condition2LineLeadingradi22,
.condition2LineLeadingradi23 {
  top: 148rem;
  left: 24.75rem;
  height: 3.5rem;
}
.condition2LineLeadingradi23 {
  left: 1rem;
}
.condition1LineLeadingchec14,
.condition1LineLeadingchec15,
.condition3LineLeadingrad46,
.condition3LineLeadingrad47 {
  position: absolute;
  top: 141.25rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingchec14,
.condition1LineLeadingchec15,
.condition3LineLeadingrad47 {
  left: 1rem;
}
.condition1LineLeadingchec14,
.condition1LineLeadingchec15 {
  top: 131.5rem;
  left: 24.75rem;
  height: 2.5rem;
  justify-content: center;
}
.condition1LineLeadingchec15 {
  left: 1rem;
}
.condition2LineLeadingchec22 {
  top: 125.75rem;
  left: 24.75rem;
  height: 3.5rem;
  justify-content: center;
}
.condition1LineLeadingvide25,
.condition2LineLeadingchec22,
.condition2LineLeadingchec23,
.condition3LineLeadingche46,
.condition3LineLeadingche47 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingchec23 {
  top: 125.75rem;
  left: 1rem;
  height: 3.5rem;
  justify-content: center;
}
.condition1LineLeadingvide25,
.condition3LineLeadingche46,
.condition3LineLeadingche47 {
  top: 119rem;
  left: 24.75rem;
  justify-content: flex-start;
}
.condition1LineLeadingvide25,
.condition3LineLeadingche47 {
  left: 1rem;
}
.condition1LineLeadingvide25 {
  top: 107.25rem;
  left: 96rem;
  height: 4.5rem;
  justify-content: center;
}
.condition1LineLeadingvide26,
.condition1LineLeadingvide27,
.condition1LineLeadingvide28,
.condition1LineLeadingvide29,
.condition2LineLeadingvide55,
.condition2LineLeadingvide56,
.condition2LineLeadingvide57 {
  position: absolute;
  top: 107.25rem;
  left: 72.25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingvide27,
.condition1LineLeadingvide28,
.condition1LineLeadingvide29,
.condition2LineLeadingvide55,
.condition2LineLeadingvide56,
.condition2LineLeadingvide57 {
  left: 48.5rem;
}
.condition1LineLeadingvide28,
.condition1LineLeadingvide29,
.condition2LineLeadingvide55,
.condition2LineLeadingvide56,
.condition2LineLeadingvide57 {
  left: 24.75rem;
}
.condition1LineLeadingvide29,
.condition2LineLeadingvide55,
.condition2LineLeadingvide56,
.condition2LineLeadingvide57 {
  left: 1rem;
}
.condition2LineLeadingvide55,
.condition2LineLeadingvide56,
.condition2LineLeadingvide57 {
  top: 100.5rem;
  left: 96rem;
}
.condition2LineLeadingvide56,
.condition2LineLeadingvide57 {
  left: 72.25rem;
}
.condition2LineLeadingvide57 {
  left: 48.5rem;
}
.condition2LineLeadingvide58 {
  top: 100.5rem;
  left: 24.75rem;
  height: 4.5rem;
  justify-content: center;
}
.condition2LineLeadingvide58,
.condition2LineLeadingvide59,
.condition3LineLeadingvid115 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingvide59 {
  top: 100.5rem;
  left: 1rem;
  height: 4.5rem;
  justify-content: center;
}
.condition3LineLeadingvid115 {
  top: 92.5rem;
  left: 96rem;
  justify-content: flex-start;
}
.condition3LineLeadingima115,
.condition3LineLeadingima116,
.condition3LineLeadingvid116,
.condition3LineLeadingvid117,
.condition3LineLeadingvid118,
.condition3LineLeadingvid119 {
  position: absolute;
  top: 92.5rem;
  left: 72.25rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingima115,
.condition3LineLeadingima116,
.condition3LineLeadingvid117,
.condition3LineLeadingvid118,
.condition3LineLeadingvid119 {
  left: 48.5rem;
}
.condition3LineLeadingima115,
.condition3LineLeadingima116,
.condition3LineLeadingvid118,
.condition3LineLeadingvid119 {
  left: 24.75rem;
}
.condition3LineLeadingima115,
.condition3LineLeadingima116,
.condition3LineLeadingvid119 {
  left: 1rem;
}
.condition3LineLeadingima115,
.condition3LineLeadingima116 {
  top: 68rem;
  left: 96rem;
}
.condition3LineLeadingima116 {
  left: 72.25rem;
}
.condition1LineLeadingicon35,
.condition1LineLeadingicon36,
.condition1LineLeadingicon37,
.condition3LineLeadingima117,
.condition3LineLeadingima118,
.condition3LineLeadingima119 {
  position: absolute;
  top: 68rem;
  left: 48.5rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition1LineLeadingicon35,
.condition1LineLeadingicon36,
.condition1LineLeadingicon37,
.condition3LineLeadingima118,
.condition3LineLeadingima119 {
  left: 24.75rem;
}
.condition1LineLeadingicon35,
.condition1LineLeadingicon36,
.condition1LineLeadingicon37,
.condition3LineLeadingima119 {
  left: 1rem;
}
.condition1LineLeadingicon35,
.condition1LineLeadingicon36,
.condition1LineLeadingicon37 {
  top: 58.25rem;
  left: 96rem;
  height: 2.5rem;
  justify-content: center;
}
.condition1LineLeadingicon36,
.condition1LineLeadingicon37 {
  left: 72.25rem;
}
.condition1LineLeadingicon37 {
  left: 48.5rem;
}
.condition1LineLeadingicon38,
.condition1LineLeadingicon39,
.condition2LineLeadingicon55,
.condition2LineLeadingicon56,
.condition2LineLeadingicon57,
.condition2LineLeadingicon58,
.condition2LineLeadingicon59 {
  position: absolute;
  top: 58.25rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingicon39,
.condition2LineLeadingicon55,
.condition2LineLeadingicon56,
.condition2LineLeadingicon57,
.condition2LineLeadingicon58,
.condition2LineLeadingicon59 {
  left: 1rem;
}
.condition2LineLeadingicon55,
.condition2LineLeadingicon56,
.condition2LineLeadingicon57,
.condition2LineLeadingicon58,
.condition2LineLeadingicon59 {
  top: 52.5rem;
  left: 96rem;
  height: 3.5rem;
}
.condition2LineLeadingicon56,
.condition2LineLeadingicon57,
.condition2LineLeadingicon58,
.condition2LineLeadingicon59 {
  left: 72.25rem;
}
.condition2LineLeadingicon57,
.condition2LineLeadingicon58,
.condition2LineLeadingicon59 {
  left: 48.5rem;
}
.condition2LineLeadingicon58,
.condition2LineLeadingicon59 {
  left: 24.75rem;
}
.condition2LineLeadingicon59 {
  left: 1rem;
}
.condition3LineLeadingico115,
.condition3LineLeadingico116,
.condition3LineLeadingico117,
.condition3LineLeadingico118,
.condition3LineLeadingico119 {
  position: absolute;
  top: 45.75rem;
  left: 96rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingico116,
.condition3LineLeadingico117,
.condition3LineLeadingico118,
.condition3LineLeadingico119 {
  left: 72.25rem;
}
.condition3LineLeadingico117,
.condition3LineLeadingico118,
.condition3LineLeadingico119 {
  left: 48.5rem;
}
.condition3LineLeadingico118,
.condition3LineLeadingico119 {
  left: 24.75rem;
}
.condition3LineLeadingico119 {
  left: 1rem;
}
.condition2LineLeadingmono55 {
  top: 30.25rem;
  left: 96rem;
  height: 3.5rem;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition2LineLeadingmono55,
.condition2LineLeadingmono56,
.condition2LineLeadingmono57 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingmono56 {
  top: 30.25rem;
  left: 72.25rem;
  height: 3.5rem;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition2LineLeadingmono57 {
  left: 48.5rem;
}
.condition2LineLeadingmono57,
.condition2LineLeadingmono58,
.condition2LineLeadingmono59 {
  top: 30.25rem;
  height: 3.5rem;
  justify-content: center;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition2LineLeadingmono58 {
  position: absolute;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition2LineLeadingmono59 {
  left: 1rem;
}
.condition2LineLeadingmono59,
.condition3LineLeadingmon115,
.condition3LineLeadingmon116 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon115 {
  top: 23.5rem;
  left: 96rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon116 {
  left: 72.25rem;
}
.condition3LineLeadingmon116,
.condition3LineLeadingmon117,
.condition3LineLeadingmon118 {
  top: 23.5rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition3LineLeadingmon117 {
  position: absolute;
  left: 48.5rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon118 {
  left: 24.75rem;
}
.condition1LineLeadingnone35,
.condition1LineLeadingnone36,
.condition1LineLeadingnone37,
.condition3LineLeadingmon118,
.condition3LineLeadingmon119 {
  position: absolute;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.condition3LineLeadingmon119 {
  top: 23.5rem;
  left: 1rem;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--m3-title-medium-size);
}
.condition1LineLeadingnone35,
.condition1LineLeadingnone36,
.condition1LineLeadingnone37 {
  top: 13.75rem;
  left: 96rem;
  height: 2.5rem;
  justify-content: center;
}
.condition1LineLeadingnone36,
.condition1LineLeadingnone37 {
  left: 72.25rem;
}
.condition1LineLeadingnone37 {
  left: 48.5rem;
}
.condition1LineLeadingnone38,
.condition1LineLeadingnone39,
.condition2LineLeadingnone55,
.condition2LineLeadingnone56,
.condition2LineLeadingnone57,
.condition2LineLeadingnone58,
.condition2LineLeadingnone59 {
  position: absolute;
  top: 13.75rem;
  left: 24.75rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  height: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.condition1LineLeadingnone39,
.condition2LineLeadingnone55,
.condition2LineLeadingnone56,
.condition2LineLeadingnone57,
.condition2LineLeadingnone58,
.condition2LineLeadingnone59 {
  left: 1rem;
}
.condition2LineLeadingnone55,
.condition2LineLeadingnone56,
.condition2LineLeadingnone57,
.condition2LineLeadingnone58,
.condition2LineLeadingnone59 {
  top: 8rem;
  left: 96rem;
  height: 3.5rem;
}
.condition2LineLeadingnone56,
.condition2LineLeadingnone57,
.condition2LineLeadingnone58,
.condition2LineLeadingnone59 {
  left: 72.25rem;
}
.condition2LineLeadingnone57,
.condition2LineLeadingnone58,
.condition2LineLeadingnone59 {
  left: 48.5rem;
}
.condition2LineLeadingnone58,
.condition2LineLeadingnone59 {
  left: 24.75rem;
}
.condition2LineLeadingnone59 {
  left: 1rem;
}
.condition3LineLeadingnon115,
.condition3LineLeadingnon116,
.condition3LineLeadingnon117,
.condition3LineLeadingnon118,
.condition3LineLeadingnon119 {
  position: absolute;
  top: 1.25rem;
  left: 96rem;
  background-color: var(--m3-sys-dark-surface);
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.condition3LineLeadingnon116,
.condition3LineLeadingnon117,
.condition3LineLeadingnon118,
.condition3LineLeadingnon119 {
  left: 72.25rem;
}
.condition3LineLeadingnon117,
.condition3LineLeadingnon118,
.condition3LineLeadingnon119 {
  left: 48.5rem;
}
.condition3LineLeadingnon118,
.condition3LineLeadingnon119 {
  left: 24.75rem;
}
.condition3LineLeadingnon119 {
  left: 1rem;
}
.listItemlistItem4Densit1 {
  position: relative;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-on-surface);
  width: 47.5rem;
  height: 181.38rem;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  text-align: left;
  font-size: var(--m3-label-medium-size);
}
.buildingBlocksstateLayerDaChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--m3-state-layers-dark-on-surface-opacity-008);
}
.buildingBlocksstateLayerDa,
.buildingBlocksstateLayerDa1 {
  position: absolute;
  top: 1.25rem;
  left: 5.31rem;
  width: 2.5rem;
  height: 2.5rem;
}
.buildingBlocksstateLayerDa1 {
  left: 1.19rem;
}
.buildingBlocksstateLayerDaItem {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--m3-state-layers-dark-on-surface-opacity-012);
}
.buildingBlocksstateLayerDa2,
.buildingBlocksstateLayerDa3 {
  position: absolute;
  top: 1.25rem;
  left: 9.44rem;
  width: 2.5rem;
  height: 2.5rem;
}
.buildingBlocksstateLayerDa3 {
  left: 13.56rem;
  overflow: hidden;
}
.rectangleDiv {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--m3-state-layers-dark-on-surface-opacity-016);
}
.buildingBlocksstateLayerDa4 {
  position: absolute;
  top: 1.25rem;
  left: 17.69rem;
  background-color: var(--m3-sys-dark-surface);
  box-shadow: var(--m3-elevation-dark-5);
  width: 2.5rem;
  height: 2.5rem;
}
.stateLayerColorsDark {
  position: relative;
  width: 21.44rem;
  height: 5rem;
}
.buildingBlocks2,
.buildingBlocks3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buildingBlocks3 {
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-on-surface);
  width: 54.38rem;
  flex-direction: row;
  padding: var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-21xl);
  color: var(--m3-sys-dark-on-primary-container);
}
.buildingBlocks2 {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-3xs);
  color: var(--m3-sys-light-on-error);
}
.components,
.darkScheme,
.lists {
  display: flex;
  justify-content: flex-start;
}
.darkScheme {
  border-radius: var(--br-base);
  background-color: var(--m3-sys-dark-surface);
  flex-direction: column;
  padding: 0 0 var(--padding-21xl);
  align-items: center;
  gap: var(--gap-21xl);
  color: var(--m3-sys-dark-on-surface-variant);
}
.components,
.lists {
  align-items: flex-start;
}
.components {
  background-color: var(--m3-sys-light-on-error);
  flex-direction: row;
  padding: var(--padding-51xl);
  gap: var(--gap-21xl);
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-light-on-surface-variant);
}
.lists {
  position: relative;
  border-radius: var(--br-41xl) var(--br-41xl) var(--br-base) var(--br-base);
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  text-align: center;
  font-size: var(--font-size-61xl);
  color: var(--m3-black);
  font-family: var(--m3-headline-medium);
}
