.logoIcon {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 2.5rem;
  flex-shrink: 0;
}
.componentName,
.link,
.logoIcon {
  position: relative;
}
.link {
  font-size: var(--m3-headline-small-size);
  text-decoration: underline;
  line-height: 2rem;
  color: var(--m3-sys-light-primary);
}
.definition {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 1.5rem;
  text-align: left;
}
.header,
.title {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.header {
  background-color: var(--m3-sys-dark-on-surface);
  overflow: hidden;
  padding: var(--padding-33xl) var(--padding-33xl) var(--padding-51xl);
  gap: var(--gap-41xl);
}
.icon {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
}
.iconContainer,
.stateLayer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stateLayer {
  width: 4rem;
  height: 2rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-xl);
  box-sizing: border-box;
}
.iconContainer {
  border-radius: var(--br-base);
  background-color: var(--m3-sys-dark-on-secondary-container);
  overflow: hidden;
  flex-direction: column;
}
.labelText {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 600;
}
.iconContainer1,
.segment1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.segment1 {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  gap: var(--gap-9xs);
}
.iconContainer1 {
  border-radius: var(--br-base);
  width: 2rem;
  flex-direction: row;
}
.labelText1 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 500;
}
.segment2 {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  gap: var(--gap-9xs);
  color: var(--m3-sys-light-on-surface-variant);
}
.badgeLabel,
.segment2,
.stateLayer2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stateLayer2 {
  width: 4rem;
  height: 2rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-xl);
  box-sizing: border-box;
  z-index: 0;
}
.badgeLabel {
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 7px);
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 500;
  width: 0.88rem;
  height: 0.88rem;
}
.badge {
  position: absolute;
  margin: 0 !important;
  top: 0.13rem;
  left: 1rem;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  width: 1rem;
  height: 1rem;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.iconContainer2 {
  border-radius: var(--br-base);
  width: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}
.labelText2 {
  align-self: stretch;
  position: relative;
  font-size: var(--m3-label-medium-size);
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 500;
  color: var(--m3-sys-light-on-surface-variant);
}
.segment3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
  font-size: var(--m3-label-small-size);
  color: var(--m3-sys-light-on-error);
}
.configurationiconLabelSe {
  background-color: var(--m3-sys-light-surface-container);
  width: 25.75rem;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.segment11 {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
  color: var(--m3-sys-light-on-surface);
}
.configurationiconLabelSe1 {
  background-color: var(--m3-sys-light-surface-container);
  width: 25.75rem;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--m3-sys-light-on-surface-variant);
}
.segment13,
.segment23,
.segment33 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.segment13 {
  align-self: stretch;
  padding: var(--padding-xs) 0 var(--padding-base);
  gap: var(--gap-9xs);
}
.segment23,
.segment33 {
  padding: var(--padding-3xl) 0 var(--padding-7xl);
}
.segment33 {
  font-size: var(--m3-label-small-size);
  color: var(--m3-sys-light-on-error);
}
.badge4 {
  position: absolute;
  margin: 0 !important;
  top: 0.25rem;
  left: 1.38rem;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  width: 0.38rem;
  height: 0.38rem;
  z-index: 1;
}
.iconContainer18,
.navigationBar {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.iconContainer18 {
  border-radius: var(--br-base);
  width: 2rem;
  justify-content: center;
  position: relative;
}
.navigationBar {
  align-self: stretch;
  border-radius: var(--br-5xs);
  padding: var(--padding-xl);
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.label {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 1.5rem;
  font-weight: 500;
}
.stateLayer24 {
  background-color: var(
    --m3-state-layers-light-on-secondary-container-opacity-012
  );
  width: 4rem;
  height: 2rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.badgeLabel5 {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 500;
}
.badge6,
.iconContainer24 {
  overflow: hidden;
  display: flex;
  align-items: center;
}
.badge6 {
  margin: 0 !important;
  position: absolute;
  top: 0.13rem;
  left: 2rem;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  flex-direction: column;
  padding: 0 var(--padding-9xs);
  justify-content: flex-start;
  z-index: 1;
}
.iconContainer24 {
  border-radius: var(--br-base);
  background-color: var(--m3-sys-dark-on-secondary-container);
  flex-direction: row;
  justify-content: center;
  position: relative;
}
.activetrueStatepressedLa {
  position: absolute;
  top: 95rem;
  left: 24.5rem;
  width: 6.5rem;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
  box-sizing: border-box;
  justify-content: center;
}
.activetrueStatepressedLa,
.badge7,
.stateLayer25 {
  display: flex;
  align-items: center;
}
.badge7 {
  margin: 0 !important;
  position: absolute;
  top: 0.13rem;
  left: 2rem;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  overflow: hidden;
  flex-direction: column;
  padding: 0 var(--padding-9xs);
  justify-content: flex-start;
  z-index: 0;
}
.stateLayer25 {
  background-color: var(
    --m3-state-layers-light-on-secondary-container-opacity-012
  );
  width: 4rem;
  height: 2rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-xl);
  box-sizing: border-box;
  justify-content: center;
  z-index: 1;
}
.activetrueStatefocusedLa {
  position: absolute;
  top: 95rem;
  left: 16.75rem;
  width: 6.5rem;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
}
.activetrueStateenabledLa,
.activetrueStatefocusedLa,
.activetrueStatehoveredLa,
.stateLayer26 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.stateLayer26 {
  background-color: var(
    --m3-state-layers-light-on-secondary-container-opacity-008
  );
  width: 4rem;
  height: 2rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-xl);
  z-index: 0;
}
.activetrueStateenabledLa,
.activetrueStatehoveredLa {
  position: absolute;
  top: 95rem;
  left: 9rem;
  width: 6.5rem;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
}
.activetrueStateenabledLa {
  left: 1.25rem;
}
.badge10 {
  position: absolute;
  margin: 0 !important;
  top: 0.13rem;
  left: 2rem;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  width: 1rem;
  height: 1rem;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.activetrueStateenabledLa1,
.activetrueStatefocusedLa1,
.activetrueStatehoveredLa1,
.activetrueStatepressedLa1 {
  position: absolute;
  top: 88.75rem;
  left: 24.5rem;
  width: 6.5rem;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activetrueStateenabledLa1,
.activetrueStatefocusedLa1,
.activetrueStatehoveredLa1 {
  left: 16.75rem;
}
.activetrueStateenabledLa1,
.activetrueStatehoveredLa1 {
  left: 9rem;
}
.activetrueStateenabledLa1 {
  left: 1.25rem;
}
.badge14 {
  position: absolute;
  margin: 0 !important;
  top: 0.25rem;
  left: 2.38rem;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  width: 0.38rem;
  height: 0.38rem;
  z-index: 1;
}
.activetrueStateenabledLa2,
.activetrueStatefocusedLa2,
.activetrueStatehoveredLa2,
.activetrueStatepressedLa2 {
  position: absolute;
  top: 82.5rem;
  left: 24.5rem;
  width: 6.5rem;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activetrueStateenabledLa2,
.activetrueStatefocusedLa2,
.activetrueStatehoveredLa2 {
  left: 16.75rem;
}
.activetrueStateenabledLa2,
.activetrueStatehoveredLa2 {
  left: 9rem;
}
.activetrueStateenabledLa2 {
  left: 1.25rem;
}
.labelText15 {
  align-self: stretch;
  position: relative;
  font-size: var(--m3-label-medium-size);
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 600;
  color: var(--m3-sys-light-on-surface);
}
.activetrueStateenabledLa3,
.activetrueStatefocusedLa3,
.activetrueStatehoveredLa3,
.activetrueStatepressedLa3 {
  position: absolute;
  top: 76.25rem;
  left: 24.5rem;
  width: 6.5rem;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.activetrueStateenabledLa3,
.activetrueStatefocusedLa3,
.activetrueStatehoveredLa3 {
  left: 16.75rem;
}
.activetrueStateenabledLa3,
.activetrueStatehoveredLa3 {
  left: 9rem;
}
.activetrueStateenabledLa3 {
  left: 1.25rem;
}
.activetrueStateenabledLa4,
.activetrueStateenabledLa5,
.activetrueStatefocusedLa4,
.activetrueStatefocusedLa5,
.activetrueStatehoveredLa4,
.activetrueStatehoveredLa5,
.activetrueStatepressedLa4,
.activetrueStatepressedLa5 {
  position: absolute;
  top: 70rem;
  left: 24.5rem;
  width: 6.5rem;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.activetrueStateenabledLa4,
.activetrueStateenabledLa5,
.activetrueStatefocusedLa4,
.activetrueStatefocusedLa5,
.activetrueStatehoveredLa4,
.activetrueStatehoveredLa5,
.activetrueStatepressedLa5 {
  left: 16.75rem;
}
.activetrueStateenabledLa4,
.activetrueStateenabledLa5,
.activetrueStatefocusedLa5,
.activetrueStatehoveredLa4,
.activetrueStatehoveredLa5,
.activetrueStatepressedLa5 {
  left: 9rem;
}
.activetrueStateenabledLa4,
.activetrueStateenabledLa5,
.activetrueStatefocusedLa5,
.activetrueStatehoveredLa5,
.activetrueStatepressedLa5 {
  left: 1.25rem;
}
.activetrueStateenabledLa5,
.activetrueStatefocusedLa5,
.activetrueStatehoveredLa5,
.activetrueStatepressedLa5 {
  top: 63.75rem;
  left: 24.5rem;
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-light-on-surface);
}
.activetrueStateenabledLa5,
.activetrueStatefocusedLa5,
.activetrueStatehoveredLa5 {
  left: 16.75rem;
}
.activetrueStateenabledLa5,
.activetrueStatehoveredLa5 {
  left: 9rem;
}
.activetrueStateenabledLa5 {
  left: 1.25rem;
}
.iconContainer48,
.stateLayer48 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.stateLayer48 {
  background-color: var(--m3-state-layers-light-on-surface-opacity-012);
  width: 4rem;
  height: 2rem;
  padding: var(--padding-9xs) var(--padding-xl);
  box-sizing: border-box;
  z-index: 0;
}
.iconContainer48 {
  border-radius: var(--br-base);
  overflow: hidden;
  position: relative;
}
.activefalseStatepressedL {
  position: absolute;
  top: 57.5rem;
  left: 24.5rem;
  width: 6.5rem;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
}
.activefalseStatefocusedL,
.activefalseStatepressedL,
.stateLayer50 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activefalseStatefocusedL {
  position: absolute;
  top: 57.5rem;
  left: 16.75rem;
  width: 6.5rem;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
}
.stateLayer50 {
  background-color: var(--m3-state-layers-light-on-surface-opacity-008);
  width: 4rem;
  height: 2rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-xl);
  z-index: 0;
}
.activefalseStatehoveredL,
.badge33 {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.activefalseStatehoveredL {
  top: 57.5rem;
  left: 9rem;
  width: 6.5rem;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
  box-sizing: border-box;
  justify-content: center;
}
.badge33 {
  margin: 0 !important;
  top: 0.13rem;
  left: 1rem;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  overflow: hidden;
  padding: 0 var(--padding-9xs);
  justify-content: flex-start;
  z-index: 1;
}
.activefalseStateenabledL,
.activefalseStatepressedL1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.activefalseStateenabledL {
  position: absolute;
  top: 57.5rem;
  left: 1.25rem;
  width: 6.5rem;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
  box-sizing: border-box;
}
.activefalseStatepressedL1 {
  left: 24.5rem;
}
.activefalseStatefocusedL1,
.activefalseStatehoveredL1,
.activefalseStatepressedL1 {
  position: absolute;
  top: 51.25rem;
  width: 6.5rem;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
  box-sizing: border-box;
}
.activefalseStatefocusedL1 {
  left: 16.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.activefalseStatehoveredL1 {
  left: 9rem;
}
.activefalseStateenabledL1,
.activefalseStatehoveredL1,
.iconContainer56 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.activefalseStateenabledL1 {
  position: absolute;
  top: 51.25rem;
  left: 1.25rem;
  width: 6.5rem;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
  box-sizing: border-box;
}
.iconContainer56 {
  border-radius: var(--br-base);
  overflow: hidden;
  position: relative;
}
.activefalseStateenabledL2,
.activefalseStatefocusedL2,
.activefalseStatehoveredL2,
.activefalseStatepressedL2 {
  position: absolute;
  top: 45rem;
  left: 24.5rem;
  width: 6.5rem;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activefalseStateenabledL2,
.activefalseStatefocusedL2,
.activefalseStatehoveredL2 {
  left: 16.75rem;
}
.activefalseStateenabledL2,
.activefalseStatehoveredL2 {
  left: 9rem;
}
.activefalseStateenabledL2 {
  left: 1.25rem;
}
.activefalseStateenabledL3,
.activefalseStateenabledL4,
.activefalseStatefocusedL3,
.activefalseStatefocusedL4,
.activefalseStatehoveredL3,
.activefalseStatehoveredL4,
.activefalseStatepressedL3,
.activefalseStatepressedL4 {
  position: absolute;
  top: 38.75rem;
  left: 24.5rem;
  width: 6.5rem;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.activefalseStateenabledL3,
.activefalseStateenabledL4,
.activefalseStatefocusedL3,
.activefalseStatefocusedL4,
.activefalseStatehoveredL3,
.activefalseStatehoveredL4,
.activefalseStatepressedL4 {
  left: 16.75rem;
}
.activefalseStateenabledL3,
.activefalseStateenabledL4,
.activefalseStatefocusedL4,
.activefalseStatehoveredL3,
.activefalseStatehoveredL4,
.activefalseStatepressedL4 {
  left: 9rem;
}
.activefalseStateenabledL3,
.activefalseStateenabledL4,
.activefalseStatefocusedL4,
.activefalseStatehoveredL4,
.activefalseStatepressedL4 {
  left: 1.25rem;
}
.activefalseStateenabledL4,
.activefalseStatefocusedL4,
.activefalseStatehoveredL4,
.activefalseStatepressedL4 {
  top: 32.5rem;
  left: 24.5rem;
}
.activefalseStateenabledL4,
.activefalseStatefocusedL4,
.activefalseStatehoveredL4 {
  left: 16.75rem;
}
.activefalseStateenabledL4,
.activefalseStatehoveredL4 {
  left: 9rem;
}
.activefalseStateenabledL4 {
  left: 1.25rem;
}
.badge50 {
  position: absolute;
  margin: 0 !important;
  top: 0.25rem;
  left: 2.38rem;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  width: 0.38rem;
  height: 0.38rem;
  z-index: 0;
}
.stateLayer68 {
  background-color: var(--m3-state-layers-light-on-surface-opacity-012);
  width: 4rem;
  height: 2rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.activefalseStatefocusedL5,
.activefalseStatepressedL5 {
  position: absolute;
  top: 26.25rem;
  width: 6.5rem;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  gap: var(--gap-9xs);
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-light-on-surface-variant);
}
.activefalseStatepressedL5 {
  left: 24.5rem;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activefalseStatefocusedL5 {
  left: 16.75rem;
}
.activefalseStatefocusedL5,
.activefalseStatehoveredL5,
.stateLayer70 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.stateLayer70 {
  background-color: var(--m3-state-layers-light-on-surface-opacity-008);
  width: 4rem;
  height: 2rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-xl);
  z-index: 1;
}
.activefalseStatehoveredL5 {
  position: absolute;
  top: 26.25rem;
  left: 9rem;
  width: 6.5rem;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  gap: var(--gap-9xs);
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-light-on-surface-variant);
}
.badge53 {
  position: absolute;
  margin: 0 !important;
  top: 0.25rem;
  left: 1.38rem;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-error);
  width: 0.38rem;
  height: 0.38rem;
  z-index: 0;
}
.stateLayer71 {
  width: 4rem;
  height: 2rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-xl);
  z-index: 1;
}
.activefalseStateenabledL5,
.stateLayer71,
.stateLayer72 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activefalseStateenabledL5 {
  position: absolute;
  top: 26.25rem;
  left: 1.25rem;
  width: 6.5rem;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  gap: var(--gap-9xs);
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-light-on-surface-variant);
}
.stateLayer72 {
  background-color: var(
    --m3-state-layers-light-on-secondary-container-opacity-012
  );
  width: 4rem;
  height: 2rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-xl);
}
.activetrueStatepressedLa6 {
  position: absolute;
  top: 20rem;
  left: 24.5rem;
  width: 6.5rem;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
}
.activetrueStatefocusedLa6,
.activetrueStatepressedLa6,
.stateLayer74 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activetrueStatefocusedLa6 {
  position: absolute;
  top: 20rem;
  left: 16.75rem;
  width: 6.5rem;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
}
.stateLayer74 {
  background-color: var(
    --m3-state-layers-light-on-secondary-container-opacity-008
  );
  width: 4rem;
  height: 2rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-xl);
}
.activetrueStateenabledLa6,
.activetrueStateenabledLa7,
.activetrueStatefocusedLa7,
.activetrueStatehoveredLa6,
.activetrueStatehoveredLa7,
.activetrueStatepressedLa7 {
  position: absolute;
  top: 20rem;
  left: 9rem;
  width: 6.5rem;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activetrueStateenabledLa6,
.activetrueStateenabledLa7,
.activetrueStatefocusedLa7,
.activetrueStatehoveredLa7,
.activetrueStatepressedLa7 {
  left: 1.25rem;
}
.activetrueStateenabledLa7,
.activetrueStatefocusedLa7,
.activetrueStatehoveredLa7,
.activetrueStatepressedLa7 {
  top: 13.75rem;
  left: 24.5rem;
  padding: var(--padding-xs) 0 var(--padding-base);
  gap: var(--gap-9xs);
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-light-on-surface);
}
.activetrueStateenabledLa7,
.activetrueStatefocusedLa7,
.activetrueStatehoveredLa7 {
  left: 16.75rem;
}
.activetrueStateenabledLa7,
.activetrueStatehoveredLa7 {
  left: 9rem;
}
.activetrueStateenabledLa7 {
  left: 1.25rem;
}
.iconContainer80,
.stateLayer80 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.stateLayer80 {
  background-color: var(--m3-state-layers-light-on-surface-opacity-012);
  width: 4rem;
  height: 2rem;
  padding: var(--padding-9xs) var(--padding-xl);
  box-sizing: border-box;
}
.iconContainer80 {
  border-radius: var(--br-base);
  overflow: hidden;
}
.activefalseStatepressedL6 {
  position: absolute;
  top: 7.5rem;
  left: 24.5rem;
  width: 6.5rem;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
}
.activefalseStatefocusedL6,
.activefalseStatepressedL6,
.stateLayer82 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activefalseStatefocusedL6 {
  position: absolute;
  top: 7.5rem;
  left: 16.75rem;
  width: 6.5rem;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
}
.stateLayer82 {
  background-color: var(--m3-state-layers-light-on-surface-opacity-008);
  width: 4rem;
  height: 2rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-xl);
}
.activefalseStateenabledL6,
.activefalseStateenabledL7,
.activefalseStatefocusedL7,
.activefalseStatehoveredL6,
.activefalseStatehoveredL7,
.activefalseStatepressedL7 {
  position: absolute;
  top: 7.5rem;
  left: 9rem;
  width: 6.5rem;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activefalseStateenabledL6,
.activefalseStateenabledL7,
.activefalseStatefocusedL7,
.activefalseStatehoveredL7,
.activefalseStatepressedL7 {
  left: 1.25rem;
}
.activefalseStateenabledL7,
.activefalseStatefocusedL7,
.activefalseStatehoveredL7,
.activefalseStatepressedL7 {
  top: 1.25rem;
  left: 24.5rem;
  padding: var(--padding-xs) 0 var(--padding-base);
  gap: var(--gap-9xs);
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-light-on-surface-variant);
}
.activefalseStateenabledL7,
.activefalseStatefocusedL7,
.activefalseStatehoveredL7 {
  left: 16.75rem;
}
.activefalseStateenabledL7,
.activefalseStatehoveredL7 {
  left: 9rem;
}
.activefalseStateenabledL7 {
  left: 1.25rem;
}
.buildingBlocksSegment {
  position: relative;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-surface-container-low);
  width: 32.25rem;
  height: 101.25rem;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--m3-label-small-size);
  color: var(--m3-sys-light-on-error);
}
.buildingBlocks,
.lightScheme {
  display: flex;
  flex-direction: column;
}
.buildingBlocks {
  align-self: stretch;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
  font-size: var(--m3-title-medium-size);
  color: var(--m3-black);
}
.lightScheme {
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-surface);
  border: 1px solid var(--m3-sys-dark-on-surface-variant);
  padding: var(--padding-xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-21xl);
}
.iconContainer88,
.segment26 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.iconContainer88 {
  border-radius: var(--br-base);
  background-color: var(--m3-sys-dark-secondary-container);
  overflow: hidden;
}
.segment26 {
  flex: 1;
  padding: var(--padding-xs) 0 var(--padding-base);
  gap: var(--gap-9xs);
  color: var(--m3-sys-dark-on-surface-variant);
}
.badgeDark {
  position: absolute;
  margin: 0 !important;
  top: 0.13rem;
  left: 1rem;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-dark-error);
  width: 1rem;
  height: 1rem;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.labelText49 {
  align-self: stretch;
  position: relative;
  font-size: var(--m3-label-medium-size);
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 500;
  color: var(--m3-sys-dark-on-surface-variant);
}
.segment36 {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
  font-size: var(--m3-label-small-size);
  color: var(--m3-sys-dark-on-error);
}
.configurationiconLabelSe3 {
  background-color: var(--m3-sys-dark-surface-container);
  width: 25.75rem;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.segment17 {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
  color: var(--m3-sys-dark-on-surface);
}
.configurationiconLabelSe4 {
  background-color: var(--m3-sys-dark-surface-container);
  width: 25.75rem;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--m3-sys-dark-on-surface-variant);
}
.segment39 {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
  align-items: center;
  justify-content: center;
  font-size: var(--m3-label-small-size);
  color: var(--m3-sys-dark-on-error);
}
.badgeDark4 {
  position: absolute;
  margin: 0 !important;
  top: 0.25rem;
  left: 1.38rem;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-dark-error);
  width: 0.38rem;
  height: 0.38rem;
  z-index: 1;
}
.navigationBarDark {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--m3-sys-dark-surface);
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.stateLayer112 {
  background-color: var(
    --m3-state-layers-dark-on-secondary-container-opacity-012
  );
  width: 4rem;
  height: 2rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.badgeDark6,
.iconContainer112 {
  overflow: hidden;
  display: flex;
  align-items: center;
}
.badgeDark6 {
  margin: 0 !important;
  position: absolute;
  top: 0.13rem;
  left: 2rem;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-dark-error);
  flex-direction: column;
  padding: 0 var(--padding-9xs);
  justify-content: flex-start;
  z-index: 1;
}
.iconContainer112 {
  border-radius: var(--br-base);
  background-color: var(--m3-sys-dark-secondary-container);
  flex-direction: row;
  justify-content: center;
  position: relative;
}
.activetrueStatefocusedLa8,
.activetrueStatepressedLa8 {
  position: absolute;
  height: 4.94%;
  width: 20.16%;
  top: 93.83%;
  bottom: 1.23%;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
}
.activetrueStatepressedLa8 {
  right: 3.88%;
  left: 75.97%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activetrueStatefocusedLa8 {
  right: 27.91%;
  left: 51.94%;
}
.activetrueStateenabledLa8,
.activetrueStatefocusedLa8,
.activetrueStatehoveredLa8,
.stateLayer114 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.stateLayer114 {
  background-color: var(
    --m3-state-layers-dark-on-secondary-container-opacity-008
  );
  width: 4rem;
  height: 2rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-xl);
  z-index: 0;
}
.activetrueStateenabledLa8,
.activetrueStatehoveredLa8 {
  position: absolute;
  height: 4.94%;
  width: 20.16%;
  top: 93.83%;
  right: 51.94%;
  bottom: 1.23%;
  left: 27.91%;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
}
.activetrueStateenabledLa8 {
  right: 75.97%;
  left: 3.88%;
}
.badgeDark10 {
  position: absolute;
  margin: 0 !important;
  top: 0.13rem;
  left: 2rem;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-dark-error);
  width: 1rem;
  height: 1rem;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.activetrueStateenabledLa9,
.activetrueStatefocusedLa9,
.activetrueStatehoveredLa9,
.activetrueStatepressedLa9 {
  position: absolute;
  height: 4.94%;
  width: 20.16%;
  top: 87.65%;
  right: 3.88%;
  bottom: 7.41%;
  left: 75.97%;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activetrueStateenabledLa9,
.activetrueStatefocusedLa9,
.activetrueStatehoveredLa9 {
  right: 27.91%;
  left: 51.94%;
}
.activetrueStateenabledLa9,
.activetrueStatehoveredLa9 {
  right: 51.94%;
  left: 27.91%;
}
.activetrueStateenabledLa9 {
  right: 75.97%;
  left: 3.88%;
}
.badgeDark14 {
  position: absolute;
  margin: 0 !important;
  top: 0.25rem;
  left: 2.38rem;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-dark-error);
  width: 0.38rem;
  height: 0.38rem;
  z-index: 1;
}
.activetrueStateenabledLa10,
.activetrueStatefocusedLa10,
.activetrueStatehoveredLa10,
.activetrueStatepressedLa10 {
  position: absolute;
  height: 4.94%;
  width: 20.16%;
  top: 81.48%;
  right: 3.88%;
  bottom: 13.58%;
  left: 75.97%;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activetrueStateenabledLa10,
.activetrueStatefocusedLa10,
.activetrueStatehoveredLa10 {
  right: 27.91%;
  left: 51.94%;
}
.activetrueStateenabledLa10,
.activetrueStatehoveredLa10 {
  right: 51.94%;
  left: 27.91%;
}
.activetrueStateenabledLa10 {
  right: 75.97%;
  left: 3.88%;
}
.labelText62 {
  align-self: stretch;
  position: relative;
  font-size: var(--m3-label-medium-size);
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 600;
  color: var(--m3-sys-dark-on-surface);
}
.activetrueStatepressedLa11 {
  right: 3.88%;
  left: 75.97%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activetrueStatefocusedLa11,
.activetrueStatehoveredLa11,
.activetrueStatepressedLa11 {
  position: absolute;
  height: 4.94%;
  width: 20.16%;
  top: 75.31%;
  bottom: 19.75%;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  gap: var(--gap-9xs);
}
.activetrueStatefocusedLa11 {
  right: 27.91%;
  left: 51.94%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activetrueStatehoveredLa11 {
  right: 51.94%;
  left: 27.91%;
}
.activetrueStateenabledLa11,
.activetrueStatehoveredLa11,
.activetrueStatepressedLa12 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activetrueStateenabledLa11 {
  position: absolute;
  height: 4.94%;
  width: 20.16%;
  top: 75.31%;
  right: 75.97%;
  bottom: 19.75%;
  left: 3.88%;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  gap: var(--gap-9xs);
}
.activetrueStatepressedLa12 {
  right: 3.88%;
  left: 75.97%;
}
.activetrueStatefocusedLa12,
.activetrueStatehoveredLa12,
.activetrueStatepressedLa12 {
  position: absolute;
  height: 4.94%;
  width: 20.16%;
  top: 69.14%;
  bottom: 25.93%;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  gap: var(--gap-9xs);
}
.activetrueStatefocusedLa12 {
  right: 27.91%;
  left: 51.94%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activetrueStatehoveredLa12 {
  right: 51.94%;
  left: 27.91%;
}
.activetrueStateenabledLa12,
.activetrueStatehoveredLa12,
.activetrueStatepressedLa13 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activetrueStateenabledLa12 {
  position: absolute;
  height: 4.94%;
  width: 20.16%;
  top: 69.14%;
  right: 75.97%;
  bottom: 25.93%;
  left: 3.88%;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  gap: var(--gap-9xs);
}
.activetrueStatepressedLa13 {
  right: 3.88%;
  left: 75.97%;
}
.activetrueStatefocusedLa13,
.activetrueStatehoveredLa13,
.activetrueStatepressedLa13 {
  position: absolute;
  height: 4.94%;
  width: 20.16%;
  top: 62.96%;
  bottom: 32.1%;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  gap: var(--gap-9xs);
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-dark-on-surface);
}
.activetrueStatefocusedLa13 {
  right: 27.91%;
  left: 51.94%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activetrueStatehoveredLa13 {
  right: 51.94%;
  left: 27.91%;
}
.activetrueStateenabledLa13,
.activetrueStatehoveredLa13,
.stateLayer136 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activetrueStateenabledLa13 {
  position: absolute;
  height: 4.94%;
  width: 20.16%;
  top: 62.96%;
  right: 75.97%;
  bottom: 32.1%;
  left: 3.88%;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  gap: var(--gap-9xs);
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-dark-on-surface);
}
.stateLayer136 {
  background-color: var(--m3-state-layers-dark-on-surface-opacity-012);
  width: 4rem;
  height: 2rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-xl);
  z-index: 0;
}
.activefalseStatepressedL8 {
  position: absolute;
  width: 20.16%;
  top: calc(50% + 110px);
  right: 3.88%;
  left: 75.97%;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
}
.activefalseStatefocusedL8,
.activefalseStatepressedL8,
.stateLayer138 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activefalseStatefocusedL8 {
  position: absolute;
  width: 20.16%;
  top: calc(50% + 110px);
  right: 27.91%;
  left: 51.94%;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
}
.stateLayer138 {
  background-color: var(--m3-state-layers-dark-on-surface-opacity-008);
  width: 4rem;
  height: 2rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-xl);
  z-index: 0;
}
.activefalseStatehoveredL8,
.badgeDark33 {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.activefalseStatehoveredL8 {
  width: 20.16%;
  top: calc(50% + 110px);
  right: 51.94%;
  left: 27.91%;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
  box-sizing: border-box;
  justify-content: center;
}
.badgeDark33 {
  margin: 0 !important;
  top: 0.13rem;
  left: 1rem;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-dark-error);
  overflow: hidden;
  padding: 0 var(--padding-9xs);
  justify-content: flex-start;
  z-index: 1;
}
.activefalseStateenabledL8 {
  width: 20.16%;
  top: calc(50% + 110px);
  right: 75.97%;
  left: 3.88%;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
  box-sizing: border-box;
  justify-content: center;
}
.activefalseStateenabledL8,
.activefalseStatefocusedL9,
.activefalseStatepressedL9 {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.activefalseStatepressedL9 {
  width: 20.16%;
  top: calc(50% + 10px);
  right: 3.88%;
  left: 75.97%;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
  box-sizing: border-box;
  justify-content: center;
}
.activefalseStatefocusedL9 {
  right: 27.91%;
  left: 51.94%;
}
.activefalseStateenabledL9,
.activefalseStatefocusedL9,
.activefalseStatehoveredL9 {
  width: 20.16%;
  top: calc(50% + 10px);
  padding: var(--padding-3xl) 0 var(--padding-7xl);
  box-sizing: border-box;
  justify-content: center;
}
.activefalseStatehoveredL9 {
  position: absolute;
  right: 51.94%;
  left: 27.91%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.activefalseStateenabledL9 {
  right: 75.97%;
  left: 3.88%;
}
.activefalseStateenabledL9,
.activefalseStatefocusedL10,
.activefalseStatepressedL10 {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.activefalseStatepressedL10 {
  width: 20.16%;
  top: calc(50% - 90px);
  right: 3.88%;
  left: 75.97%;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
  box-sizing: border-box;
  justify-content: center;
}
.activefalseStatefocusedL10 {
  right: 27.91%;
  left: 51.94%;
}
.activefalseStateenabledL10,
.activefalseStatefocusedL10,
.activefalseStatehoveredL10 {
  width: 20.16%;
  top: calc(50% - 90px);
  padding: var(--padding-3xl) 0 var(--padding-7xl);
  box-sizing: border-box;
  justify-content: center;
}
.activefalseStatehoveredL10 {
  position: absolute;
  right: 51.94%;
  left: 27.91%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.activefalseStateenabledL10 {
  right: 75.97%;
  left: 3.88%;
}
.activefalseStateenabledL10,
.activefalseStatepressedL11,
.badgeDark43 {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.activefalseStatepressedL11 {
  width: 20.16%;
  top: calc(50% - 190px);
  right: 3.88%;
  left: 75.97%;
  padding: var(--padding-xs) 0 var(--padding-base);
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-9xs);
}
.badgeDark43 {
  margin: 0 !important;
  top: 0.06rem;
  left: 2rem;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-dark-error);
  overflow: hidden;
  padding: 0 var(--padding-9xs);
  justify-content: flex-start;
  z-index: 1;
}
.activefalseStateenabledL11,
.activefalseStatefocusedL11,
.activefalseStatehoveredL11 {
  position: absolute;
  width: 20.16%;
  top: calc(50% - 190px);
  right: 27.91%;
  left: 51.94%;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.activefalseStateenabledL11,
.activefalseStatehoveredL11 {
  right: 51.94%;
  left: 27.91%;
}
.activefalseStateenabledL11 {
  right: 75.97%;
  left: 3.88%;
}
.activefalseStateenabledL12,
.activefalseStatefocusedL12,
.activefalseStatefocusedL13,
.activefalseStatehoveredL12,
.activefalseStatehoveredL13,
.activefalseStatepressedL12,
.activefalseStatepressedL13 {
  position: absolute;
  width: 20.16%;
  top: calc(50% - 290px);
  right: 3.88%;
  left: 75.97%;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.activefalseStateenabledL12,
.activefalseStatefocusedL12,
.activefalseStatefocusedL13,
.activefalseStatehoveredL12,
.activefalseStatehoveredL13,
.activefalseStatepressedL13 {
  right: 27.91%;
  left: 51.94%;
}
.activefalseStateenabledL12,
.activefalseStatefocusedL13,
.activefalseStatehoveredL12,
.activefalseStatehoveredL13,
.activefalseStatepressedL13 {
  right: 51.94%;
  left: 27.91%;
}
.activefalseStateenabledL12,
.activefalseStatefocusedL13,
.activefalseStatehoveredL13,
.activefalseStatepressedL13 {
  right: 75.97%;
  left: 3.88%;
}
.activefalseStatefocusedL13,
.activefalseStatehoveredL13,
.activefalseStatepressedL13 {
  top: calc(50% - 390px);
  right: 3.88%;
  left: 75.97%;
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-dark-on-surface-variant);
}
.activefalseStatefocusedL13,
.activefalseStatehoveredL13 {
  right: 27.91%;
  left: 51.94%;
}
.activefalseStatehoveredL13 {
  right: 51.94%;
  left: 27.91%;
}
.badgeDark53 {
  position: absolute;
  margin: 0 !important;
  top: 0.25rem;
  left: 1.38rem;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-dark-error);
  width: 0.38rem;
  height: 0.38rem;
  z-index: 0;
}
.activefalseStateenabledL13,
.stateLayer160 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activefalseStateenabledL13 {
  position: absolute;
  width: 20.16%;
  top: calc(50% - 390px);
  right: 75.97%;
  left: 3.88%;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  gap: var(--gap-9xs);
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-dark-on-surface-variant);
}
.stateLayer160 {
  background-color: var(
    --m3-state-layers-dark-on-secondary-container-opacity-012
  );
  width: 4rem;
  height: 2rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-xl);
}
.activetrueStatepressedLa14 {
  position: absolute;
  height: 4.94%;
  width: 20.16%;
  top: 19.75%;
  right: 3.88%;
  bottom: 75.31%;
  left: 75.97%;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
}
.activetrueStatefocusedLa14,
.activetrueStatepressedLa14,
.stateLayer162 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activetrueStatefocusedLa14 {
  position: absolute;
  height: 4.94%;
  width: 20.16%;
  top: 19.75%;
  right: 27.91%;
  bottom: 75.31%;
  left: 51.94%;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
}
.stateLayer162 {
  background-color: var(
    --m3-state-layers-dark-on-secondary-container-opacity-008
  );
  width: 4rem;
  height: 2rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-xl);
}
.activetrueStatehoveredLa14 {
  position: absolute;
  height: 4.94%;
  width: 20.16%;
  top: 19.75%;
  right: 51.94%;
  bottom: 75.31%;
  left: 27.91%;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
}
.activetrueStateenabledLa14,
.activetrueStatehoveredLa14,
.activetrueStatepressedLa15 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activetrueStateenabledLa14 {
  position: absolute;
  height: 4.94%;
  width: 20.16%;
  top: 19.75%;
  right: 75.97%;
  bottom: 75.31%;
  left: 3.88%;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
}
.activetrueStatepressedLa15 {
  right: 3.88%;
  left: 75.97%;
}
.activetrueStatefocusedLa15,
.activetrueStatehoveredLa15,
.activetrueStatepressedLa15 {
  position: absolute;
  height: 4.94%;
  width: 20.16%;
  top: 13.58%;
  bottom: 81.48%;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  gap: var(--gap-9xs);
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-dark-on-surface);
}
.activetrueStatefocusedLa15 {
  right: 27.91%;
  left: 51.94%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activetrueStatehoveredLa15 {
  right: 51.94%;
  left: 27.91%;
}
.activetrueStateenabledLa15,
.activetrueStatehoveredLa15,
.stateLayer168 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activetrueStateenabledLa15 {
  position: absolute;
  height: 4.94%;
  width: 20.16%;
  top: 13.58%;
  right: 75.97%;
  bottom: 81.48%;
  left: 3.88%;
  flex-direction: column;
  padding: var(--padding-xs) 0 var(--padding-base);
  gap: var(--gap-9xs);
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-dark-on-surface);
}
.stateLayer168 {
  background-color: var(--m3-state-layers-dark-on-surface-opacity-012);
  width: 4rem;
  height: 2rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-xl);
}
.activefalseStatepressedL14 {
  position: absolute;
  width: 20.16%;
  top: calc(50% - 690px);
  right: 3.88%;
  left: 75.97%;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
}
.activefalseStatefocusedL14,
.activefalseStatepressedL14,
.stateLayer170 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activefalseStatefocusedL14 {
  position: absolute;
  width: 20.16%;
  top: calc(50% - 690px);
  right: 27.91%;
  left: 51.94%;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
}
.stateLayer170 {
  background-color: var(--m3-state-layers-dark-on-surface-opacity-008);
  width: 4rem;
  height: 2rem;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-xl);
}
.activefalseStateenabledL14,
.activefalseStateenabledL15,
.activefalseStatefocusedL15,
.activefalseStatehoveredL14,
.activefalseStatehoveredL15,
.activefalseStatepressedL15 {
  position: absolute;
  width: 20.16%;
  top: calc(50% - 690px);
  right: 51.94%;
  left: 27.91%;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xl) 0 var(--padding-7xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.activefalseStateenabledL14,
.activefalseStateenabledL15,
.activefalseStatefocusedL15,
.activefalseStatehoveredL15,
.activefalseStatepressedL15 {
  right: 75.97%;
  left: 3.88%;
}
.activefalseStateenabledL15,
.activefalseStatefocusedL15,
.activefalseStatehoveredL15,
.activefalseStatepressedL15 {
  top: calc(50% - 790px);
  right: 3.88%;
  left: 75.97%;
  padding: var(--padding-xs) 0 var(--padding-base);
  gap: var(--gap-9xs);
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-dark-on-surface-variant);
}
.activefalseStateenabledL15,
.activefalseStatefocusedL15,
.activefalseStatehoveredL15 {
  right: 27.91%;
  left: 51.94%;
}
.activefalseStateenabledL15,
.activefalseStatehoveredL15 {
  right: 51.94%;
  left: 27.91%;
}
.activefalseStateenabledL15 {
  right: 75.97%;
  left: 3.88%;
}
.buildingBlocksSegmentDark {
  position: relative;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-dark-surface-container);
  width: 32.25rem;
  height: 101.25rem;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--m3-label-small-size);
  color: var(--m3-sys-dark-on-error);
}
.buildingBlocks1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-light-on-error);
}
.darkScheme {
  border-radius: var(--br-base);
  background-color: var(--m3-sys-dark-surface);
  flex-direction: column;
  padding: var(--padding-xl);
  gap: var(--gap-21xl);
  color: var(--m3-sys-dark-on-surface);
}
.components,
.darkScheme,
.navigationBars {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.components {
  flex-direction: row;
  padding: var(--padding-51xl);
  gap: var(--gap-21xl);
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-light-on-surface);
}
.navigationBars {
  position: relative;
  border-radius: var(--br-41xl) var(--br-41xl) var(--br-base) var(--br-base);
  background-color: var(--m3-sys-light-on-error);
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  text-align: center;
  font-size: var(--font-size-61xl);
  color: var(--m3-black);
  font-family: var(--m3-headline-medium);
}
