.logoIcon {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 2.5rem;
  flex-shrink: 0;
}
.componentName,
.link,
.logoIcon {
  position: relative;
}
.link {
  font-size: var(--m3-headline-small-size);
  text-decoration: underline;
  line-height: 2rem;
  color: var(--m3-sys-light-primary);
}
.definition {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 1.5rem;
  text-align: left;
}
.header,
.title {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.header {
  background-color: var(--m3-sys-dark-on-surface);
  overflow: hidden;
  padding: var(--padding-33xl) var(--padding-33xl) var(--padding-51xl);
  gap: var(--gap-41xl);
}
.iconsclose24px {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
}
.stateLayer {
  flex-direction: row;
  padding: var(--padding-5xs);
}
.closeIcon,
.container,
.stateLayer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container {
  border-radius: var(--br-81xl);
  overflow: hidden;
  flex-direction: row;
}
.closeIcon {
  width: 3rem;
  height: 3rem;
  flex-direction: column;
}
.labelText {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  font-weight: 500;
}
.button,
.stateLayer1 {
  display: flex;
  justify-content: center;
}
.stateLayer1 {
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xs);
  align-items: center;
}
.button {
  border-radius: var(--br-81xl);
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
}
.closeIconParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-xs) 0 0;
  align-items: center;
  justify-content: space-between;
}
.selectedDate {
  position: relative;
  font-size: var(--m3-title-large-size);
  line-height: 1.75rem;
  color: var(--m3-sys-light-on-surface);
}
.labelDate {
  flex: 1;
  flex-direction: column;
  padding: 0 0 var(--padding-9xs);
  align-items: flex-start;
  gap: var(--gap-5xs);
}
.header1,
.labeDateIconButton,
.labelDate {
  display: flex;
  justify-content: flex-start;
}
.labeDateIconButton {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-xs) var(--padding-xs) var(--padding-45xl);
  align-items: flex-end;
  gap: var(--gap-5xs);
  text-align: left;
  color: var(--m3-sys-light-on-surface-variant);
}
.header1 {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  border-bottom: 1px solid var(--m3-sys-dark-on-surface-variant);
  box-sizing: border-box;
  flex-direction: column;
  padding: var(--padding-xs) 0 0;
  align-items: flex-start;
}
.date {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
}
.daysOfTheWeek,
.sunday {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.sunday {
  flex: 1;
  border-radius: var(--br-81xl);
  height: 3rem;
  align-items: center;
}
.daysOfTheWeek {
  position: absolute;
  width: 100%;
  top: 8.25rem;
  right: 0;
  left: 0;
  padding: 0 var(--padding-xs);
  box-sizing: border-box;
  align-items: flex-start;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-light-on-surface);
}
.monthYear {
  flex: 1;
  position: relative;
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  font-weight: 500;
  display: inline-block;
  height: 1.25rem;
}
.header2 {
  align-self: stretch;
  height: 3rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-base) var(--padding-5xl) var(--padding-xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--m3-label-large-prominent-size);
  color: var(--m3-sys-light-on-surface-variant);
}
.date7 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
  display: none;
  align-items: center;
  justify-content: center;
}
.sun {
  flex: 1;
  position: relative;
  height: 3rem;
  color: var(--m3-sys-light-on-surface-variant);
}
.stateLayer3 {
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
}
.container2,
.stateLayer3,
.tue {
  display: flex;
  flex-direction: row;
}
.container2 {
  border-radius: var(--br-81xl);
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}
.tue {
  flex: 1;
  height: 3rem;
}
.container6,
.stateLayer7,
.thu,
.tue {
  align-items: center;
  justify-content: center;
}
.thu {
  flex: 1;
  height: 3rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.container6,
.stateLayer7 {
  box-sizing: border-box;
  width: 2.5rem;
  height: 2.5rem;
}
.stateLayer7 {
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
}
.container6 {
  border-radius: var(--br-81xl);
  border: 1px solid var(--m3-sys-light-primary);
  overflow: hidden;
  flex-shrink: 0;
}
.container6,
.sat,
.week1 {
  display: flex;
  flex-direction: row;
}
.sat {
  flex: 1;
  height: 3rem;
  align-items: center;
  justify-content: center;
  color: var(--m3-sys-light-primary);
}
.week1 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}
.rangeHighlightEnd,
.rangeHighlightStart {
  position: absolute;
  margin: 0 !important;
  width: 80%;
  top: calc(50% - 20px);
  background-color: var(--m3-sys-dark-on-secondary-container);
  height: 2.5rem;
}
.rangeHighlightEnd {
  right: 48.75%;
  left: -28.75%;
  display: none;
  z-index: 0;
}
.rangeHighlightStart {
  right: -31.25%;
  left: 51.25%;
  z-index: 1;
}
.container18,
.thu2 {
  overflow: hidden;
  display: flex;
}
.container18 {
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-primary);
  width: 2.5rem;
  height: 2.5rem;
  flex-shrink: 0;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
}
.thu2 {
  flex: 1;
  height: 3rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
  color: var(--m3-sys-light-on-error);
}
.rangeHighlightEnd1 {
  width: 80%;
  right: 50%;
  left: -30%;
  z-index: 0;
}
.rangeHighlightEnd1,
.rangeHighlightMiddle,
.rangeHighlightStart1 {
  position: absolute;
  margin: 0 !important;
  top: calc(50% - 20px);
  background-color: var(--m3-sys-dark-on-secondary-container);
  height: 2.5rem;
}
.rangeHighlightMiddle {
  left: calc(50% + 20px);
  width: 2.5rem;
  transform: rotate(180deg);
  transform-origin: 0 0;
  z-index: 1;
}
.rangeHighlightStart1 {
  width: 80%;
  right: -30%;
  left: 50%;
  z-index: 2;
}
.fri2,
.stateLayer20 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stateLayer20 {
  width: 2.5rem;
  height: 2.5rem;
  flex-direction: column;
  z-index: 3;
}
.fri2 {
  flex: 1;
  height: 3rem;
  overflow: hidden;
  flex-direction: row;
  position: relative;
}
.fri2,
.sat2 {
  color: var(--m3-sys-light-on-secondary-container);
}
.sat2,
.sun3 {
  flex: 1;
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}
.rangeHighlightEnd6,
.rangeHighlightStart5,
.rangeHighlightStart6 {
  position: absolute;
  margin: 0 !important;
  width: 80%;
  top: calc(50% - 20px);
  background-color: var(--m3-sys-dark-on-secondary-container);
  height: 2.5rem;
}
.rangeHighlightStart5 {
  right: -30%;
  left: 50%;
  display: none;
  z-index: 2;
}
.rangeHighlightEnd6,
.rangeHighlightStart6 {
  right: 48.75%;
  left: -28.75%;
  z-index: 0;
}
.rangeHighlightStart6 {
  right: -31.25%;
  left: 51.25%;
  display: none;
  z-index: 1;
}
.thu3,
.wed3 {
  flex: 1;
  height: 3rem;
  align-items: center;
  justify-content: center;
}
.wed3 {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: var(--gap-3xs);
  color: var(--m3-sys-light-on-error);
}
.thu3 {
  overflow: hidden;
  color: var(--m3-sys-light-on-surface);
}
.sat3,
.thu3,
.week4 {
  display: flex;
  flex-direction: row;
}
.sat3 {
  flex: 1;
  height: 3rem;
  align-items: center;
  justify-content: center;
  color: var(--m3-sys-light-on-surface);
}
.week4 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--m3-sys-light-on-secondary-container);
}
.fri4 {
  overflow: hidden;
  color: var(--m3-sys-light-on-surface-variant);
}
.fri4,
.sun5 {
  flex: 1;
  position: relative;
  height: 3rem;
}
.month1,
.week6 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}
.week6 {
  display: none;
  flex-direction: row;
  color: var(--m3-sys-light-on-surface-variant);
}
.month1 {
  display: flex;
  flex-direction: column;
}
.thu6 {
  flex: 1;
  position: relative;
  height: 3rem;
  overflow: hidden;
}
.week11 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--m3-sys-light-on-surface-variant);
}
.monthGroup,
.months {
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
}
.monthGroup {
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.months {
  height: calc(100% - 240px);
  top: 11.25rem;
  bottom: 3.75rem;
  overflow-y: auto;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-light-on-surface);
}
.secondayButton {
  border-radius: var(--br-81xl);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.localActions {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  border-top: 1px solid var(--m3-sys-dark-on-surface-variant);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xs) var(--padding-xs);
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-5xs);
}
.typefullScreenRange {
  position: absolute;
  top: 1rem;
  left: 48.25rem;
  background-color: var(--m3-sys-light-surface-container-high);
  width: 22.5rem;
  height: 43.5rem;
  color: var(--m3-sys-light-primary);
}
.supportingText,
.weekDayDay {
  align-self: stretch;
  position: relative;
  display: inline-block;
  flex-shrink: 0;
}
.supportingText {
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  font-weight: 500;
  height: 1rem;
}
.weekDayDay {
  font-size: var(--m3-headline-large-size);
  line-height: 2.5rem;
  color: var(--m3-sys-light-on-surface);
  height: 2.5rem;
}
.labelAndDate {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-17xl);
}
.header7 {
  align-self: stretch;
  border-bottom: 1px solid var(--m3-sys-dark-on-surface-variant);
  display: flex;
  flex-direction: row;
  padding: var(--padding-base) var(--padding-xs) var(--padding-xs)
    var(--padding-5xl);
  align-items: flex-end;
  justify-content: center;
  gap: var(--gap-5xs);
  text-align: left;
}
.icon {
  position: relative;
  width: 1.13rem;
  height: 1.13rem;
}
.stateLayer159 {
  border-radius: var(--br-81xl);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-9xs) var(--padding-3xs)
    var(--padding-5xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.buildingBlocksmenuButton {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.localSelectionRow,
.stateLayer160 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.localSelectionRow {
  align-self: stretch;
  height: 3.5rem;
  padding: var(--padding-9xs) var(--padding-xs) var(--padding-9xs)
    var(--padding-base);
  box-sizing: border-box;
  justify-content: flex-start;
}
.stateLayer160 {
  width: 4.5rem;
  height: 2.25rem;
  justify-content: center;
}
.buildingBlocksyear,
.container151,
.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.buildingBlocksyear,
.row {
  align-self: stretch;
  flex: 1;
}
.row {
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-5xs);
}
.year4 {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-weight: 500;
}
.container155 {
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-primary);
}
.buildingBlocksyear4,
.container155,
.years {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.buildingBlocksyear4 {
  align-self: stretch;
  flex: 1;
  color: var(--m3-sys-light-on-error);
}
.years {
  position: absolute;
  width: calc(100% - 48px);
  top: 0.5rem;
  right: 1.5rem;
  left: 1.5rem;
  height: 19.5rem;
}
.yearsContainer {
  align-self: stretch;
  flex: 1;
  position: relative;
  overflow-y: auto;
  font-size: var(--m3-title-medium-size);
}
.localActions1,
.typeyear {
  display: flex;
  align-items: flex-start;
}
.localActions1 {
  align-self: stretch;
  border-top: 1px solid var(--m3-sys-dark-on-surface-variant);
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xs) var(--padding-xs);
  justify-content: flex-end;
  gap: var(--gap-5xs);
  color: var(--m3-sys-light-primary);
}
.typeyear {
  position: absolute;
  top: 1rem;
  left: 24.63rem;
  border-radius: var(--br-9xl);
  background-color: var(--m3-sys-light-surface-container-high);
  width: 22.5rem;
  height: 32.25rem;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
}
.iconsnavigateBefore {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
}
.controls {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.daysOfTheWeek1,
.localSelectionRow1,
.sunday1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
}
.localSelectionRow1 {
  padding: var(--padding-9xs) var(--padding-xs) var(--padding-9xs)
    var(--padding-base);
  align-items: center;
  justify-content: space-between;
}
.daysOfTheWeek1,
.sunday1 {
  justify-content: center;
}
.sunday1 {
  flex: 1;
  align-items: center;
}
.daysOfTheWeek1 {
  height: 3rem;
  align-items: flex-start;
}
.sunday2 {
  align-self: stretch;
  flex: 1;
  position: relative;
  color: var(--m3-sys-light-on-surface-variant);
}
.saturday2,
.thursday4 {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.saturday2 {
  align-self: stretch;
  flex-direction: row;
  color: var(--m3-sys-light-primary);
}
.thursday4 {
  flex-direction: column;
  position: relative;
  gap: var(--gap-3xs);
  font-size: var(--m3-label-large-prominent-size);
  color: var(--m3-sys-light-on-error);
}
.sunday7,
.thursday4,
.week65 {
  align-self: stretch;
}
.sunday7 {
  flex: 1;
  position: relative;
}
.week65 {
  height: 3rem;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  color: var(--m3-sys-light-on-surface-variant);
}
.localCalendarGrid {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-xs);
  align-items: center;
  justify-content: flex-start;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-light-on-surface);
}
.localActions2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xs) var(--padding-xs);
  align-items: flex-end;
  justify-content: flex-end;
  gap: var(--gap-5xs);
  color: var(--m3-sys-light-primary);
}
.typeday {
  position: absolute;
  top: 1rem;
  left: 1rem;
  border-radius: var(--br-9xl);
  background-color: var(--m3-sys-light-surface-container-high);
  width: 22.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.modalDatePicker {
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--m3-sys-light-surface);
  width: 71.75rem;
  height: 45.25rem;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
}
.selectDate,
.weekDayDay2 {
  align-self: stretch;
  position: relative;
}
.selectDate {
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  font-weight: 500;
}
.weekDayDay2 {
  flex: 1;
  line-height: 2.5rem;
  display: flex;
  align-items: center;
}
.date266,
.header9 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.date266 {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-12xs);
  font-size: var(--m3-headline-large-size);
  color: var(--m3-sys-light-on-surface);
}
.header9 {
  border-bottom: 1px solid var(--m3-sys-dark-on-surface-variant);
  flex-direction: column;
  padding: var(--padding-base) var(--padding-xs) var(--padding-5xs)
    var(--padding-5xl);
  align-items: flex-start;
  gap: var(--gap-17xl);
}
.caretIcon {
  position: relative;
  width: 0;
  height: 1rem;
  display: none;
}
.inputText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  z-index: 0;
}
.labelText10 {
  position: relative;
  line-height: 1rem;
}
.labelText9 {
  margin: 0 !important;
  position: absolute;
  top: -1rem;
  left: -0.25rem;
  background-color: var(--m3-sys-light-surface-container-high);
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-9xs);
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-light-primary);
}
.content {
  flex: 1;
  height: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}
.stateLayer218,
.textField1 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.stateLayer218 {
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  flex-direction: row;
  padding: var(--padding-5xs) 0 var(--padding-5xs) var(--padding-base);
  align-items: center;
}
.textField1 {
  border-radius: var(--br-9xs);
  border: 2px solid var(--m3-sys-light-primary);
  flex-direction: column;
  align-items: flex-start;
}
.supportingText3 {
  flex: 1;
  position: relative;
  line-height: 1rem;
}
.supportingText2 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-base) 0;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-light-on-surface-variant);
}
.labelText11,
.textField {
  display: flex;
  justify-content: flex-start;
}
.textField {
  flex: 1;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  height: 3.5rem;
  flex-direction: column;
  align-items: flex-start;
}
.labelText11 {
  flex-direction: row;
  align-items: center;
}
.content1,
.textField3 {
  display: flex;
  flex-direction: column;
}
.content1 {
  flex: 1;
  height: 2.5rem;
  align-items: flex-start;
  justify-content: center;
}
.textField3 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  border: 1px solid var(--m3-sys-light-outline);
}
.supportingText4,
.textField2,
.textField3 {
  align-items: flex-start;
  justify-content: flex-start;
}
.supportingText4 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-base) 0;
  font-size: var(--m3-label-medium-size);
}
.textField2 {
  flex: 1;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  color: var(--m3-sys-light-on-surface-variant);
}
.dateReturn,
.localActions3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
}
.dateReturn {
  padding: var(--padding-lg) var(--padding-5xl) var(--padding-base);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-light-on-surface);
}
.localActions3 {
  padding: var(--padding-5xs) var(--padding-xs) var(--padding-xs);
  align-items: flex-end;
  justify-content: flex-end;
  gap: var(--gap-5xs);
  text-align: center;
  color: var(--m3-sys-light-primary);
}
.typerange,
.weekDayDayParent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.typerange {
  position: absolute;
  top: 1.25rem;
  left: 23rem;
  border-radius: var(--br-9xl);
  background-color: var(--m3-sys-light-surface-container-high);
  width: 20.5rem;
  overflow: hidden;
  flex-direction: column;
}
.weekDayDayParent {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-5xs);
  font-size: var(--m3-headline-large-size);
  color: var(--m3-sys-light-on-surface);
}
.date267,
.textField4,
.typesingleInput {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.textField4 {
  align-self: stretch;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  height: 3.5rem;
  align-items: flex-start;
}
.date267,
.typesingleInput {
  align-items: center;
}
.date267 {
  align-self: stretch;
  padding: var(--padding-lg) var(--padding-5xl) var(--padding-base);
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-light-on-surface);
}
.typesingleInput {
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  border-radius: var(--br-9xl);
  background-color: var(--m3-sys-light-surface-container-high);
  width: 20.5rem;
  overflow: hidden;
}
.inputDatePicker {
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--m3-sys-light-surface);
  width: 44.75rem;
  height: 19.63rem;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
  text-align: left;
}
.monthDropDown,
.monthDropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.monthDropdown {
  width: 4.13rem;
  height: 2.75rem;
  padding: 0 0 0 var(--padding-5xs);
  box-sizing: border-box;
}
.monthDropDown {
  opacity: 0.38;
}
.buildingBlocksmenuButton2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
}
.iconButton7 {
  width: 2.75rem;
  height: 2.75rem;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.selectionRow {
  border-bottom: 1px solid var(--m3-sys-dark-on-surface-variant);
  box-sizing: border-box;
  width: 22.5rem;
  height: 4rem;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-33xl);
  align-items: center;
  justify-content: space-between;
  z-index: 0;
}
.stateLayerOverlay {
  position: absolute;
  margin: 0 !important;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.iconspersonOutline24px {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  display: none;
}
.leadingElement {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.overline {
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 500;
  display: none;
}
.headline,
.overline,
.supportingText8 {
  align-self: stretch;
  position: relative;
}
.headline {
  font-size: var(--m3-title-medium-size);
  letter-spacing: 0.5px;
  line-height: 1.5rem;
  color: var(--m3-sys-light-on-surface);
}
.supportingText8 {
  font-size: var(--m3-label-large-prominent-size);
  letter-spacing: 0.25px;
  line-height: 1.25rem;
  display: none;
}
.content3 {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.trailingSupportingText {
  position: relative;
  font-size: var(--m3-label-small-size);
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 500;
  text-align: right;
  display: none;
}
.divider,
.stateLayer229 {
  align-self: stretch;
  box-sizing: border-box;
}
.stateLayer229 {
  height: 3rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xl) var(--padding-9xs)
    var(--padding-base);
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
  z-index: 1;
}
.divider {
  position: relative;
  border-top: 1px solid var(--m3-sys-dark-on-surface-variant);
  height: 0.06rem;
}
.horizontalfullWidth,
.listItem1 {
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
}
.horizontalfullWidth {
  display: none;
  align-items: flex-start;
  z-index: 2;
}
.listItem1 {
  display: flex;
  align-items: center;
  position: relative;
}
.buildingBlocksstateLayer2Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--m3-state-layers-light-on-surface-opacity-008);
}
.list {
  position: absolute;
  top: -3rem;
  left: -0.03rem;
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-base) 0 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.listContainer {
  position: relative;
  width: 22.5rem;
  height: 21rem;
  overflow-y: auto;
  flex-shrink: 0;
  z-index: 1;
  font-size: var(--m3-label-medium-size);
}
.scrollBackgroundIcon {
  position: absolute;
  height: 377.27%;
  width: 200%;
  top: 23.22%;
  right: -4000%;
  bottom: -300.5%;
  left: 3900%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  display: none;
}
.scrollBar1 {
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-outline);
}
.group,
.scrollBar,
.scrollBar1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.scrollBar {
  margin: 0 !important;
  height: 18.56%;
  width: 2.44%;
  top: 26.25%;
  right: 1.22%;
  bottom: 55.19%;
  left: 96.34%;
  z-index: 2;
}
.dropDownList,
.labelText20 {
  position: absolute;
  justify-content: flex-start;
}
.dropDownList {
  top: 5.69rem;
  left: 0;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-surface-container-high);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 0 var(--padding-xl);
  align-items: flex-start;
  gap: var(--gap-5xs);
}
.labelText20 {
  margin: 0 !important;
  top: -1rem;
  left: -0.25rem;
  background-color: var(--m3-sys-light-surface);
  padding: 0 var(--padding-9xs);
  z-index: 1;
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-light-primary);
}
.labelText20,
.stateLayer241,
.stateLayer242 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.stateLayer242 {
  background-color: var(--m3-state-layers-dark-surface-variant-opacity-012);
  padding: var(--padding-5xs);
  justify-content: center;
}
.stateLayer241 {
  align-self: stretch;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  padding: var(--padding-9xs) 0 var(--padding-9xs) var(--padding-base);
  justify-content: flex-start;
}
.supportingText20,
.textField6 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.supportingText20 {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-base) 0;
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-light-on-surface-variant);
}
.textField6 {
  position: absolute;
  top: 0.5rem;
  left: 0;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  width: 19.5rem;
  height: 3.5rem;
  flex-direction: column;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-light-on-surface);
}
.typeyear1 {
  position: absolute;
  top: 1rem;
  left: 48.19rem;
  width: 22.5rem;
  height: 32.44rem;
  text-align: left;
}
.year18,
.yearDropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.yearDropdown {
  width: 4.75rem;
  height: 2.75rem;
  padding: 0 var(--padding-5xl) 0 0;
  box-sizing: border-box;
  justify-content: flex-end;
}
.year18 {
  justify-content: center;
  opacity: 0.38;
  text-align: left;
}
.list1 {
  position: absolute;
  top: -12rem;
  left: 0;
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-base) 0 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.listContainer1 {
  position: relative;
  width: 22.5rem;
  height: 21rem;
  overflow-y: auto;
  flex-shrink: 0;
  z-index: 1;
  text-align: left;
  font-size: var(--m3-label-medium-size);
}
.dropDownList1,
.textField8 {
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.dropDownList1 {
  top: 5.69rem;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-surface-container-high);
  overflow: hidden;
  padding: 0 0 var(--padding-xl);
  align-items: center;
  gap: var(--gap-5xs);
}
.textField8 {
  top: 0.5rem;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  width: 19.5rem;
  height: 3.5rem;
  align-items: flex-start;
  text-align: left;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-light-on-surface);
}
.typemonth {
  position: absolute;
  top: 1rem;
  left: 24.63rem;
  width: 22.5rem;
  height: 32.44rem;
}
.selectionRow2 {
  align-self: stretch;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.daysOfTheWeek2,
.sunday8 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.sunday8 {
  border-radius: var(--br-81xl);
  width: 3rem;
  height: 3rem;
  align-items: center;
}
.daysOfTheWeek2 {
  align-self: stretch;
  align-items: flex-start;
}
.container213,
.monday12,
.saturday9,
.sunday9 {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.monday12,
.saturday9,
.sunday9 {
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
}
.monday12,
.saturday9 {
  color: var(--m3-sys-light-on-surface);
}
.monday12 {
  flex-direction: column;
  position: relative;
  gap: var(--gap-3xs);
  color: var(--m3-sys-light-on-error);
}
.calendarGrid,
.localActions5 {
  display: flex;
  align-items: flex-end;
}
.calendarGrid {
  flex-direction: column;
  padding: 0 var(--padding-xs) var(--padding-9xs);
  justify-content: flex-start;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-light-on-surface);
}
.localActions5 {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xs);
  justify-content: flex-end;
  gap: var(--gap-5xs);
  color: var(--m3-sys-light-primary);
}
.datePicker1 {
  position: absolute;
  top: 5.69rem;
  left: 0;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-light-surface-container-high);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.typeday1 {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 22.5rem;
  height: 34.44rem;
}
.dockedInputDatePickerDesk {
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--m3-sys-light-surface);
  width: 71.69rem;
  height: 36.44rem;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}
.label,
.label1 {
  left: 0;
  letter-spacing: 0.1px;
  line-height: 1.5rem;
  font-weight: 500;
}
.label {
  position: absolute;
  top: 0;
}
.label1 {
  top: 47.5rem;
}
.label1,
.label2,
.labels {
  position: absolute;
}
.label2 {
  top: 70rem;
  left: 0;
  letter-spacing: 0.1px;
  line-height: 1.5rem;
  font-weight: 500;
}
.labels {
  margin: 0 !important;
  top: -1.5rem;
  left: 1.28rem;
  width: 15.88rem;
  height: 71.5rem;
  z-index: 3;
  text-align: left;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-black);
}
.components1 {
  display: flex;
  flex-direction: column;
  padding: 0 0 var(--padding-21xl);
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-21xl);
}
.container256,
.typedefaultStatedisabled,
.typetodayStatedisabled {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.container256 {
  border-radius: var(--br-81xl);
  border: 1px solid var(--m3-sys-light-on-surface);
  box-sizing: border-box;
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  flex-shrink: 0;
}
.typedefaultStatedisabled,
.typetodayStatedisabled {
  position: absolute;
  width: 8.23%;
  top: 17.13rem;
  right: 71.18%;
  left: 20.58%;
  height: 3rem;
  opacity: 0.38;
}
.typedefaultStatedisabled {
  right: 88.34%;
  left: 3.43%;
}
.date319 {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
  z-index: 0;
}
.rippleIcon {
  position: absolute;
  margin: 0 !important;
  height: 87.5%;
  width: 125%;
  top: 12.5%;
  right: -25%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}
.stateLayer312 {
  border-radius: var(--br-81xl);
  background-color: var(--m3-state-layers-light-on-surface-opacity-012);
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
  z-index: 3;
}
.typeselectedMiddleState {
  position: absolute;
  width: 8.23%;
  top: 13.25rem;
  right: 37.74%;
  left: 54.03%;
  height: 3rem;
  flex-direction: row;
  color: var(--m3-sys-light-on-secondary-container);
}
.stateLayer313,
.typeselectedMiddleState,
.typeselectedStatepressed {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stateLayer313 {
  background-color: var(--m3-state-layers-light-on-primary-opacity-012);
  width: 2.5rem;
  height: 2.5rem;
  flex-direction: row;
}
.typeselectedStatepressed {
  position: absolute;
  top: 13.25rem;
  left: calc(50% - 71.5px);
  width: 3rem;
  height: 3rem;
  flex-direction: column;
  gap: var(--gap-3xs);
  color: var(--m3-sys-light-on-error);
}
.stateLayer314,
.typetodayStatepressed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.stateLayer314 {
  background-color: var(--m3-state-layers-light-primary-opacity-012);
  width: 2.5rem;
  height: 2.5rem;
  padding: var(--padding-3xs);
  box-sizing: border-box;
}
.typetodayStatepressed {
  position: absolute;
  width: 8.23%;
  top: 13.25rem;
  right: 71.18%;
  left: 20.58%;
  height: 3rem;
  color: var(--m3-sys-light-primary);
}
.rippleIcon1 {
  position: absolute;
  margin: 0 !important;
  right: -0.62rem;
  bottom: 0;
  width: 3.13rem;
  height: 2.19rem;
  z-index: 1;
}
.stateLayer315 {
  background-color: var(--m3-state-layers-light-on-surface-opacity-012);
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
}
.typedefaultStatepressed {
  position: absolute;
  width: 8.23%;
  top: 13.25rem;
  right: 88.34%;
  left: 3.43%;
  height: 3rem;
}
.stateLayer316,
.typedefaultStatepressed,
.typeselectedMiddleState1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.stateLayer316 {
  border-radius: var(--br-81xl);
  background-color: var(--m3-state-layers-light-on-surface-opacity-012);
  width: 2.5rem;
  height: 2.5rem;
  z-index: 3;
}
.typeselectedMiddleState1 {
  position: absolute;
  width: 8.23%;
  top: 9.38rem;
  right: 37.74%;
  left: 54.03%;
  height: 3rem;
  color: var(--m3-sys-light-on-secondary-container);
}
.focus {
  position: absolute;
  margin: 0 !important;
  top: -0.31rem;
  left: -0.31rem;
  border-radius: var(--br-81xl);
  border: 3px solid var(--m3-sys-dark-secondary-container);
  box-sizing: border-box;
  width: 3.13rem;
  height: 3.13rem;
  display: none;
  z-index: 3;
}
.container262,
.typeselectedStatefocused {
  display: flex;
  align-items: center;
  justify-content: center;
}
.typeselectedStatefocused {
  position: absolute;
  top: 9.38rem;
  left: calc(50% - 71.5px);
  width: 3rem;
  height: 3rem;
  flex-direction: column;
  gap: var(--gap-3xs);
  color: var(--m3-sys-light-on-error);
}
.container262 {
  border-radius: var(--br-81xl);
  border: 1px solid var(--m3-sys-light-primary);
  box-sizing: border-box;
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  z-index: 0;
}
.focus1 {
  position: absolute;
  margin: 0 !important;
  top: -0.31rem;
  left: -0.31rem;
  border-radius: var(--br-5xs);
  border: 3px solid var(--m3-sys-dark-secondary-container);
  box-sizing: border-box;
  width: 3.13rem;
  height: 3.13rem;
  display: none;
  z-index: 1;
}
.container263,
.stateLayer319,
.typetodayStatefocused {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.typetodayStatefocused {
  position: absolute;
  width: 8.23%;
  top: 9.38rem;
  right: 71.18%;
  left: 20.58%;
  height: 3rem;
  gap: var(--gap-3xs);
  color: var(--m3-sys-light-primary);
}
.container263,
.stateLayer319 {
  border-radius: var(--br-81xl);
  width: 2.5rem;
  height: 2.5rem;
}
.stateLayer319 {
  background-color: var(--m3-state-layers-light-on-surface-opacity-012);
  z-index: 0;
}
.container263 {
  position: relative;
  gap: var(--gap-3xs);
}
.typedefaultStatefocused {
  position: absolute;
  width: 8.23%;
  top: 9.38rem;
  right: 88.34%;
  left: 3.43%;
  height: 3rem;
}
.stateLayer320,
.typedefaultStatefocused,
.typeselectedMiddleState2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.stateLayer320 {
  border-radius: var(--br-81xl);
  background-color: var(--m3-state-layers-light-on-surface-opacity-008);
  width: 2.5rem;
  height: 2.5rem;
  z-index: 3;
}
.typeselectedMiddleState2 {
  position: absolute;
  width: 8.23%;
  top: 5.5rem;
  right: 37.74%;
  left: 54.03%;
  height: 3rem;
  color: var(--m3-sys-light-on-secondary-container);
}
.stateLayer321 {
  background-color: var(--m3-state-layers-light-on-primary-opacity-008);
  width: 2.5rem;
  height: 2.5rem;
  flex-direction: row;
}
.stateLayer321,
.stateLayer322,
.typeselectedStatehovered {
  display: flex;
  align-items: center;
  justify-content: center;
}
.typeselectedStatehovered {
  position: absolute;
  top: 5.5rem;
  left: calc(50% - 71.5px);
  width: 3rem;
  height: 3rem;
  flex-direction: column;
  gap: var(--gap-3xs);
  color: var(--m3-sys-light-on-error);
}
.stateLayer322 {
  background-color: var(--m3-state-layers-light-primary-opacity-008);
  width: 2.5rem;
  height: 2.5rem;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
}
.typetodayStatehovered {
  position: absolute;
  width: 8.23%;
  top: 5.5rem;
  right: 71.18%;
  left: 20.58%;
  height: 3rem;
  color: var(--m3-sys-light-primary);
}
.stateLayer323,
.typedefaultStatehovered,
.typetodayStatehovered {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.stateLayer323 {
  background-color: var(--m3-state-layers-light-on-surface-opacity-008);
  width: 2.5rem;
  height: 2.5rem;
}
.typedefaultStatehovered {
  position: absolute;
  width: 8.23%;
  top: 5.5rem;
  right: 88.34%;
  left: 3.43%;
  height: 3rem;
}
.typenullStateenabled,
.typeprevnextStateenabled {
  position: absolute;
  width: 8.23%;
  top: 1.63rem;
  right: 2.57%;
  left: 89.19%;
  height: 3rem;
  color: var(--m3-sys-light-on-surface-variant);
}
.typeprevnextStateenabled {
  right: 19.73%;
  left: 72.04%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.typeselectedMiddleState3 {
  width: 8.23%;
  right: 37.74%;
  left: 54.03%;
  flex-direction: row;
  color: var(--m3-sys-light-on-secondary-container);
}
.typedefaultStateenabled,
.typeselectedMiddleState3,
.typeselectedStateenabled,
.typetodayStateenabled {
  position: absolute;
  top: 1.63rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.typeselectedStateenabled {
  left: calc(50% - 71.5px);
  width: 3rem;
  flex-direction: column;
  gap: var(--gap-3xs);
  color: var(--m3-sys-light-on-error);
}
.typedefaultStateenabled,
.typetodayStateenabled {
  width: 8.23%;
  flex-direction: row;
}
.typetodayStateenabled {
  right: 71.18%;
  left: 20.58%;
  color: var(--m3-sys-light-primary);
}
.typedefaultStateenabled {
  right: 88.34%;
  left: 3.43%;
}
.buildingBlockslocalM3Calen {
  position: relative;
  border-radius: var(--br-8xs);
  width: 36.44rem;
  height: 21.25rem;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
  color: var(--m3-sys-light-on-surface);
}
.stateLayer329 {
  width: 4.5rem;
  height: 2.25rem;
  flex-direction: row;
  opacity: 0.38;
}
.selectedfalseStatedisabled,
.stateLayer329,
.stateLayer330 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectedfalseStatedisabled {
  position: absolute;
  top: 16.75rem;
  left: calc(50% - 98px);
  width: 5.5rem;
  height: 3.25rem;
  flex-direction: column;
}
.stateLayer330 {
  background-color: var(--m3-state-layers-light-on-primary-opacity-012);
  width: 4.5rem;
  height: 2.25rem;
  flex-direction: row;
}
.container272,
.selectedtrueStatepressed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container272 {
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-light-primary);
  overflow: hidden;
}
.selectedtrueStatepressed {
  position: absolute;
  top: 12.88rem;
  left: calc(50% + 10px);
  width: 5.5rem;
  height: 3.25rem;
  color: var(--m3-sys-light-on-error);
}
.stateLayer331 {
  background-color: var(--m3-state-layers-light-on-surface-opacity-012);
  width: 4.5rem;
  height: 2.25rem;
  flex-direction: row;
}
.selectedfalseStatepressed,
.stateLayer331,
.stateLayer332 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectedfalseStatepressed {
  position: absolute;
  top: 12.88rem;
  left: calc(50% - 98px);
  width: 5.5rem;
  height: 3.25rem;
  flex-direction: column;
}
.stateLayer332 {
  background-color: var(--m3-state-layers-light-on-primary-opacity-012);
  width: 4.5rem;
  height: 2.25rem;
  flex-direction: row;
  z-index: 0;
}
.container274,
.focus3,
.stateLayer332 {
  border-radius: var(--br-81xl);
}
.focus3 {
  position: absolute;
  margin: 0 !important;
  top: -0.31rem;
  left: -0.31rem;
  border: 3px solid var(--m3-sys-dark-secondary-container);
  box-sizing: border-box;
  width: 5.13rem;
  height: 2.88rem;
  display: none;
  z-index: 1;
}
.container274 {
  background-color: var(--m3-sys-light-primary);
  flex-direction: column;
  position: relative;
  gap: var(--gap-3xs);
}
.container274,
.selectedtrueStatefocused,
.stateLayer333 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectedtrueStatefocused {
  position: absolute;
  top: 9rem;
  left: calc(50% + 10px);
  width: 5.5rem;
  height: 3.25rem;
  flex-direction: column;
  color: var(--m3-sys-light-on-error);
}
.stateLayer333 {
  border-radius: var(--br-81xl);
  background-color: var(--m3-state-layers-light-on-surface-opacity-012);
  width: 4.5rem;
  height: 2.25rem;
  flex-direction: row;
  z-index: 0;
}
.focus4 {
  position: absolute;
  margin: 0 !important;
  top: -0.31rem;
  left: -0.31rem;
  border-radius: var(--br-5xs);
  border: 3px solid var(--m3-sys-dark-secondary-container);
  box-sizing: border-box;
  width: 5.13rem;
  height: 2.88rem;
  display: none;
  z-index: 1;
}
.container275,
.selectedfalseStatefocused {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container275 {
  border-radius: var(--br-81xl);
  position: relative;
  gap: var(--gap-3xs);
}
.selectedfalseStatefocused {
  position: absolute;
  top: 9rem;
  left: calc(50% - 98px);
  width: 5.5rem;
  height: 3.25rem;
}
.stateLayer334 {
  background-color: var(--m3-state-layers-light-on-primary-opacity-008);
  width: 4.5rem;
  height: 2.25rem;
  flex-direction: row;
}
.selectedtrueStatehovered,
.stateLayer334,
.stateLayer335 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectedtrueStatehovered {
  position: absolute;
  top: 5.13rem;
  left: calc(50% + 10px);
  width: 5.5rem;
  height: 3.25rem;
  flex-direction: column;
  color: var(--m3-sys-light-on-error);
}
.stateLayer335 {
  background-color: var(--m3-state-layers-light-on-surface-opacity-008);
  width: 4.5rem;
  height: 2.25rem;
  flex-direction: row;
}
.selectedfalseStateenabled,
.selectedfalseStatehovered,
.selectedtrueStateenabled {
  position: absolute;
  width: 5.5rem;
  height: 3.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.selectedfalseStatehovered {
  top: 5.13rem;
  left: calc(50% - 98px);
}
.selectedfalseStateenabled,
.selectedtrueStateenabled {
  top: 1.25rem;
}
.selectedtrueStateenabled {
  left: calc(50% + 10px);
  color: var(--m3-sys-light-on-error);
}
.selectedfalseStateenabled {
  left: calc(50% - 98px);
}
.buildingBlocksyear18 {
  position: relative;
  border-radius: var(--br-8xs);
  width: 14.75rem;
  height: 21.25rem;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.stateLayer339 {
  background-color: var(--m3-state-layers-light-on-surface-variant-opacity-008);
}
.stateLayer339,
.stateLayer340,
.stateLayer342 {
  border-radius: var(--br-81xl);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-9xs) var(--padding-3xs)
    var(--padding-5xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.stateLayer340 {
  background-color: var(--m3-state-layers-dark-surface-variant-opacity-012);
}
.stateLayer342 {
  opacity: 0.38;
}
.buildingBlocksmenuButton6 {
  border-radius: var(--br-8xs);
  display: flex;
  flex-direction: column;
  padding: var(--padding-7xl) var(--padding-xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xl);
  z-index: 2;
  font-size: var(--m3-label-large-prominent-size);
}
.label3 {
  position: absolute;
  top: 19.94rem;
  left: 0;
  letter-spacing: 0.1px;
  line-height: 1.13rem;
  font-weight: 500;
  text-align: left;
}
.range {
  margin: 0;
}
.label4,
.label5,
.label6 {
  position: absolute;
  top: 0;
  left: 24.88rem;
  letter-spacing: 0.1px;
  line-height: 1.13rem;
  font-weight: 500;
}
.label5,
.label6 {
  left: 5.28rem;
}
.label6 {
  left: 43.5rem;
}
.label7 {
  top: 0;
  left: 50.69rem;
}
.label7,
.label8,
.label9 {
  position: absolute;
  letter-spacing: 0.1px;
  line-height: 1.13rem;
  font-weight: 500;
}
.label8 {
  top: 0;
  left: 57.88rem;
}
.label9 {
  top: 4.13rem;
}
.label10,
.label11,
.label9 {
  left: 0;
  text-align: left;
}
.label10 {
  position: absolute;
  top: 8.31rem;
  letter-spacing: 0.1px;
  line-height: 1.13rem;
  font-weight: 500;
}
.label11 {
  top: 12.19rem;
}
.label11,
.label12,
.label13,
.label14,
.label15,
.label16 {
  position: absolute;
  letter-spacing: 0.1px;
  line-height: 1.13rem;
  font-weight: 500;
}
.label12 {
  top: 16.06rem;
  left: 0;
  text-align: left;
}
.label13,
.label14,
.label15,
.label16 {
  top: 0;
  left: 12.81rem;
}
.label14,
.label15,
.label16 {
  left: 18.5rem;
}
.label15,
.label16 {
  left: 30.81rem;
}
.label16 {
  left: 38.25rem;
}
.labels1 {
  position: absolute;
  margin: 0 !important;
  top: 4.19rem;
  left: 2.44rem;
  width: 63rem;
  height: 21.06rem;
  z-index: 3;
  font-size: var(--m3-label-large-prominent-size);
  color: var(--m3-black);
}
.label17 {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 1.5rem;
  font-weight: 500;
}
.title1 {
  margin: 0 !important;
  position: absolute;
  top: 0.81rem;
  left: 1.25rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 4;
  color: var(--m3-black);
}
.buildingBlocks,
.lightScheme {
  border-radius: var(--br-base);
  display: flex;
  align-items: flex-start;
}
.buildingBlocks {
  background-color: var(--m3-sys-light-surface-container-low);
  flex-direction: row;
  padding: var(--padding-81xl) var(--padding-xl) var(--padding-xl)
    var(--padding-105xl);
  justify-content: flex-end;
  position: relative;
  font-size: var(--m3-title-medium-size);
}
.lightScheme {
  background-color: var(--m3-sys-light-surface);
  border: 1px solid var(--m3-sys-dark-on-surface-variant);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-21xl) var(--padding-xl) var(--padding-xl);
  justify-content: flex-start;
}
.selectedDate1 {
  position: relative;
  font-size: var(--m3-title-large-size);
  line-height: 1.75rem;
  color: var(--m3-sys-dark-on-surface);
}
.labeDateIconButton1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-xs) var(--padding-xs) var(--padding-45xl);
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  text-align: left;
  color: var(--m3-sys-dark-on-surface-variant);
}
.daysOfTheWeek3,
.header11 {
  position: absolute;
  width: calc(100% - 1px);
  right: 0.03rem;
  left: 0.03rem;
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
}
.header11 {
  top: 0;
  border-bottom: 1px solid var(--m3-sys-light-on-surface-variant);
  flex-direction: column;
  padding: var(--padding-xs) 0 0;
  justify-content: flex-start;
}
.daysOfTheWeek3 {
  top: 8.19rem;
  flex-direction: row;
  padding: 0 var(--padding-xs);
  justify-content: center;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-dark-on-surface);
}
.header12,
.sun30 {
  height: 3rem;
  color: var(--m3-sys-dark-on-surface-variant);
}
.header12 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-base) var(--padding-5xl) var(--padding-xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--m3-label-large-prominent-size);
}
.sun30 {
  flex: 1;
  position: relative;
}
.container286,
.sat30 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.container286 {
  border-radius: var(--br-81xl);
  border: 1px solid var(--m3-sys-dark-primary);
  box-sizing: border-box;
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  flex-shrink: 0;
}
.sat30 {
  flex: 1;
  height: 3rem;
  color: var(--m3-sys-dark-primary);
}
.rangeHighlightEnd17,
.rangeHighlightStart17 {
  position: absolute;
  margin: 0 !important;
  width: 80%;
  top: calc(50% - 20px);
  background-color: var(--m3-sys-dark-secondary-container);
  height: 2.5rem;
}
.rangeHighlightEnd17 {
  right: 48.75%;
  left: -28.75%;
  display: none;
  z-index: 0;
}
.rangeHighlightStart17 {
  right: -31.25%;
  left: 51.25%;
  z-index: 1;
}
.container298,
.thu32 {
  overflow: hidden;
  display: flex;
}
.container298 {
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-dark-primary);
  width: 2.5rem;
  height: 2.5rem;
  flex-shrink: 0;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
}
.thu32 {
  flex: 1;
  height: 3rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
  color: var(--m3-sys-dark-on-primary);
}
.rangeHighlightEnd18,
.rangeHighlightMiddle9,
.rangeHighlightStart18 {
  position: absolute;
  width: 80%;
  top: calc(50% - 20px);
  right: 50%;
  left: -30%;
  background-color: var(--m3-sys-dark-secondary-container);
  height: 2.5rem;
}
.rangeHighlightMiddle9,
.rangeHighlightStart18 {
  width: 100%;
  right: 0;
  left: 0;
}
.rangeHighlightStart18 {
  width: 80%;
  right: -30%;
  left: 50%;
}
.date363 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fri32 {
  overflow: hidden;
}
.fri32,
.sat32 {
  flex: 1;
  position: relative;
  height: 3rem;
  color: var(--m3-sys-dark-on-secondary-container);
}
.rangeHighlightEnd23,
.rangeHighlightStart22,
.rangeHighlightStart23 {
  position: absolute;
  width: 80%;
  top: calc(50% - 20px);
  background-color: var(--m3-sys-dark-secondary-container);
  height: 2.5rem;
}
.rangeHighlightStart22 {
  right: -30%;
  left: 50%;
  display: none;
}
.rangeHighlightEnd23,
.rangeHighlightStart23 {
  margin: 0 !important;
  right: 48.75%;
  left: -28.75%;
  z-index: 0;
}
.rangeHighlightStart23 {
  right: -31.25%;
  left: 51.25%;
  display: none;
  z-index: 1;
}
.wed33 {
  flex: 1;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
  color: var(--m3-sys-dark-on-primary);
}
.thu33 {
  overflow: hidden;
}
.sat33,
.thu33 {
  flex: 1;
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--m3-sys-dark-on-surface);
}
.week46,
.week67 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.week46 {
  display: flex;
  color: var(--m3-sys-dark-on-secondary-container);
}
.week67 {
  display: none;
}
.fri52,
.week18,
.week67 {
  color: var(--m3-sys-dark-on-surface-variant);
}
.week18 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.fri52 {
  flex: 1;
  position: relative;
  height: 3rem;
  overflow: hidden;
}
.months1 {
  position: absolute;
  height: calc(100% - 239px);
  width: calc(100% - 1px);
  top: 11.19rem;
  right: 0.03rem;
  bottom: 3.75rem;
  left: 0.03rem;
  overflow-y: auto;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-dark-on-surface);
}
.secondaryButton3 {
  border-radius: var(--br-81xl);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.localActions6 {
  position: absolute;
  width: calc(100% - 1px);
  right: 0.03rem;
  bottom: 0;
  left: 0.03rem;
  border-top: 1px solid var(--m3-sys-light-on-surface-variant);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xs) var(--padding-xs);
  align-items: flex-start;
  justify-content: flex-end;
  gap: var(--gap-5xs);
}
.typefullScreenRange1 {
  position: absolute;
  top: 1rem;
  left: 48.75rem;
  background-color: var(--m3-sys-dark-surface-container-high);
  width: 22.56rem;
  height: 43.44rem;
  color: var(--m3-sys-dark-primary);
}
.weekDayDay4 {
  align-self: stretch;
  position: relative;
  font-size: var(--m3-headline-large-size);
  line-height: 2.5rem;
  color: var(--m3-sys-dark-on-surface);
  display: inline-block;
  height: 2.5rem;
  flex-shrink: 0;
}
.header17 {
  align-self: stretch;
  border-bottom: 1px solid var(--m3-sys-light-on-surface-variant);
  display: flex;
  flex-direction: row;
  padding: var(--padding-base) var(--padding-xs) var(--padding-xs)
    var(--padding-5xl);
  align-items: flex-end;
  justify-content: center;
  gap: var(--gap-5xs);
  text-align: left;
}
.buildingBlocksyearDark4,
.container435 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container435 {
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-dark-primary);
}
.buildingBlocksyearDark4 {
  align-self: stretch;
  flex: 1;
  color: var(--m3-sys-dark-on-primary);
}
.localActions7,
.typeyear2 {
  display: flex;
  align-items: flex-start;
}
.localActions7 {
  align-self: stretch;
  border-top: 1px solid var(--m3-sys-light-on-surface-variant);
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xs) var(--padding-xs);
  justify-content: flex-end;
  gap: var(--gap-5xs);
  color: var(--m3-sys-dark-primary);
}
.typeyear2 {
  position: absolute;
  top: 1.25rem;
  left: 24.88rem;
  border-radius: var(--br-9xl);
  background-color: var(--m3-sys-dark-surface-container-high);
  width: 22.5rem;
  height: 32.25rem;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
}
.sunday17 {
  align-self: stretch;
  flex: 1;
  position: relative;
  color: var(--m3-sys-dark-on-surface-variant);
}
.saturday17 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--m3-sys-dark-primary);
}
.buildingBlocksm3CalendarCe,
.saturday17,
.week612 {
  align-self: stretch;
  justify-content: center;
}
.buildingBlocksm3CalendarCe {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: var(--gap-3xs);
  color: var(--m3-sys-dark-on-primary);
}
.week612 {
  height: 3rem;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  color: var(--m3-sys-dark-on-surface-variant);
}
.localCalendarGrid1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-xs);
  align-items: center;
  justify-content: flex-start;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-dark-on-surface);
}
.localActions8 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xs) var(--padding-xs);
  align-items: flex-end;
  justify-content: flex-end;
  gap: var(--gap-5xs);
  color: var(--m3-sys-dark-primary);
}
.typeday2 {
  position: absolute;
  top: 1.25rem;
  left: 1.03rem;
  border-radius: var(--br-9xl);
  background-color: var(--m3-sys-dark-surface-container-high);
  width: 22.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.modalDatePickerDark {
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--m3-sys-dark-surface);
  width: 72.31rem;
  height: 45.44rem;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
}
.date603 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-12xs);
  font-size: var(--m3-headline-large-size);
  color: var(--m3-sys-dark-on-surface);
}
.date603,
.header19,
.labelText45 {
  display: flex;
  justify-content: flex-start;
}
.header19 {
  align-self: stretch;
  border-bottom: 1px solid var(--m3-sys-light-on-surface-variant);
  flex-direction: column;
  padding: var(--padding-base) var(--padding-xs) var(--padding-5xs)
    var(--padding-5xl);
  align-items: flex-start;
  gap: var(--gap-17xl);
}
.labelText45 {
  margin: 0 !important;
  position: absolute;
  top: -1rem;
  left: -0.25rem;
  background-color: var(--m3-sys-dark-surface-container-high);
  flex-direction: row;
  padding: 0 var(--padding-9xs);
  align-items: center;
  z-index: 1;
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-dark-primary);
}
.supportingText40,
.textField12 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}
.textField12 {
  border-radius: var(--br-9xs);
  border: 2px solid var(--m3-sys-dark-primary);
  display: flex;
  flex-direction: column;
}
.supportingText40 {
  display: none;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-base) 0;
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-dark-on-surface-variant);
}
.textFieldDark {
  flex: 1;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
}
.textField13,
.textFieldDark,
.textFieldDark1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.textField13 {
  border-radius: var(--br-9xs);
  border: 1px solid var(--m3-sys-dark-outline);
}
.textFieldDark1 {
  flex: 1;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  color: var(--m3-sys-dark-on-surface-variant);
}
.dateReturn1,
.localActions9 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
}
.dateReturn1 {
  padding: var(--padding-lg) var(--padding-5xl) var(--padding-base);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-dark-on-surface);
}
.localActions9 {
  padding: var(--padding-5xs) var(--padding-xs) var(--padding-xs);
  align-items: flex-end;
  justify-content: flex-end;
  gap: var(--gap-5xs);
  text-align: center;
  color: var(--m3-sys-dark-primary);
}
.typerange1 {
  position: absolute;
  top: 1.25rem;
  left: 23rem;
  border-radius: var(--br-9xl);
  background-color: var(--m3-sys-dark-surface-container-high);
  width: 20.5rem;
  overflow: hidden;
  flex-direction: column;
}
.date604,
.typerange1,
.typesingleInput1,
.weekDayDayGroup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.weekDayDayGroup {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-5xs);
  font-size: var(--m3-headline-large-size);
  color: var(--m3-sys-dark-on-surface);
}
.date604,
.typesingleInput1 {
  flex-direction: column;
}
.date604 {
  align-self: stretch;
  padding: var(--padding-lg) var(--padding-5xl) var(--padding-base);
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-dark-on-surface);
}
.typesingleInput1 {
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  border-radius: var(--br-9xl);
  background-color: var(--m3-sys-dark-surface-container-high);
  width: 20.5rem;
  overflow: hidden;
}
.inputDatePickerDark {
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--m3-sys-dark-surface);
  width: 44.75rem;
  height: 19.63rem;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
  text-align: left;
}
.selectionRow3 {
  border-bottom: 1px solid var(--m3-sys-light-on-surface-variant);
  box-sizing: border-box;
  width: 22.5rem;
  height: 4rem;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-33xl);
  align-items: center;
  justify-content: space-between;
  z-index: 0;
}
.headline24 {
  align-self: stretch;
  position: relative;
  font-size: var(--m3-title-medium-size);
  letter-spacing: 0.5px;
  line-height: 1.5rem;
  color: var(--m3-sys-dark-on-surface);
}
.trailingSupportingText24 {
  position: relative;
  font-size: var(--m3-label-small-size);
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 500;
  color: var(--color-gainsboro-100);
  text-align: right;
  display: none;
}
.divider24 {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--m3-sys-light-on-surface-variant);
  box-sizing: border-box;
  height: 0.06rem;
}
.buildingBlocksstateLayerDaChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--m3-state-layers-dark-on-surface-opacity-008);
}
.list2 {
  position: absolute;
  top: -3rem;
  left: 0.03rem;
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-base) 0 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.listContainer2 {
  position: relative;
  width: 22.5rem;
  height: 21rem;
  overflow-y: auto;
  flex-shrink: 0;
  z-index: 1;
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-light-on-surface-variant);
}
.scrollBar5 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-dark-outline);
}
.dropDownList2,
.labelText56 {
  position: absolute;
  display: flex;
  justify-content: flex-start;
}
.dropDownList2 {
  top: 5.69rem;
  left: 0;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-dark-surface-container-high);
  overflow: hidden;
  flex-direction: column;
  padding: 0 0 var(--padding-xl);
  align-items: flex-start;
  gap: var(--gap-5xs);
}
.labelText56 {
  margin: 0 !important;
  top: -1rem;
  left: -0.25rem;
  background-color: var(--m3-sys-dark-surface);
  flex-direction: row;
  padding: 0 var(--padding-9xs);
  align-items: center;
  z-index: 1;
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-dark-primary);
}
.stateLayer580,
.trailingIcon3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stateLayer580 {
  background-color: var(--m3-state-layers-dark-on-surface-variant-opacity-012);
  flex-direction: row;
  padding: var(--padding-5xs);
}
.trailingIcon3 {
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
}
.supportingText58,
.textFieldDark3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.supportingText58 {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-base) 0;
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-dark-on-surface-variant);
}
.textFieldDark3 {
  position: absolute;
  top: 0.5rem;
  left: 0;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  width: 19.5rem;
  height: 3.5rem;
  flex-direction: column;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-dark-on-surface);
}
.listContainer3,
.typeyear3 {
  width: 22.5rem;
  text-align: left;
}
.typeyear3 {
  position: absolute;
  top: 1rem;
  left: 47.94rem;
  height: 32.44rem;
}
.listContainer3 {
  position: relative;
  height: 21rem;
  overflow-y: auto;
  flex-shrink: 0;
  z-index: 1;
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-light-on-surface-variant);
}
.dropDownList3,
.textFieldDark4 {
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.dropDownList3 {
  top: 5.69rem;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-dark-surface-container-high);
  overflow: hidden;
  padding: 0 0 var(--padding-xl);
  align-items: center;
  gap: var(--gap-5xs);
}
.textFieldDark4 {
  top: 0.5rem;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  width: 19.5rem;
  height: 3.5rem;
  align-items: flex-start;
  text-align: left;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-dark-on-surface);
}
.typemonth1 {
  position: absolute;
  top: 1rem;
  left: 24.56rem;
  width: 22.5rem;
  height: 32.44rem;
}
.monday27,
.saturday24,
.selectionRow5 {
  width: 22.5rem;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.monday27,
.saturday24 {
  width: 3rem;
  height: 3rem;
  justify-content: center;
  color: var(--m3-sys-dark-on-surface);
}
.monday27 {
  flex-direction: column;
  position: relative;
  gap: var(--gap-3xs);
  color: var(--m3-sys-dark-on-primary);
}
.calendarGrid1,
.localActions11 {
  display: flex;
  align-items: flex-end;
}
.calendarGrid1 {
  flex-direction: column;
  padding: 0 var(--padding-xs) var(--padding-9xs);
  justify-content: flex-start;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-dark-on-surface);
}
.localActions11 {
  width: 22.5rem;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xs);
  box-sizing: border-box;
  justify-content: flex-end;
  gap: var(--gap-5xs);
  color: var(--m3-sys-dark-primary);
}
.datePicker2 {
  position: absolute;
  top: 5.69rem;
  left: 0;
  border-radius: var(--br-base);
  background-color: var(--m3-sys-dark-surface-container-high);
  width: 22.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.dockedInputDatePickerDark {
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--m3-sys-dark-surface);
  width: 71.44rem;
  height: 36.44rem;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}
.labels2 {
  position: absolute;
  margin: 0 !important;
  top: -1.5rem;
  left: 1.28rem;
  width: 15.88rem;
  height: 71.5rem;
  z-index: 3;
  text-align: left;
  font-size: var(--m3-title-medium-size);
  color: var(--m3-sys-light-on-error);
}
.container536,
.highlight {
  width: 2.5rem;
  height: 2.5rem;
}
.container536 {
  border-radius: var(--br-81xl);
  border: 1px solid var(--m3-sys-dark-on-surface);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.highlight {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  border-radius: 50%;
  background-color: var(--m3-sys-dark-primary);
  display: none;
}
.date656,
.stateLayer650 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.date656 {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
  width: 3rem;
  height: 3rem;
  flex-shrink: 0;
  z-index: 0;
}
.stateLayer650 {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  border-radius: var(--br-81xl);
  background-color: var(--m3-state-layers-dark-on-surface-opacity-012);
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  flex-direction: row;
  gap: var(--gap-3xs);
}
.typeselectedMiddleState4 {
  position: absolute;
  width: 8.23%;
  top: 13.25rem;
  right: 36.88%;
  left: 54.89%;
  height: 3rem;
  color: var(--m3-sys-dark-on-secondary-container);
}
.stateLayer651,
.typeselectedStatepressed1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stateLayer651 {
  background-color: var(--m3-state-layers-dark-on-primary-opacity-012);
  width: 2.5rem;
  height: 2.5rem;
  flex-direction: row;
}
.typeselectedStatepressed1 {
  position: absolute;
  top: 13.25rem;
  left: calc(50% - 71.5px);
  width: 3rem;
  height: 3rem;
  flex-direction: column;
  gap: var(--gap-3xs);
  color: var(--m3-sys-dark-on-primary);
}
.stateLayer652,
.typetodayStatepressed1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.stateLayer652 {
  background-color: var(--m3-state-layers-dark-primary-opacity-012);
  width: 2.5rem;
  height: 2.5rem;
  padding: var(--padding-3xs);
  box-sizing: border-box;
}
.typetodayStatepressed1 {
  position: absolute;
  width: 8.23%;
  top: 13.25rem;
  right: 71.18%;
  left: 20.58%;
  height: 3rem;
  color: var(--m3-sys-dark-primary);
}
.stateLayer653 {
  background-color: var(--m3-state-layers-dark-on-surface-opacity-012);
  width: 2.5rem;
  height: 2.5rem;
  flex-direction: row;
  position: relative;
  gap: var(--gap-3xs);
}
.date660,
.stateLayer653,
.stateLayer654 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.date660 {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
  width: 3rem;
  height: 3rem;
  flex-shrink: 0;
}
.stateLayer654 {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  border-radius: var(--br-81xl);
  background-color: var(--m3-state-layers-dark-on-surface-opacity-012);
  width: 2.5rem;
  height: 2.5rem;
  flex-direction: row;
}
.typeselectedMiddleState5 {
  position: absolute;
  width: 8.23%;
  top: 9.38rem;
  right: 36.88%;
  left: 54.89%;
  height: 3rem;
  color: var(--m3-sys-dark-on-secondary-container);
}
.stateLayer655 {
  background-color: var(--m3-state-layers-dark-on-primary-opacity-012);
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.container541,
.focus5 {
  border-radius: var(--br-81xl);
}
.focus5 {
  position: absolute;
  margin: 0 !important;
  top: -0.31rem;
  left: -0.31rem;
  border: 3px solid var(--m3-ref-secondary-secondary80);
  box-sizing: border-box;
  width: 3.13rem;
  height: 3.13rem;
  display: none;
  z-index: 1;
}
.container541 {
  background-color: var(--m3-sys-dark-primary);
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-3xs);
  z-index: 2;
}
.stateLayer656,
.typeselectedStatefocused1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.typeselectedStatefocused1 {
  position: absolute;
  top: 9.38rem;
  left: calc(50% - 71.5px);
  width: 3rem;
  height: 3rem;
  flex-direction: column;
  gap: var(--gap-3xs);
  color: var(--m3-sys-dark-on-primary);
}
.stateLayer656 {
  border-radius: var(--br-81xl);
  background-color: var(--m3-state-layers-dark-primary-opacity-012);
  width: 2.5rem;
  height: 2.5rem;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  z-index: 0;
}
.focus6 {
  position: absolute;
  margin: 0 !important;
  top: -0.31rem;
  left: -0.31rem;
  border-radius: var(--br-5xs);
  border: 3px solid var(--m3-ref-secondary-secondary80);
  box-sizing: border-box;
  width: 3.13rem;
  height: 3.13rem;
  display: none;
  z-index: 1;
}
.container542 {
  border-radius: var(--br-81xl);
  border: 1px solid var(--m3-sys-dark-primary);
  box-sizing: border-box;
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  gap: var(--gap-3xs);
}
.container542,
.stateLayer657,
.stateLayer658,
.typetodayStatefocused1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.typetodayStatefocused1 {
  position: absolute;
  width: 8.23%;
  top: 9.38rem;
  right: 71.18%;
  left: 20.58%;
  height: 3rem;
  color: var(--m3-sys-dark-primary);
}
.stateLayer657,
.stateLayer658 {
  border-radius: var(--br-81xl);
  width: 2.5rem;
  height: 2.5rem;
}
.stateLayer657 {
  background-color: var(--m3-state-layers-dark-on-surface-opacity-012);
  z-index: 0;
}
.stateLayer658 {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  background-color: var(--m3-state-layers-dark-on-surface-opacity-008);
  overflow: hidden;
}
.typeselectedMiddleState6 {
  position: absolute;
  width: 8.23%;
  top: 5.5rem;
  right: 36.88%;
  left: 54.89%;
  height: 3rem;
  color: var(--m3-sys-dark-on-secondary-container);
}
.stateLayer659,
.typeselectedStatehovered1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stateLayer659 {
  background-color: var(--m3-state-layers-dark-on-primary-opacity-008);
  width: 2.5rem;
  height: 2.5rem;
  flex-direction: row;
}
.typeselectedStatehovered1 {
  position: absolute;
  top: 5.5rem;
  left: calc(50% - 71.5px);
  width: 3rem;
  height: 3rem;
  flex-direction: column;
  gap: var(--gap-3xs);
  color: var(--m3-sys-dark-on-primary);
}
.stateLayer660 {
  background-color: var(--m3-state-layers-dark-primary-opacity-008);
  width: 2.5rem;
  height: 2.5rem;
  padding: var(--padding-3xs);
  box-sizing: border-box;
}
.stateLayer660,
.stateLayer661,
.typetodayStatehovered1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.typetodayStatehovered1 {
  position: absolute;
  width: 8.23%;
  top: 5.5rem;
  right: 71.18%;
  left: 20.58%;
  height: 3rem;
  color: var(--m3-sys-dark-primary);
}
.stateLayer661 {
  background-color: var(--m3-state-layers-dark-on-surface-opacity-008);
  width: 2.5rem;
  height: 2.5rem;
}
.typenullStateenabled1,
.typeprevnextStateenabled1 {
  position: absolute;
  width: 8.23%;
  top: 1.63rem;
  right: 2.57%;
  left: 89.19%;
  height: 3rem;
  color: var(--m3-sys-dark-on-surface-variant);
}
.typeprevnextStateenabled1 {
  right: 19.73%;
  left: 72.04%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.typeselectedMiddleState7 {
  position: absolute;
  width: 8.23%;
  top: 1.63rem;
  right: 36.88%;
  left: 54.89%;
  height: 3rem;
  color: var(--m3-sys-dark-on-secondary-container);
}
.typeselectedStateenabled1,
.typetodayStateenabled1 {
  position: absolute;
  top: 1.63rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.typeselectedStateenabled1 {
  left: calc(50% - 71.5px);
  width: 3rem;
  flex-direction: column;
  gap: var(--gap-3xs);
  color: var(--m3-sys-dark-on-primary);
}
.typetodayStateenabled1 {
  width: 8.23%;
  right: 71.18%;
  left: 20.58%;
  flex-direction: row;
  color: var(--m3-sys-dark-primary);
}
.buildingBlocksm3CalendarCe1 {
  position: relative;
  border-radius: var(--br-8xs);
  width: 36.44rem;
  height: 21.25rem;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
  color: var(--m3-sys-dark-on-surface);
}
.stateLayer667 {
  background-color: var(--m3-state-layers-dark-on-primary-opacity-012);
  width: 4.5rem;
  height: 2.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.container552,
.selectedtrueStatepressed1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container552 {
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-dark-primary);
  overflow: hidden;
}
.selectedtrueStatepressed1 {
  position: absolute;
  top: 12.88rem;
  left: calc(50% + 10px);
  width: 5.5rem;
  height: 3.25rem;
  color: var(--m3-sys-dark-on-primary);
}
.stateLayer668,
.stateLayer669 {
  background-color: var(--m3-state-layers-dark-on-surface-opacity-012);
  width: 4.5rem;
  height: 2.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.stateLayer669 {
  border-radius: var(--br-81xl);
  z-index: 0;
}
.focus8 {
  position: absolute;
  margin: 0 !important;
  top: -0.31rem;
  left: -0.31rem;
  border-radius: var(--br-5xs);
  border: 3px solid var(--m3-ref-secondary-secondary80);
  box-sizing: border-box;
  width: 5.13rem;
  height: 2.88rem;
  display: none;
  z-index: 1;
}
.selectedtrueStateenabled1 {
  position: absolute;
  top: 1.25rem;
  left: calc(50% + 10px);
  width: 5.5rem;
  height: 3.25rem;
  flex-direction: column;
  color: var(--m3-sys-dark-on-primary);
}
.selectedtrueStateenabled1,
.selectedtrueStatehovered1,
.stateLayer672 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stateLayer672 {
  background-color: var(--m3-state-layers-dark-on-primary-opacity-008);
  width: 4.5rem;
  height: 2.25rem;
  flex-direction: row;
}
.selectedtrueStatehovered1 {
  position: absolute;
  top: 5.13rem;
  left: calc(50% + 10px);
  width: 5.5rem;
  height: 3.25rem;
  flex-direction: column;
  color: var(--m3-sys-dark-on-primary);
}
.focus9,
.stateLayer673 {
  border-radius: var(--br-81xl);
}
.stateLayer673 {
  background-color: var(--m3-state-layers-dark-on-primary-opacity-012);
  width: 4.5rem;
  height: 2.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.focus9 {
  position: absolute;
  margin: 0 !important;
  top: -0.31rem;
  left: -0.31rem;
  border: 3px solid var(--m3-ref-secondary-secondary80);
  box-sizing: border-box;
  width: 5.13rem;
  height: 2.88rem;
  display: none;
  z-index: 1;
}
.container558,
.selectedtrueStatefocused1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container558 {
  border-radius: var(--br-81xl);
  background-color: var(--m3-sys-dark-primary);
  position: relative;
  gap: var(--gap-3xs);
}
.selectedtrueStatefocused1 {
  position: absolute;
  top: 9rem;
  left: calc(50% + 10px);
  width: 5.5rem;
  height: 3.25rem;
  color: var(--m3-sys-dark-on-primary);
}
.stateLayer674,
.stateLayer676,
.stateLayer677 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.stateLayer674 {
  background-color: var(--m3-state-layers-dark-on-surface-opacity-008);
  width: 4.5rem;
  height: 2.25rem;
}
.stateLayer676,
.stateLayer677 {
  border-radius: var(--br-81xl);
  background-color: var(--m3-state-layers-dark-on-surface-variant-opacity-008);
  overflow: hidden;
  padding: var(--padding-3xs) var(--padding-9xs) var(--padding-3xs)
    var(--padding-5xs);
  gap: var(--gap-5xs);
}
.stateLayer677 {
  background-color: var(--m3-state-layers-dark-on-surface-variant-opacity-012);
}
.label24 {
  position: absolute;
  top: 0;
  left: 58.13rem;
  letter-spacing: 0.1px;
  line-height: 1.13rem;
  font-weight: 500;
}
.labels3 {
  position: absolute;
  margin: 0 !important;
  top: 4.13rem;
  left: 2.44rem;
  width: 63.25rem;
  height: 21.06rem;
  z-index: 3;
  font-size: var(--m3-label-large-prominent-size);
  color: var(--m3-sys-light-on-error);
}
.buildingBlocks1,
.title2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.title2 {
  margin: 0 !important;
  position: absolute;
  top: 0.81rem;
  left: 1.5rem;
  padding: var(--padding-3xs);
  justify-content: flex-start;
  z-index: 4;
  color: var(--m3-sys-light-on-error);
}
.buildingBlocks1 {
  padding: var(--padding-81xl) var(--padding-xl) var(--padding-xl)
    var(--padding-105xl);
  justify-content: flex-end;
  position: relative;
  font-size: var(--m3-title-medium-size);
}
.darkScheme {
  border-radius: var(--br-base);
  background-color: var(--m3-sys-dark-surface);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-21xl) var(--padding-xl) var(--padding-xl);
  color: var(--m3-sys-dark-on-surface-variant);
}
.components,
.darkScheme,
.datePicker {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.components {
  background-color: var(--m3-sys-light-on-error);
  flex-direction: row;
  padding: var(--padding-51xl);
  gap: var(--gap-21xl);
  font-size: var(--m3-label-large-prominent-size);
  color: var(--m3-sys-light-on-surface-variant);
}
.datePicker {
  position: relative;
  border-radius: var(--br-41xl) var(--br-41xl) var(--br-base) var(--br-base);
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  text-align: center;
  font-size: var(--font-size-61xl);
  color: var(--m3-black);
  font-family: var(--m3-headline-medium);
}
